import { createTheme } from "@mui/material/styles";
import "typeface-barlow";
import "typeface-ubuntu";

declare module "@mui/material/styles" {
  interface Theme {
    colorsBase?: any;
    designSystem?: {
      titulo1: any;
      titulo2: any;
      titulo3: any;
      titulo4: any;
      cuerpo: any;
      cuerpo1: any;
      cuerpo2: any;
      cuerpo3: any;
      cuerpo4: any;
      btnQuiz: any;
      btnQuizSelected: any;
    };
  }
  // fix the type error when calling `createTheme()` with a custom theme option
  interface ThemeOptions {
    colorsBase?: any;
    designSystem?: {
      titulo1: any;
      tituloSemi: any;
      titulo2: any;
      titulo3: any;
      titulo4: any;
      cuerpo: any;
      cuerpo1: any;
      cuerpo2: any;
      cuerpo3: any;
      cuerpo4: any;
      btnQuiz: any;
      btnQuizSelected: any;
    };
  }
}

export const adminTheme = createTheme({
  typography: {
    fontFamily: ["Barlow", "Ubuntu", "Arial","Poppins", "sans-serif"].join(","),
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          ":before": {
            display: "none",
          },
        },
      },
    },
  },
  colorsBase: {
    Rosado: "#B73876",
    Lila2: "#F088B6",
    Azul1: "#28538E",
    Azul2: "#BAE5F7",
    Naranja1: "#E94C29",
    Naranja2: "#FFCC68",
    Rojo: "#E83133",
    Rojo1: "#E94C29",
    Verde3: "#CBD253",
    TextoNegro: "#1D1D1B",
    TextoGray: "#ABAFB3",
    DrawerGray: "#F5F9F6",
    Texto2: "#6C6C6C",
    Blanco: "#fff",
    VerdeClaro: "#97BE46",
    Verde1: "#479C3D",
    Verde2: "#97BE46",
    Verde: "#028647",
    Gray: "#E7E2E2",
    BorderGray: "#DDDFE1",
    Negro3: "#F5F9F6",
    BorderFilter: "#D1D5DB",
    TextoRojo: "#DF2F2F",
    Negro2: "#E7E2E2",
  },
  designSystem: {
    titulo1: {
      fontFamily: "Barlow",
      fontWeight: "bold",
      fontSize: "24px",
      color: "#1D1D1B",
      "@media (maxWidth:899.95px)": {
        // [theme.breakpoints.down('md')]
        fontSize: "20px",
      },
    },
    tituloSemi: {
      fontFamily: "Barlow",
      fontWeight: "semibold",
      fontSize: "24px",
      color: "#1D1D1B",
      "@media (maxWidth:899.95px)": {
        // [theme.breakpoints.down('md')]
        fontSize: "20px",
      },
    },
    titulo2: {
      fontFamily: "Barlow",
      fontWeight: "bold",
      fontSize: "16px",
      color: "#1D1D1B",
      "@media (maxWidth:899.95px)": {
        // [theme.breakpoints.down('md')]
        fontSize: "14px",
      },
    },
    titulo3: {
      fontFamily: "Barlow",
      fontWeight: "bold",
      fontSize: "14px",
      color: "#1D1D1B",
      "@media (maxWidth:899.95px)": {
        // [theme.breakpoints.down('md')]
        fontSize: "12px",
      },
    },
    titulo4: {
      fontFamily: "Barlow",
      fontWeight: "bold",
      fontSize: "12px",
      color: "#1D1D1B",
      "@media (maxWidth:899.95px)": {
        // [theme.breakpoints.down('md')]
        fontSize: "10px",
      },
    },
    cuerpo: {
      fontFamily: "Ubuntu",
      fontWeight: "regular",
      fontSize: "24px",
      color: "#1D1D1B",
      "@media (maxWidth:899.95px)": {
        // [theme.breakpoints.down('md')]
        fontSize: "20px",
      },
    },
    cuerpo1: {
      fontFamily: "Ubuntu",
      fontWeight: "regular",
      fontSize: "16px",
      color: "#1D1D1B",
      "@media (maxWidth:899.95px)": {
        // [theme.breakpoints.down('md')]
        fontSize: "14px",
      },
    },
    cuerpo2: {
      fontFamily: "Ubuntu ",
      fontWeight: "regular",
      fontSize: "13px",
      color: "#1D1D1B",
      "@media (maxWidth:899.95px)": {
        // [theme.breakpoints.down('md')]
        fontSize: "12px",
      },
    },
    cuerpo3: {
      fontFamily: "Ubuntu",
      fontWeight: "regular",
      fontSize: "12px",
      color: "#1D1D1B",
      "@media (maxWidth:899.95px)": {
        // [theme.breakpoints.down('md')]
        fontSize: "10px",
      },
    },
    cuerpo4: {
      fontFamily: "Ubuntu",
      fontWeight: "regular",
      fontSize: "10px",
      color: "#1D1D1B",
      "@media (maxWidth:899.95px)": {
        // [theme.breakpoints.down('md')]
        fontSize: "8px",
      },
    },

    btnQuiz: {
      backgroundColor: "#CBD253",
      color: "#fff",
      borderRadius: "8px",
      cursor: "pointer",
      p: 5,
      "&:disabled": {
        backgroundColor: "#DDDFE1",
      },
      "@media (maxWidth:899.95px)": {
        p: 2,
      },
    },
    btnQuizSelected: {
      backgroundColor: "#479C3D",
      color: "#fff",
      borderRadius: "8px",
      cursor: "pointer",
      p: 5,
      "&:disabled": {
        backgroundColor: "#DDDFE1",
      },
      "@media (maxWidth:899.95px)": {
        p: 2,
      },
    },
  },
});
