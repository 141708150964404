import { useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, Typography, styled,Chip } from "@mui/material";
import { FacebookRounded } from "@mui/icons-material";
import TwitterIcon from '@mui/icons-material/Twitter';
import { useLocation, useParams } from "react-router-dom";
import Layout from "../../../../components/Layout";
import theme from "../../../../themes";
import LinkCard from "./LinkCard/LinkCard";
import n3 from "../.././../../assets/Hemeroteca/news/new_three.jpg";
import Footer from "../../../../components/Footer";
import useAppContext from "../../../../shared/hooks/useAppContext";
import moment from "moment";

interface RouteParams {
  id: string
}

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: '100%',
  // backgroundColor:'#e9e9e9'
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  fontFamily: 'Barlow',
  fontSize: 14,
  backgroundColor: theme.palette.primary.main,
  color: '#FFFFFF',
  width: '50%',
  height: '50px',
  bottom: '0px',
  '&:hover': {
    backgroundColor: theme.palette.primary.hover,
    color: '#FFFFFF',
  }
}));

const StyledButton2 = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  fontFamily: 'Barlow',
  fontSize: 14,
  border: `1px solid ${theme.palette.primary.main}`,
  height: '40px',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: '#808080',
    color: '#E8E8E8',
  },
  [theme.breakpoints.down("md")]: {
    width: '150px'
  },
}));

const StyledButton3 = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  fontFamily: 'Barlow',
  fontSize: 14,
  border: `1px solid #004c89`,
  height: '40px',
  backgroundColor: '#004c89',
  color: '#FFFFFF',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#808080',
    color: '#E8E8E8',
  },
  [theme.breakpoints.down("md")]: {
  },
}));

const StyledButton4 = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  fontFamily: 'Barlow',
  fontSize: 16,
  border: `1px solid #00b8ee`,
  backgroundColor: '#00b8ee',
  height: '40px',
  color: '#FFFFFF',
  borderRadius: '8px',
  marginLeft: '1.5em',
  '&:hover': {
    backgroundColor: '#808080',
    color: '#E8E8E8',
  },
  [theme.breakpoints.down("md")]: {
  },
}));

const InterestLink = () => {

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const params= useParams<RouteParams>();

  const {
    getInterestLinkContent,
    interestLinkContent
  } = useAppContext();
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }

    window.scrollTo(0, 0);
  }, [pathname, hash, key]);

  useEffect(() => {
    if(interestLinkContent)
      setIsLoading(false);

  }, [interestLinkContent])

  useEffect(() => {
    // Required to have just one _
    // let index = params.id.indexOf('_');
    // let linkId:number=0;

    // if(index !== -1) {
    //   linkId = parseInt(params.id.substring(index+1));
    // }

    let linkId = parseInt(params.id);
    
    if(linkId)
      getInterestLinkContent(linkId)
    
  }, []); 

  const handleLinkRedirect = () => {
    if(interestLinkContent && interestLinkContent.prensa_enlace){
      const linkToOpen = interestLinkContent.prensa_enlace.startsWith("http://") || interestLinkContent.prensa_enlace.startsWith("https://")
        ? interestLinkContent.prensa_enlace
        : `http://${interestLinkContent.prensa_enlace}`;

      window.open(linkToOpen, '_blank');
    }
  }

  const handleFacebookShare = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=https://vidadignasinviolencia.org.bo/hemeroteca/link-de-interes/${interestLinkContent.prensa_cod}`;
    window.open(url, '_blank');
  };
  const handleTwitterShare = () => {
    const url = `https://twitter.com/intent/tweet?url=https://vidadignasinviolencia.org.bo/hemeroteca/link-de-interes/${interestLinkContent.prensa_cod}`;
    window.open(url, '_blank');
    
  };
  return (
    <Layout>
      <Backdrop sx={{
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
        }} 
        open={isLoading}
      > 
        <CircularProgress color="inherit" />
      </Backdrop>
      <StyledBox display="flex" flexDirection="column" >
        <Box display="flex" justifyContent="center" sx={{ width: '100%', backgroundColor: '#e9e9e9', paddingBottom: '1.5em' }}>
          <Box sx={{ width: { xs: '100%', md: '75%' }, height: '100%', maxHeight: '100%',  margin: 0, padding: '1.5em 1.5em 3em 1.5em' }}>
            <Box display="flex" sx={{ width: '100%', height: '100%' }}>
              <LinkCard image={n3} content={""} date={""} clipLabel={""} />
            </Box>
          </Box>
        </Box>
        {/*  */}
        <Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%', padding: '1.5em 1.5em 1.5em 1.5em' }}>
          <Box display="flex" sx={{ width: { xs: '100%', md: '75%' } }}>
            <Box display="flex" sx={{ width: '100%', paddingBottom: '1.5em' }}>
              <StyledButton onClick={ handleLinkRedirect} disabled={interestLinkContent && interestLinkContent.prensa_enlace ? false : true}>
                LEE LA NOTA ORIGINAL 
              </StyledButton>
            </Box>
          </Box>
          <Box display="flex" sx={{ width: { xs: '100%', md: '75%' } }}>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" sx={{ width: '100%', borderTop: `1px solid ${theme.palette.neutral.main}` }}>
              <Box display="flex" sx={{ py: '1em', width: '70%' }}>
                <Box display="flex" alignItems="center" sx={{ width: { xs: '100%', md: '200px' } }}>
                  <Typography variant="h2" sx={{ fontFamily: 'Ubuntu', fontSize: { xs: '22px', md: '22px' }, color: theme.palette.neutral.main }}>
                    CATEGORÍA
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ margin: '0 0 0 1.5em' }}>
                  <StyledButton2 variant="text">
                    {interestLinkContent && interestLinkContent.categoria}
                  </StyledButton2>
                </Box>
              </Box>
              <Box display="flex" sx={{ borderLeft: { xs: 'none', md: `1px solid ${theme.palette.neutral.main}` }, py: '1em' }}>
                <Typography sx={{ ml: { xs: 0, md: '1.5em' }, fontFamily: 'Ubuntu', fontSize: '16px', color: theme.palette.neutral.main }}>
                  Última actualización: &nbsp;
                </Typography>
                <Typography sx={{ fontFamily: 'Ubuntu', fontSize: '16px', color: theme.palette.neutral.main }}>
                  {  moment(interestLinkContent && interestLinkContent.ultimaModificacionFecha).format("DD-MM-YYYY HH:mm") }
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" sx={{ width: { xs: '100%', md: '75%' } }}>
            <Box display="flex" sx={{ width: '100%', borderTop: `1px solid ${theme.palette.neutral.main}`, padding: '1.5em 0 1.5em 0' }}>
              <Box display="flex" sx={{ width: { xs: '100%' } }}>
                <Typography mb={'1.5em'} mr={'1.5em'} variant="h2" sx={{ fontFamily: 'Ubuntu', fontSize: { xs: '22px', md: '22px' }, color: theme.palette.neutral.main }}>
                  Comparte en
                </Typography>
                <Chip
                    icon={<FacebookRounded color="inherit" fontSize="small" />}
                    label="Facebook"
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: 500,
                      background: "#004c89",
                      color: theme.palette.common.white,
                    }}
                    onClick={handleFacebookShare}
                  />
                <Chip
                    icon={<TwitterIcon color="inherit" fontSize="small" />}
                    label="Twitter"
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: 500,
                      background: "#00b8ee",
                      color: theme.palette.common.white,
                    }}
                    onClick={handleTwitterShare}
                  />
              </Box>
            </Box>
          </Box>
        </Box> 
        {/*  */}
      </StyledBox>
      <Footer />
    </Layout>
  )
};

export default InterestLink;