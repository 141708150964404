import { Box, Typography, styled } from "@mui/material";
import theme from "../../../../themes";
import TimelineTree from "../../../../components/TimelineTree";

const StyledBox = styled(Box)(() => ({
  margin: '0 0 3em 0',
  padding: 0,
  width: '100%',
}));

const TimelineWhoWeAre = () => {
  return (
    <StyledBox display="flex" justifyContent="center" id="nuestraHistoria">
      <Box sx={{ width: { xs: '100%', md: '75%' }, height: '100%', margin: 0, padding: '0em 1.5em 0em 1.5em', borderRadius: '8px' }}>
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent="space-between" sx={{ height: '100%', margin: 0, padding: 0 }}>
          <Box display="flex" flexDirection="column" sx={{ margin: '0em 0 3em 0', padding: 0, width: '100%' }}>
            <Typography sx={{ color: theme.palette.neutral.hover, fontFamily: 'Barlow', fontSize: '22px' }}>
              EL CAMINO RECORRIDO
            </Typography>
            <Typography sx={{ fontFamily: 'Barlow', fontSize: 42, color: theme.palette.neutral.hover }}>
              Nuestra historia desde el inicio
            </Typography>
          </Box>
        </Box>
        <TimelineTree nodes={8} />
      </Box>
    </StyledBox >
  )
};
export default TimelineWhoWeAre;