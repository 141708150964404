import { Box, styled, Typography } from "@mui/material";
import banner from "../../../../assets/Hemeroteca/main_Banner.jpg";
import theme from "../../../../themes";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  // height: 400,
  height: "70vh",
  backgroundImage: `url(${banner})`,
  backgroundSize: "cover",
  backgroundAttachment: 'fixed',
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "fit-content",
    height: 500,
  },
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "3em 1.5em 1.5em 1.5em",
  backgroundColor: "rgba(0, 124, 60, 0.6)",
  width: "50%",
  height: "100%",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const MainBanner = () => {
  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledDivMediaCaption>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            sx={{ height: "100%", margin: 0, padding: 0 }}
          >
            <Box mb={2}>
              <Typography
                variant="h1"
                style={{
                  fontFamily: "Barlow",
                  fontWeight: 400,
                  fontSize: 42,
                  color: theme.palette.common.white,
                }}
              >
                Hemeroteca
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 300,
                  color: theme.palette.common.white,
                  mt: "1em",
                }}
              >
                En este espacio encontrarás las noticias del proyecto que
                reflejan el trabajo y la gestión de los actores clave y enlaces
                de interés relevantes sobre la temática.
              </Typography>
            </Box>
          </Box>
        </StyledDivMediaCaption>
      </Box>
    </StyledBox>
  );
};

export default MainBanner;
