export const SystemRoles = {
  PARTICIPANTE: "PARTICIPANTE",
  MONITOR: "MONITOR",
  REGIONAL: "REGIONAL",
};

export const FirebaseExceptions: Record<string, string> = {
  "auth/wrong-password": "La contraseña es incorrecta.",
  "auth/too-many-requests":
    "El acceso a esta cuenta fue temporalmente inhabilitado. Puedes resetear tu contraseña para poder acceder inmediatamente o intentar nuevamente más tarde.",
  "auth/user-not-found":
    "No se encotró ningún usuario con el correo ingresado.",
  "auth/user-disabled": "El usuario con este correo ha sido inhabilitado.",
  "auth/invalid-email": "El correo electrónico tiene un formato no válido.",
  "auth/network-request-failed":
    "No se puede establecer una conexión a internet estable.",
  "auth/operation-not-allowed": "Operacion no permitida.",
  "auth/email-already-in-use":
    "El correo electrónico ya ha sido registrado. Inicie sesión o restablezca su contraseña.",
};

export const ResultadoMachometro = {
  MUY_MACHISTA: "Muy machista",
  MACHISTA: "Machista",
  POCO_MACHISTA: "Un poco machista",
  NADA_MACHISTA: "Nada machista",
};
