import { ReactNode, useCallback, useEffect, useState } from "react";
import { LoginParams, LogoutParams, RegisterParams, UserRecord } from "./Auth";
import AuthContext from "./AuthContext";
import Auth from "./";
import CSplash from "../components/CSplash";
import { printLog } from "../utils";
import { Alerts } from "../hooks/useAlert";

export default function AuthContextProvider(props: { children?: ReactNode }) {
  const [user, setUser] = useState<UserRecord | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const login = useCallback(
    (params: LoginParams) => {
      Auth.login(params)
        .then((loggedInUser) => {
          setUser(loggedInUser);
        })
        .catch((error) => {
          Alerts.showError({
            title: "¡Error!",
            description:
              "Parece que ha habido un error al ingresar tu dirección de correo electrónico. Por favor, verifica e inténtalo nuevamente.",
          });
          setUser(null);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Auth.login, setUser]
  );

  const logout = useCallback(
    (params: LogoutParams) => {
      Auth.logout(params).then((_) => {
        setUser(null);
      });
    },
    [setUser]
  );

  const register = useCallback(
    (params: RegisterParams) => {
      Auth.register(params)
        .then((loggedInUser) => {
          setUser(loggedInUser);
        })
        .catch((error) => {
          setUser(null);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Auth.register, setUser]
  );

  const setRol = async (_userRol: any) => {
    printLog("seteando user rol....", _userRol);
    // const response: any = await http.get('/current_login', {
    //   auth: _userRol.token,
    // })
    // printLog('[useHttp]  >>> reponse :: ', response);
    // if (response) {
    // }
    // setUser(null)
    setUser(_userRol);
  };

  const resetPassword = async (email: string) => {
    await Auth.resetPassword(email);
  };

  const updatePassword = async (oldPassword: string, newPassword: string) => {
    await Auth.updatePassword(oldPassword, newPassword);
  };

  const getCurrentToken = () => Auth.getCurrentTokenID();

  useEffect(() => {
    setLoading(true);
    printLog("Inicializando Auth");
    Auth.init()
      .then(() => {
        printLog("on auth init :: ");
        Auth.subscribe((loggedInUser) => {
          printLog("auth.subscribe :: ", loggedInUser);
          setUser(loggedInUser);
          setLoading(false);
          // setRol(loggedInUser);
        });
        // setLoading(false);
        printLog("Auth inicializado");
      })
      .catch((err) => {
        printLog("Error on auth :: ", err);
        setUser(null);
        setLoading(false);
      });
    return () => {
      Auth.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: user,
        login,
        logout,
        setRol,
        resetPassword,
        updatePassword,
        getCurrentTokenID: getCurrentToken,
        register,
      }}
    >
      {loading ? <CSplash /> : props.children}
    </AuthContext.Provider>
  );
}
