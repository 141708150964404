export const preguntasRespuestas = [
  {
    question:
      "¿Por qué no tengo habilitada la opción “Inscribirme a un curso”?",
    answer:
      '<p style="word-break: break-word;">Los cursos se habilitan por cronograma interno y solo se permiten inscripciones por un rango de tiempo dado.</p>',
    rol: "PARTICIPANTE",
  },
  {
    question:
      "¿Por qué ningún contenido está disponible aparte de la primera unidad?",
    answer:
      '<p style="word-break: break-word;">Se sigue una lógica ordenada, por lo que el usuario debe iniciar con la unidad número uno antes de pasar a la unidad número dos y así sucesivamente. Conforme se vaya avanzando, se habilitará el contenido del resto de unidades.</p>',
    rol: "PARTICIPANTE",
  },
  {
    question: "¿Por qué el sistema no me permite enviar mi cuestionario?",
    answer:
      '<p style="word-break: break-word;">Todas las preguntas deben ser respondidas, de otro modo se notificará al usuario con un mensaje de error.</p>',
    rol: "PARTICIPANTE",
  },
  {
    question: "Si ya envié un archivo, ¿Puedo cambiarlo después?",
    answer:
      '<p style="word-break: break-word;">No, una vez un documento es enviado, no podrá ser modificado.</p>',
    rol: "PARTICIPANTE",
  },
];

export const manuales = [
  {
    title: "Manual administrador",
    url: "https://docs.google.com/presentation/d/1eZkx5PR-_NSPCQFNO3gWug8rOE1bJVog/edit?usp=sharing&ouid=115900361254901146560&rtpof=true&sd=true",
    rol: "MONITOR",
  },
  {
    title: "Manual estudiante",
    url: "https://drive.google.com/file/d/1pWyXHZsv-Ada200PnsLETnuA61Feot3q/view?usp=drive_link",
    rol: "PARTICIPANTE",
  },
  {
    title: "Manual envío de buenas prácticas",
    url: "https://drive.google.com/file/d/1A-fNKHWUKUF2FTm-DLKHnGEG-vWLlU9x/view?usp=sharing",
    rol: "PARTICIPANTE",
  },
];

export const tutoriales = [
  {
    title: "Inscripción a un curso",
    url: "https://youtu.be/puzc6xJJ6vo?si=MBnKpF3sEhDH5ziQ",
    rol: "PARTICIPANTE",
  },
  {
    title: "Recorrido por el curso",
    url: "https://youtu.be/S0vNYi-dFjE?si=athHj5e_gYX9BMet",
    rol: "PARTICIPANTE",
  },
  {
    title: "Cargado de noticias",
    url: "https://youtu.be/kiBhRNXalgc",
    rol: "MONITOR",
  },
  {
    title: "Envío de buenas prácticas",
    url: "https://youtu.be/wXnXV-ZLuuo",
    rol: "PARTICIPANTE",
  },
  {
    title: "Gestión de buenas prácticas",
    url: "https://youtu.be/tGI7TaBiL98",
    rol: "MONITOR",
  },
];
