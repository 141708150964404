import { styled, Tab, Tabs } from "@mui/material";
// import { colorsBase, DesignSystem } from "../theme";
import { colorsBase, DesignSystem } from "../../themes/theme";

export const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: 'inherit',
  },
});

export const AntTab = styled(Tab)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
    ...DesignSystem?.titulo3,
    // boxShadow: '5px 0px 5px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      color: colorsBase.Texto2,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: colorsBase.Blanco,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);