import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
// import { CourseBannerProps } from "../../../../interfaces/CarouselBannerProps";
import bgCourse from "../../../../assets/bg_cursos.png";
import theme from "../../../../themes";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
}));

const StyledBannerGrid = styled(Grid)(() => ({
  height: "100%",
  position: "relative",
}));

const StyledCardMedia = styled(CardMedia)(() => ({
  backgroundColor: "black",
  height: 300,
  overflow: "hidden",
  position: "relative",
  transition: "300ms",
  cursor: "pointer",
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "15px",
  bottom: 0,
  color: "white",
  width: "100%",
  height: "50%",
  fontSize: "21px",
  fontWeight: "200",
  transition: "300ms",
  cursor: "pointer",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.active,
  "&:hover": {
    backgroundColor: theme.palette.primary.hover,
    color: "#FFFFFF",
  },
}));

const Banner = ({ items }: any) => {
  let history = useHistory();
  let data = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const media = (
      <Grid item xs={4} key={i}>
        <Card elevation={0} style={{ borderRadius: '8px', height: '100%', display: "flex", flexDirection: "column" }}>
          <StyledCardMedia image={item.urlImagen} title={item.nombre}>
            <Box
              width="100%"
              height="100%"
              sx={{
                background:
                  "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2))",
              }}
            >
              <StyledDivMediaCaption sx={{ px: 3, pb: 3 }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="end"
                  alignItems={'start'}
                  height="100%"
                  gap={2}
                >
                  {item.type ? (
                    <Chip
                      label={item.type}
                      sx={{
                        backgroundColor: theme.palette.secondary.active,
                        fontWeight: 400,
                        color: theme.palette.common.white,
                      }}
                    />
                  ) : null}
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: 25, lg: 31 },
                        fontFamily: "Barlow",
                        lineHeight: "1",
                      }}
                    >
                      {item.nombre}
                    </Typography>
                  </Box>
                </Box>
              </StyledDivMediaCaption>
            </Box>
          </StyledCardMedia>
          <CardContent sx={{ px: 3, pt: 3, pb: 1, flex: 1 }}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                textAlign: 'justify',
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
            >
              {item.descripcion}
            </Typography>
          </CardContent>
          <CardActions sx={{ pb: 3, px: 3, }}>
            {/* TODO, falta link url del curso desde el api */}
            <StyledButton
              disableElevation
              variant="contained"
              onClick={() =>
                history.push("/catalogo-cursos/detalle", {
                  course: item,
                })
              }
            >
              LEER COMPLETO
            </StyledButton>
          </CardActions>
        </Card>
      </Grid>
    );

    data.push(media);
  }

  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        justifyContent="space-between"
        sx={{
          width: { xs: "100%", md: "75%" },
          // height: "550px",
        }}
        >
        <Box
          sx={{
            position: 'relative',
            width: "100%",
            height: "100%",
            padding: "1.5em 1.5em 8em 1.5em",
          }}
        >
          <StyledBannerGrid container alignItems={'stretch'} spacing={4}>
            {data}
          </StyledBannerGrid>
          <Box
            component={"img"}
            src={bgCourse}
            sx={{
              position: 'absolute',
              bottom: 0,
              left: -70,
              zIndex: -1,
            }}
          ></Box>
        </Box>
      </Box>
    </StyledBox>
  );
};

export default Banner;
