import { styled } from "@mui/material/styles";
import { Box, Card, IconButton, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
// import { DesignSystem, FichasColor } from "../../common/theme";
import { DesignSystem, FichasColor } from "../../themes/theme";
import { ReactComponent as KeyboardBackspace } from "../../assets/icons/keyboard-backspace.svg";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { ArrowForwardIos, ArrowForwardIosSharp } from "@mui/icons-material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useState } from "react";
import { preguntasRespuestas, manuales, tutoriales } from "./data";
import { useAuthContext } from "../../shared/auth/AuthContext";
import DOMPurify from "dompurify";
import Layout from "../../components/Layout";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: "none",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === 'dark'
  //     ? 'rgba(255, 255, 255, .05)'
  //     : 'rgba(0, 0, 0, .03)',
  backgroundColor: "rgba(0, 0, 0, .03)",
  border: "none",
  borderBottom: `1px solid ${FichasColor[Math.floor(Math.random() * 3)]}`,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export const Help = () => {
  const navigate = useHistory();
  const authContext = useAuthContext();
  const { user } = authContext;
  const [expanded, setExpanded] = useState<string | false>("panel1");

  console.log("usuario :: ", user);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const handleToUrl = (_url: string) => {
    console.log("Ir a tutorial >> ", _url);
    const _aLink = document.createElement("a");
    _aLink.href = _url;
    _aLink.target = "_blank";
    _aLink.click();
  };

  return (
    <Layout bgColor="custom">
      <StyledBox display="flex" justifyContent="center" id="maxExperiencias">
        <Box
          sx={{
            width: { xs: "100%", md: "75%" },
            height: "100%",
            margin: 0,
            padding: 0,
          }}
        >
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            alignContent="center"
            mb={6}
          >
            <Box display="flex" alignItems="center">
              <IconButton
                sx={{ mt: 4 }}
                size="small"
                onClick={() =>
                  navigate.push("/", {
                    replace: true,
                  })
                }
              >
                <KeyboardBackspace />
              </IconButton>
              <Typography
                component={"span"}
                sx={{
                  ...DesignSystem?.titulo1,
                  pl: 2,
                  textAlign: "center",
                  mt: 4,
                }}
              >
                Preguntas Frecuentes
              </Typography>
            </Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography>
              {/* Preguntas frecuentes para el uso de la aplicación */}
            </Typography>
            {user &&
              user.rol &&
              preguntasRespuestas
                .filter((faq) => faq.rol === user.rol)
                .map((faq: any, idxFaq: number) => (
                  <Accordion
                    key={idxFaq}
                    expanded={expanded === `panel${idxFaq}`}
                    onChange={handleChange(`panel${idxFaq}`)}
                  >
                    <AccordionSummary>
                      <Typography
                        sx={{ ...DesignSystem?.titulo2, color: "#5baad2" }}
                      >
                        {faq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <Typography sx={{ wordBreak: 'break-word' }}>
                      { faq.answer }
                    </Typography> */}
                      <div
                        className="preview"
                        dangerouslySetInnerHTML={createMarkup(faq.answer)}
                      ></div>
                    </AccordionDetails>
                  </Accordion>
                ))}
          </Box>

          <Box my={5}>
            <Typography
              sx={{ ...DesignSystem?.titulo1, pl: 2, textAlign: "center" }}
            >
              Manuales
            </Typography>
          </Box>
          <Box sx={{ my: 2 }}>
            {user &&
              user.rol &&
              manuales
                .filter((tutorial) => tutorial.rol === user.rol)
                .map((tutorial: any, idxTutorial: number) => (
                  <Card
                    key={idxTutorial}
                    elevation={0}
                    sx={{
                      // backgroundColor: 'rgba(255, 255, 255, .02)',
                      backgroundColor: "rgba(0, 0, 0, .02)",
                      borderRadius: "8px",
                      border: `1px solid ${FichasColor[idxTutorial % 3]}`,
                      my: 3,
                    }}
                    onClick={() => handleToUrl(tutorial.url)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        py: 1,
                      }}
                    >
                      {/* <PlaySquare /> */}
                      <Typography
                        sx={{
                          ml: 2,
                          ...DesignSystem?.titulo2,
                          textDecoration: "underline",
                          color: "#5baad2",
                        }}
                      >
                        {tutorial.title}
                      </Typography>
                      {/* <ArrowRightIcon /> */}
                      <IconButton onClick={() => handleToUrl(tutorial.url)}>
                        <ArrowForwardIos />
                      </IconButton>
                    </Box>
                  </Card>
                ))}
          </Box>

          <Box my={5}>
            <Typography
              sx={{ ...DesignSystem?.titulo1, pl: 2, textAlign: "center" }}
            >
              Videotutoriales
            </Typography>
          </Box>
          <Box sx={{ my: 2 }}>
            {user &&
              user.rol &&
              tutoriales
                .filter((tutorial) => tutorial.rol === user.rol)
                .map((tutorial: any, idxTutorial: number) => (
                  <Card
                    key={idxTutorial}
                    elevation={0}
                    sx={{
                      // backgroundColor: 'rgba(255, 255, 255, .02)',
                      backgroundColor: "rgba(0, 0, 0, .02)",
                      borderRadius: "8px",
                      border: `1px solid ${FichasColor[idxTutorial % 3]}`,
                      my: 3,
                    }}
                    onClick={() => handleToUrl(tutorial.url)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        py: 1,
                      }}
                    >
                      {/* <PlaySquare /> */}
                      <Typography
                        sx={{
                          ml: 2,
                          ...DesignSystem?.titulo2,
                          textDecoration: "underline",
                          color: "#5baad2",
                        }}
                      >
                        {tutorial.title}
                      </Typography>
                      {/* <ArrowRightIcon /> */}
                      <IconButton onClick={() => handleToUrl(tutorial.url)}>
                        <ArrowForwardIos />
                      </IconButton>
                    </Box>
                  </Card>
                ))}
          </Box>
        </Box>
      </StyledBox>
    </Layout>
  );
};
