import { Box, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { InternUnit } from "../context";
import CInputForm from "../../../shared/components/form/CInputForm";
import { CourseContentDialog } from "../shared/Elements";

type UnitDataProps = {
  open: boolean;
  onClose: () => void;
  unidad?: InternUnit;
  onConfirm: (valoresUnidad: UnidadFormType) => void;
};
export type UnidadFormType = {
  titulo: string;
  objetivo: string;
};

export function UnitData({ open, onClose, unidad, onConfirm }: UnitDataProps) {
  const { control, getValues } = useForm<UnidadFormType>({
    defaultValues: {
      titulo: unidad?.titulo ?? "",
      objetivo: unidad?.objetivo ?? "",
    },
  });
  // const { titulo, objetivo, handleInputChange } = useFormLocale<UnidadFormType>(
  //   {
  //     titulo: unidad?.titulo ?? "",
  //     objetivo: unidad?.objetivo ?? "",
  //   }
  // );

  const handleConfirm = () => {
    onConfirm({
      titulo: getValues("titulo"),
      objetivo: getValues("objetivo"),
    });
  };

  return (
    <CourseContentDialog
      open={open}
      onClose={onClose}
      ctitle={unidad !== undefined ? "Editar unidad" : "Crear nueva unidad"}
      actiontext={unidad !== undefined ? "Guardar" : "Crear"}
      handleConfirm={handleConfirm}
    >
      <Box
        sx={{
          textAlign: "center",
        }}
        pt={2}
      >
        <Grid container direction="column" spacing={3} justifyContent="center">
          <Grid item container xs={12}>
            <Controller
              name="titulo"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CInputForm
                  type="text"
                  placeholder="Escribe el título de la unidad"
                  fullWidth
                  {...field}
                  ref={null}
                />
              )}
            />
          </Grid>

          <Grid item container xs={12}>
            <Controller
              name="objetivo"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <CInputForm
                  type="text"
                  placeholder="Escribe el objetivo de la unidad"
                  fullWidth
                  {...field}
                  ref={null}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </CourseContentDialog>
  );
}
