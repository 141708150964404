import { Box, Grid, Typography, styled } from "@mui/material";
import theme from "../../../../themes";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  backgroundColor: "#ededed",
}));

const AboutUs = () => {
  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: "100%", height: "100%" }}
        >
          <Box display="flex" flexDirection="column">
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                padding: "1.5em 1.5em 1.5em 1.5em",
              }}
            >
              <Typography sx={{ color: theme.palette.neutral.active, fontFamily: 'Barlow', fontSize: '30px', lineHeight: '1',padding: "0em 0em 0.4em 0em", }}>
                ¿Qué es la Comunidad Virtual “Vida Digna Sin Violencia”?
              </Typography>
              
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  color: theme.palette.neutral.hover,
                }}
              >
                Es una plataforma interactiva, amigable y vivencial que tiene el propósito de compartir información y buenas prácticas de los municipios, articulando y construyendo valores comunes con equidad e igualdad de género.
                Entre todas y todos buscamos contribuir a que las mujeres alcancen una vida libre de violencia.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </StyledBox>
  );
};

export default AboutUs;
