import React from "react";
import { Box, Card, CardMedia, Typography, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ExperienceCardProps } from "../../../../../../types/ExperienceCardTypes";
import theme from "../../../../../../themes";
import listenIcon from "../../../../../../assets/GoodMunicipalPractices/icons/ico1_nomal.png";
import videoIcon from "../../../../../../assets/GoodMunicipalPractices/icons/ico4_nomal.png";
import photoIcon from "../../../../../../assets/GoodMunicipalPractices/icons/ico5_nomal.png";
import fileIcon from "../../../../../../assets/GoodMunicipalPractices/icons/ico3_nomal.png";


const StyledCard = styled(Card)(() => ({
  borderRadius: "8px",
  width: "100%",
  backgroundColor: "#FFFFFF",
  transitionProperty: "all",
  transitionDuration: ".5s",
  transitionTimingFunction: "top ease",
  transitionDelay: "0",
  "&:hover": {
    transform: "translate(0, -1em)",
    backgroundColor: "#808080",
  },
}));

const StyledCardMedia = styled(CardMedia)(() => ({
  position: "relative",
  height: 515,
}));

const DownloadBox = styled(Box)(({ theme }) => ({
  border: 0,
  padding: 0,
  color: "#FFFFFF",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const BoxT = styled(Box)(() => ({
  border: 0,
  padding: 0,
  height: "100%",
  background: "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))",
  "&:hover": {
    background:
      "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2))",
  },
}));

const StyledIcon = styled('img')(() => ({
  width: '24',
  height: '24',
  [theme.breakpoints.down('md')]: {
    width: '20',
    height: '20',
  }
}));

const ExperienceCard: React.FC<ExperienceCardProps> = ({
  title,
  dpto,
  mcp,
  text,
  image,
  customHeight,
  resourceType,
  resourceUrl,
  handleOpenModal,
  handleModalResource  
}) => {

  const setModalValues = () => {
    if(handleOpenModal)
      handleOpenModal()
    if(handleModalResource)
      handleModalResource(resourceType, resourceUrl);
  }

  const openLink = () => {
    window.open(resourceUrl, '_blank')
  }

  const renderIcon = (resourceType:string) => {
    
    if (resourceType === "YOUTUBE")
      return <StyledIcon src={videoIcon} alt="video ico" />
    else if (resourceType === "SOUNDCLOUD")
      return <StyledIcon src={listenIcon} alt="audio ico" />
    else if(resourceType === "LINK_FOTOS")
      return <StyledIcon src={photoIcon} alt="link ico" />
    else if(resourceType === "LINK_ARCHIVO")
      return <StyledIcon src={fileIcon} alt="link ico" />
    else
      return null;

  }

  const renderLabel = (resourceType:string) => {
    
    if (resourceType === "YOUTUBE")
      return <Typography sx={{ fontFamily: 'Barlow', color: '#FFFFFF', textTransform: 'uppercase' }} onClick={setModalValues}>
        Mira el video
      </Typography>
    else if (resourceType === "SOUNDCLOUD")
      return <Typography sx={{ fontFamily: 'Barlow', color: '#FFFFFF', textTransform: 'uppercase' }} onClick={setModalValues}>
        Escucha el audio
      </Typography>
    else if(resourceType === "LINK_FOTOS")
      return <Typography sx={{ fontFamily: 'Barlow', color: '#FFFFFF', textTransform: 'uppercase' }} onClick={openLink}>
        Mira las fotos
      </Typography>
    else if(resourceType === "LINK_ARCHIVO")
      return <Typography sx={{ fontFamily: 'Barlow', color: '#FFFFFF', textTransform: 'uppercase' }} onClick={openLink}>
        Revisa el enlace
      </Typography>
  }

  return(
    <StyledCard>
      <StyledCardMedia image={image} sx={{ height: { md: customHeight } }}>
        <BoxT display="flex" flexDirection="column">
          <Box sx={{ height: "35%", margin: 0, padding: 0 }}></Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{ height: "65%", margin: 0, padding: " 0em 1.5em 1.5em 1.5em" }}
          >
            <Box sx={{ overflow: "hidden", border: 0, padding: 0 }}>
              <Typography
                sx={{
                  fontFamily: "Barlow",
                  fontSize: "28px",
                  color: "#FFFFFF",
                  lineHeight: "1",
                }}
              >
                {title}
              </Typography>
              <Typography
                color="primary"
                sx={{ fontFamily: "Ubuntu", fontSize: "14px" }}
              >
                {dpto} - {mcp}
              </Typography>
              <Typography
                sx={{ fontFamily: "Ubuntu", fontSize: "14px", color: "#FFFFFF" }}
              >
                {text}
              </Typography>
            </Box>
            { resourceType ? 
              <Box mt={'0.5em'}>
                <Box display="inline-flex" justifyContent="flex-start"
                  onClick={resourceType === "YOUTUBE" || resourceType === "SOUNDCLOUD" ? setModalValues : openLink}
                  sx={{ backgroundColor: "#dddddd", borderRadius: '16px' , cursor: 'pointer'}}
                >
                  <Box display="flex" alignItems="center" sx={{ width: '100%' }} px={'12px'}>
                    {renderIcon(resourceType)}
                    {renderLabel(resourceType)}
                    
                  </Box>
                </Box>
              </Box> : null
            }
          </Box>
        </BoxT>
      </StyledCardMedia>
    </StyledCard>
  )
};

export default ExperienceCard;
