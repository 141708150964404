import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {useState} from "react";
import {Link, useHistory} from "react-router-dom";

import {Departamento, Municipio, useGetMunicipios} from "./DirectorioClient";
import CSelectForm from "../../shared/components/form/CSelectForm";
import {KeyboardBackspace} from "@mui/icons-material";
import {DesignSystem} from "../../themes/theme";

export const DirectorioPage = () => {
  const navigate = useHistory();
  const { isLoading, isError, data } = useGetMunicipios();

  const [departamento, setDepartamento] = useState<Departamento | undefined>();
  const [municipio, setMunicipio] = useState<Municipio>();

  if (isLoading) {
    return <Box>Cargando...</Box>;
  }
  if (isError) {
    return <Box>Hubo un error</Box>;
  }

  const departamentosItems = data!.departamentos.map((dpto:any) => ({
    value: dpto.departamento_cod,
    label: dpto.nombre,
  }));
  const selectDepartamento = (cod: string) =>
    setDepartamento(
      data?.departamentos.find((dpto:any) => dpto.departamento_cod === Number(cod)),
    );

  const municipiosItems = departamento?.municipios?.map((muni) => ({
    value: muni.municipio_cod,
    label: muni.municipio_desc,
  }));
  const selectMunicipio = (cod: string) =>
    setMunicipio(
      departamento?.municipios?.find(
        (muni) => muni.municipio_cod === Number(cod),
      ),
    );

  return (
    <Grid container gap={2} direction={"column"} alignItems={"center"}>
      <Grid item container>
        <Box display="flex" alignItems="center">
          <IconButton
            size="large"
            onClick={() =>
              navigate.push("/", {
                replace: true,
              })
            }
          >
            <KeyboardBackspace sx={{color: '#28538e', fontSize: 40 }} />
          </IconButton>
          <Typography sx={{ ...DesignSystem?.titulo1, pl: 2 }}>
            Directorio Municipal
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box maxWidth={760}>
          <Card>
            <CardHeader title={"Directorio Municipal"}> </CardHeader>
            <CardContent>
              <Box display={"flex"} gap={2} flexDirection={"column"}>
                <CSelectForm
                  placeholder="Selecciona un departamento"
                  fullWidth
                  value={String(departamento?.departamento_cod ?? "")}
                  items={departamentosItems}
                  onChange={(event: SelectChangeEvent) =>
                    selectDepartamento(event.target.value)
                  }
                />
                <CSelectForm
                  placeholder="Selecciona un municipio"
                  fullWidth
                  value={String(municipio?.municipio_cod ?? "")}
                  items={municipiosItems}
                  onChange={(event: SelectChangeEvent) =>
                    selectMunicipio(event.target.value)
                  }
                />

                <Button
                  variant={"contained"}
                  disabled={!municipio}
                  component={Link}
                  to={`directorio/${municipio?.municipio_cod}`}
                >
                  Editar Directorio
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
};
