import { Box, Checkbox, Grid, IconButton } from "@mui/material";
import produce from "immer";
import React from "react";
import { AddCircleOutlineRounded, DeleteOutlineRounded } from '@mui/icons-material'
import { OpcionPregunta } from "../../../interface";
import CInputForm from "../../../../../shared/components/form/CInputForm";
import { PreguntaSeleccionMultiple } from "../../../types";

type MultipleSelectionEditorProps = {
  pregunta: PreguntaSeleccionMultiple;
  onPreguntaChange: (pregunta: PreguntaSeleccionMultiple) => void;
};

export function MultipleSelectionEditor({
  pregunta,
  onPreguntaChange,
}: MultipleSelectionEditorProps) {
  const opciones = pregunta.opciones || [];
  const handleCheckboxChange =
    (index: number) =>
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onPreguntaChange(
        produce(pregunta, (draft: any) => {
          draft.opciones[index].esCorrecta = checked;
        })
      );
    };
  const handleTextChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onPreguntaChange(
        produce(pregunta, (draft: any) => {
          draft.opciones[index].texto = event.target.value;
        })
      );
    };

  const agregarOpcion = () => {
    onPreguntaChange(
      produce(pregunta, (draft: any) => {
        draft.opciones.push({
          texto: "",
          esCorrecta: false,
        });
      })
    );
  };

  const deleteOpcion = (index: number) => () => {
    onPreguntaChange(
      produce(pregunta, (draft: any) => {
        draft.opciones.splice(index, 1);
      })
    );
  };

  if (opciones.length <= 0) {
    agregarOpcion();
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={11}>
        <Box display="flex" flexDirection="column">
          {opciones.map((opcion: OpcionPregunta, idxOpcion: number) => (
            <Box
              display="inline-flex"
              key={idxOpcion}
              sx={{
                mt: 1,
              }}
            >
              <Checkbox
                checked={opcion.esCorrecta}
                onChange={handleCheckboxChange(idxOpcion)}
                name={opcion.texto}
              />
              <CInputForm
                placeholder="Introduzca la respuesta"
                value={opcion.texto}
                onChange={handleTextChange(idxOpcion)}
              />
              {idxOpcion === opciones.length - 1 && (
                <IconButton
                  size="small"
                  sx={{
                    // fill: colorsBase.Verde3,
                    fill: 'green',
                  }}
                  onClick={agregarOpcion}
                >
                  <AddCircleOutlineRounded />
                </IconButton>
              )}
              {opciones.length > 1 && (
                <IconButton
                  size="small"
                  sx={{
                    fill: 'red',
                  }}
                  onClick={deleteOpcion(idxOpcion)}
                >
                  <DeleteOutlineRounded />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} md={1}></Grid>
    </Grid>
  );
}
