import { Box, Typography, styled } from "@mui/material";
import theme from "../../../../themes";
import workApproach from  "../../../../assets/WhoWeAre/work_approach.png";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  backgroundColor: "#FFFFFF",
}));

const ApproachBox = styled(Box)(({theme}) => ({
  margin: "20px 0 20px 0",
  padding: 0,
  width: "100%",
  height: 700,
  backgroundImage: `url(${workApproach})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    height: 500,
    margin: '0 10px 0 10px',
    // padding: '0 10px 0 10px',
  },
}));

const Rights = () => {
  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: "100%", height: "100%" }}
        >
          <Box display="flex" flexDirection="column">
            <Box sx={{ padding: "1.5em 1.5em 0em 1.5em" }}>
              <Typography
                variant="h1"
                style={{
                  fontFamily: "Barlow",
                  fontSize: 42,
                  color: theme.palette.neutral.active,
                }}
              >
                Enfoque de trabajo
              </Typography>
              <Typography sx={{ color: theme.palette.neutral.active, fontFamily: 'Barlow', fontSize: '30px', lineHeight: '1',padding: "0em 0em 0.4em 0em", }}>
                Vida Digna Sin Violencia
              </Typography>
              {/* <Typography
                variant="h1"
                style={{
                  fontFamily: "Barlow",
                  fontSize: 42,
                  color: theme.palette.neutral.active,
                }}
              >
                
              </Typography> */}
            </Box>
            <ApproachBox>

            </ApproachBox>
            {/* <Box display="flex" gap={3} flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" sx={{ width: '100%', height: '100%', padding: '0 1.5em 0 1.5em' }}>
              <Box display="flex" sx={{ flex: 1, width: { xs: '100%', md: '25%' } }}>
                <RightsBox prefix="Derecho a una" rightText="Vida Libre de Violencia" colorStr={theme.palette.secondary.main} image={r1} />
              </Box>
              <Box display="flex" sx={{ flex: 1, width: { xs: '100%', md: '25%' } }}>
                <RightsBox prefix="Derecho a la" rightText="Autonomía Económica" colorStr={theme.palette.primary.main} image={r2} />
              </Box>
              <Box display="flex" sx={{ flex: 1, width: { xs: '100%', md: '25%' } }}>
                <RightsBox prefix="Derecho a la" rightText="Participación Política" colorStr={theme.palette.info.hover} image={r3} />
              </Box>
              <Box display="flex" sx={{ flex: 1, width: { xs: '100%', md: '25%' } }}>
                <RightsBox prefix="Derechos" rightText="Sexuales y Derechos reproductivos" colorStr={theme.palette.success.main} image={r4} />
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </StyledBox>
  );
};

export default Rights;
