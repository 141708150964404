import Layout from "../../components/Layout";
import Footer from "../../components/Footer";
import {alpha, Box, Button, darken, Grid, Typography, useMediaQuery, useTheme,} from "@mui/material";
import {useHistory, useLocation} from "react-router-dom";
import {CheckBoxRounded, FlagRounded, PowerSettingsNewRounded,} from "@mui/icons-material";
import {CircleBox, CoursePrimaryButton, CourseWhiteButton,} from "../Courses/shared/Elements";
import {useState} from "react";
import AuthDialog, {AuthType} from "../Auth";
import {useAuthContext} from "../../shared/auth/AuthContext";
import {CourseCatalog} from "../Courses/types/CourseCatalog.type";
import {useStudentInscription} from "../Courses/student/data/StudentCourseClient";
import {printLog} from "../../shared/utils";
import {Alerts} from "../../shared/hooks/useAlert";

type CourseCatalogDetailStateProps = {
  course: CourseCatalog;
};

const CourseCatalogDetail = () => {
  const theme = useTheme();
  const history = useHistory();
  let location = useLocation();
  const authContext = useAuthContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const myState: CourseCatalogDetailStateProps =
    location.state as unknown as CourseCatalogDetailStateProps;
  printLog("myState in course catalog detail >>> ", myState);
  const { course } = myState;

  const studentInscription = useStudentInscription();

  const [authForm, setAuthForm] = useState(false);
  const [typeAuth, setTypeAuth] = useState<AuthType>();
  const [loading, setLoading] = useState(false);

  const handleOpenAuthForm = (type: AuthType) => {
    setTypeAuth(type);
    setAuthForm(true);
  };

  const handleCloseAuthForm = () => {
    setAuthForm(false);
  };

  const handleStudentInscription = async () => {
    setLoading(true);
    printLog("Inscribiendo al curso > ", course.id);
    if (!course.id) {
      Alerts.showWarning({
        description: "La inscripción al curso no está disponible",
      });
      return;
    }
    printLog("al authContext.user >> ", authContext.user);
    const inscriptionStudent = await studentInscription({
      plantillaCursoId: course.id,
    }).finally(() => setLoading(false));
    printLog("Estudiante inscrito :: ", inscriptionStudent);
    history.push("/cursos");
  };
  // TODO: Recibir esto del backend
  const disabled = loading;

  const fecha = disabled ? "15 de enero de 2024" : "17 de noviembre de 2023";
  return (
    <Layout bgColor={theme.palette.neutral.main}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
        sx={{ background: theme.palette.neutral.main, px: "1.5rem" }}
      >
        <Box
          sx={{
            background: "white",
            width: {
              xs: "100%",
              md: theme.breakpoints.values.md,
              lg: theme.breakpoints.values.lg,
            },
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              margin: 0,
              padding: 0,
              width: "100%",
              height: 300,
              backgroundImage: `url(${course.urlImagen})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></Box>
          <Box sx={{ paddingTop: "2rem", paddingBottom: "4rem", px: "1.5rem" }}>
            <Typography sx={{ fontSize: "2.5rem" }}>{course.nombre}</Typography>
            <Grid
              container
              gap={3}
              justifyContent="space-between"
              sx={{ mt: "1.5rem" }}
            >
              <Grid item xs={12} md={7}>
                <Typography
                  fontSize={"1.5rem"}
                  color={theme.palette.neutral.hover}
                >
                  Descripción
                </Typography>
                <Typography color={theme.palette.neutral.hover}>
                  {course.descripcion}
                </Typography>
                <Box my={5}>
                  {!authContext.user && (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gap={2}
                      sx={{
                        borderRadius: "10px",
                        // background: alpha(theme.palette.neutral.main, 0.5),
                        background: "rgba(0,119,170,1)",
                        p: 2,
                        mt: 2,
                        mx: isMobile ? 0 : 4,
                      }}
                    >
                      <CoursePrimaryButton
                        onClick={() => handleOpenAuthForm(AuthType.LOGIN)}
                        disabled={disabled}
                        size={isMobile ? "small" : "medium"}
                        sx={{
                          background: "rgba(0,191,232,1)",
                          "&:hover": {
                            background: darken("rgba(0,191,232,1)", 0.05),
                          },
                        }}
                      >
                        Inicia sesión para inscribirte
                      </CoursePrimaryButton>
                      <Box
                        display={"flex"}
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent={"space-evenly"}
                        gap={isMobile ? 2 : 0}
                      >
                        <Typography
                          sx={{
                            alignSelf: "center",
                            color: "white",
                            fontSize: isMobile ? "0.85rem" : "1rem",
                          }}
                        >
                          ¿NO TIENES UNA CUENTA?
                        </Typography>
                        <CourseWhiteButton
                          size={isMobile ? "small" : "medium"}
                          onClick={() => handleOpenAuthForm(AuthType.REGISTER)}
                        >
                          Crea tu cuenta
                        </CourseWhiteButton>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={2}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "10px",
                    mt: "3rem",
                    px: isMobile ? 2 : 4,
                    py: 3,
                  }}
                >
                  <Typography
                    color={theme.palette.info.main}
                    fontSize={"1.7rem"}
                    sx={{ fontWeight: 500 }}
                  >
                    Para inscribirte al curso considera lo siguiente:
                  </Typography>
                  <Box display={"flex"} gap={2} alignItems={"center"}>
                    <CheckBoxRounded color="info" fontSize="medium" />
                    <Typography>
                      Debes iniciar sesión para poder inscribirte a los cursos
                      pulsando en el botón Inicia sesión para inscribirte
                    </Typography>
                  </Box>
                  <Box display={"flex"} gap={2} alignItems={"center"}>
                    <CheckBoxRounded color="info" fontSize="medium" />
                    <Typography>
                      Si no tienes una cuenta creada, pulsa en el botón "Crea tu
                      cuenta" y llena el formulario para crear tu cuenta dentro
                      de la comunidad virtual Vida Digna Sin Violencia
                    </Typography>
                  </Box>
                  <Box display={"flex"} gap={2} alignItems={"center"}>
                    <CheckBoxRounded color="info" fontSize="medium" />
                    <Typography>
                      Una vez que tengas tu cuenta, podrás inscribirte y acceder
                      a todos los contenidos del curso
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={3}
                  sx={{
                    background:
                      "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                    borderRadius: "10px",
                    color: "white",
                    py: 3,
                    px: 2,
                    // pl: '1.5rem',
                    // pr: '7rem'
                  }}
                >
                  <Box display={"flex"} alignItems={"center"} gap={2}>
                    <Box
                      sx={{
                        borderRadius: "8px",
                        background: alpha(theme.palette.info.main, 0.9),
                        display: "flex",
                        alignItems: "center",
                        p: 1,
                      }}
                    >
                      <CircleBox
                        sx={{ background: theme.palette.info.hover, p: 1 }}
                      >
                        <PowerSettingsNewRounded />
                      </CircleBox>
                    </Box>
                    <Box>
                      <Typography fontSize={"1.2rem"} sx={{ fontWeight: 500 }}>
                        FECHA DE INICIO
                      </Typography>
                      {/* TODO: pendiente fecha de inicio de curso */}
                      <Typography>{fecha}</Typography>
                    </Box>
                  </Box>
                  <Box display={"flex"} gap={2}>
                    <Box
                      sx={{
                        borderRadius: "8px",
                        background: alpha(theme.palette.info.main, 0.9),
                        display: "flex",
                        alignItems: "center",
                        p: 1,
                      }}
                    >
                      <CircleBox
                        sx={{ background: theme.palette.info.hover, p: 1 }}
                      >
                        <FlagRounded />
                      </CircleBox>
                    </Box>
                    <Box>
                      <Typography fontSize={"1.2rem"} sx={{ fontWeight: 500 }}>
                        FECHA DE FINALIZACIÓN
                      </Typography>
                      {/* TODO: pendiente fecha de finalizacion de curso */}
                      <Typography>31 de diciembre de 2025</Typography>
                    </Box>
                  </Box>
                </Box>
                {authContext.user && (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                    sx={{
                      borderRadius: "10px",
                      background: alpha(theme.palette.neutral.main, 0.5),
                      p: 2,
                      mt: 2,
                    }}
                  >
                    <CoursePrimaryButton
                      onClick={handleStudentInscription}
                      disabled={disabled}
                    >
                      Inscribirme al curso
                    </CoursePrimaryButton>
                    {/* <Typography sx={{ alignSelf: "center" }}>
                    ¿NO TIENES UNA CUENTA?
                  </Typography>
                  <CourseWhiteButton
                    onClick={() => handleOpenAuthForm(AuthType.REGISTER)}
                  >
                    Crea tu cuenta
                  </CourseWhiteButton> */}
                  </Box>
                )}
                {authForm && (
                  <AuthDialog
                    open={authForm}
                    onClose={handleCloseAuthForm}
                    type={typeAuth}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={7} sx={{ mt: "3rem" }}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Button
                    variant="outlined"
                    onClick={() => history.push("/catalogo-cursos")}
                    sx={{
                      borderColor: alpha(theme.palette.common.black, 0.5),
                      color: alpha(theme.palette.common.black, 0.5),
                      py: 1,
                      borderRadius: "8px",
                      "&:hover": {
                        borderColor: alpha(theme.palette.common.black, 0.7),
                        color: alpha(theme.palette.common.black, 0.7),
                        background: "transparent",
                      },
                    }}
                  >
                    Volver Atrás
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Layout>
  );
};
export default CourseCatalogDetail;
