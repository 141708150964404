// import { useAuthContext } from "../../common/auth/AuthContext";
// import useHttp from "../../common/useHttp";
import { useAuthContext } from "../../shared/auth/AuthContext";
import useHttp from "../../shared/hooks/useHttp";
import { printLog } from "../../shared/utils";
import {
  ActualizacionDeNoticiasParams,
  CreacionDeNoticiasParams,
} from "./IniciativaInterface";

export function useGetStockImages(): () => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async () => {
    return await http.get(`/admin/stockImage`, {
      auth: authContext?.user?.token ?? undefined,
    });
  };
}

export function useGetExperienciaPractica(): () => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async () => {
    return await http.get(`/admin/iniciativa`, {
      auth: authContext?.user?.token ?? undefined,
    });
  };
}

export function useGetNoticiaById(): (noticiaId: string) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async (noticiaId: string) => {
    const result: any = await http.get(`/admin/iniciativa/${noticiaId}`, {
      auth: authContext?.user?.token ?? undefined,
    });
    return result;
  };
}

export function useCreateIniciativa(): (params: any) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async (params: any) => {
    debugger;
    const arrayData = new FormData();
    arrayData.append("nombre", "a");
    arrayData.append("celular", "7");
    arrayData.append("fondo", params.fondo);
    arrayData.append("bloque", params.bloque ? params.bloque : "");
    arrayData.append("departamento_cod", params.departamento_cod);
    arrayData.append("municipio_cod", params.municipio_cod);
    arrayData.append("titulo", params.titulo);
    arrayData.append("descripcion", params.descripcion);
    arrayData.append("gestion", params.gestion);
    arrayData.append("destacada", JSON.stringify(Boolean(params.destacada)));

    if (params.estado === "PUBLICADO")
      arrayData.append("publicada", JSON.stringify(true));
    else arrayData.append("publicada", JSON.stringify(false));

    arrayData.append("tipoRecurso", params.tipoRecurso);
    arrayData.append("recurso", params.recurso);
    arrayData.append("stockImageUrl", params.stockImageUrl);

    arrayData.append(
      "adjuntosEliminados",
      JSON.stringify(params.adjuntosEliminados)
    );

    for (let index = 0; index < params.adjunto.length; index++) {
      arrayData.append("adjunto", params.adjunto[index]);
    }
    printLog("arrayData antes de enviar  :: ", arrayData.get("titulo"));

    // if (params.tipo === "NOTICIA") {
    return await http.post(`/admin/iniciativa`, {
      payload: arrayData,
      auth: authContext?.user?.token ?? undefined,
      withFiles: true,
    });
    // } else {
    //   return await http.post(`/admin/publicacion/enlace`, {
    //     payload: arrayData,
    //     auth: authContext?.user?.token ?? undefined,
    //     withFiles: true,
    //   });
    // }
  };
}

export function useDeleteIniciativa() {
  const http = useHttp();
  return async (id: string) => {
    return await http.delete(`/admin/iniciativa/${id}`, {});
  };
}

export function useEditNoticia(): (
  params: ActualizacionDeNoticiasParams,
  noticiaId: string
) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();
  return async (params: any, noticiaId: string) => {
    debugger;
    const arrayData = new FormData();
    arrayData.append("nombre", params.nombre);
    arrayData.append("celular", params.celular);
    arrayData.append("fondo", params.fondo);
    arrayData.append("bloque", params.bloque ? params.bloque : "");
    arrayData.append("departamento_cod", params.departamento_cod);
    arrayData.append("municipio_cod", params.municipio_cod);
    arrayData.append("titulo", params.titulo);
    arrayData.append("descripcion", params.descripcion);
    arrayData.append("gestion", params.gestion);
    arrayData.append("destacada", JSON.stringify(Boolean(params.destacada)));
    debugger;
    if (params.estado === "PUBLICADO")
      arrayData.append("publicada", JSON.stringify(true));
    else arrayData.append("publicada", JSON.stringify(false));

    arrayData.append("tipoRecurso", params.tipoRecurso);
    arrayData.append("recurso", params.recurso);

    arrayData.append("stockImageUrl", params.stockImageUrl);

    arrayData.append(
      "adjuntosEliminados",
      JSON.stringify(params.adjuntosEliminados)
    );
    debugger;
    for (let index = 0; index < params.adjunto.length; index++) {
      arrayData.append("adjuntos", params.adjunto[index]);
    }

    // for (let index = 0; index < params.archivos.length; index++) {
    //   arrayData.append('adjuntos', params.archivos[index]);
    // }
    // printLog('arrayData antes de enviar  :: ', arrayData.get('titulo'));

    return await http.put(`/admin/iniciativa/${noticiaId}`, {
      payload: arrayData,
      auth: authContext?.user?.token ?? undefined,
      withFiles: true,
    });
  };
}
