import { Box, Button, Grid, Typography, styled } from "@mui/material";
import theme from "../../../themes";
import { TipoRecurso } from "./MainCampaignCard";
import { printLog } from "../../../shared/utils";

const StyledImg = styled("img")(() => ({
  maxWidth: "100%",
  height: "100%",
  objectFit: "contain",
}));

export type CommunicationResourceItemProps = {
  titulo: string;
  enlace: string;
  tipo: string;
  imagen: string;
  color?: string;
};

export const CommunicationResourceItem = ({
  tipo,
  titulo,
  enlace,
  imagen,
  color,
}: CommunicationResourceItemProps) => {
  // const images = require.context('../../public/images', true);
  return (
    <Grid
      container
      spacing={2}
      mb={1}
      sx={{ borderBottom: `1px solid ${theme.palette.grey[400]}`, pb: 1 }}
    >
      <Grid item xs={12} md={3}>
        {/* imagen */}
        {!!imagen && imagen !== "" && (
          <StyledImg
            src={require(`./../../../assets/Communication/${imagen}`)}
            alt={`img-${titulo}`}
          />
        )}
      </Grid>
      <Grid item xs={12} md={9}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={3}
          sx={{ color: "#000" }}
        >
          <Typography>{titulo}</Typography>
          <Button
            variant="contained"
            size="small"
            disableElevation
            // color="primary"
            sx={{
              background: color ?? theme.palette.primary.main,
              color: "white",
              borderRadius: "8px",
            }}
            onClick={() => {
              printLog(`Visitar enlace > ${enlace}`);
              window.open(enlace, "_blank");
            }}
          >
            {tipo === TipoRecurso.ARCHIVO && "Leer completo"}
            {tipo === TipoRecurso.VIDEO && "Ver vídeo completo"}
            {tipo === TipoRecurso.AUDIO && "Escuchar audio completo"}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
