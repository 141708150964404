import {
  AddRounded,
  ArrowBackRounded,
  ArrowForwardRounded,
  CloseRounded,
  DoneRounded,
  DownloadDoneRounded,
} from "@mui/icons-material";
import {
  alpha,
  Box,
  Button,
  Grid,
  IconButton,
  MobileStepper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, {ChangeEvent, useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import ImageViewer from "react-simple-image-viewer";
import {TipoPregunta} from "../../../views/Courses/interface";
import {isImage, isValidUrl, printLog} from "../../utils";
import {CFilePicker} from "../form/CFilePicker";
import CInputForm from "../form/CInputForm";
import iconFileUploaded from "./../../../assets/course/cm_adj_dll.svg";
import theme from "../../../themes";
import {Alerts} from "../../hooks/useAlert";
import {AdjuntoMetadata, AdjuntosMap, QuizFinishedEvent, RespuestaEntregable, RespuestasMap} from "./CursoViewerTypes";

type QuizViewerProps = {
  idxContenido: number;
  tipo: "QUIZ" | "ENTREGABLE";
  preguntas: any[];
  onFinalizarQuiz: (data: QuizFinishedEvent) => void;
  actionsPosition?: "bottom" | "top" | "static";
  isEvaluated?: boolean
  onCancel?: () => void;
};

const classes = {
  btnQuiz: {
    background: alpha(theme.palette.courseSecondary.main, 0.15),
    color: theme.palette.courseSecondary.main,
    fontWeight: 700,
    borderRadius: "8px",
    px: 2,
    py: 2,
    "@media (maxWidth:899.95px)": {
      p: 2,
    },
  },
  btnQuizSelected: {
    background:
      "linear-gradient(180deg, rgba(203,42,182,1) 0%, rgba(149,29,145,1) 53%, rgba(96,15,93,1) 100%)",
    color: "#fff",
    borderRadius: "8px",
    px: 2,
    py: 2,
    "@media (maxWidth:899.95px)": {
      p: 2,
    },
  },
};

export const QuizViewer = ({
                             idxContenido,
                             tipo,
                             isEvaluated,
                             preguntas,
                             onFinalizarQuiz,
                             actionsPosition = 'bottom',
                             onCancel = () => {}
                           }: QuizViewerProps) => {
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // printLog("is mobile :: ", matchMobile);
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = preguntas.length;
  const [respuestas, setRespuestas] = useState<RespuestasMap>({});
  // TODO: pendiente errores en quiz
  // const [errores, setErrores] = useState<any>({});
  const [adjuntosRespuestas, setAdjuntosRespuestas] = useState<AdjuntosMap>({});
  // const [adjuntos, setAdjuntos] = useState<any[]>([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  /// Context de alertas
  // const alertContext = useAlertModalContext();

  useEffect(() => {
    if(tipo === "QUIZ" && isEvaluated) {
      Alerts.showConfirm({
        title: "¡Estás a punto de demostrar todo lo que has aprendido!",
        description: "El siguiente quiz está sujeto a evaluación, se requiere que el 80% de las preguntas estén correctas para avanzar." +
          " Presiona confirmar para empezar.",
        onConfirm: () => {},
        onCancel
      })
    }

  }, []);

  useEffect(() => {
    printLog(">>> preguntas ::: ", preguntas);
    const _respuestas: RespuestasMap = {};
    preguntas.forEach((pregunta: any, idx: number) => {
      _respuestas[idx] = "";
      if (pregunta.tipo === TipoPregunta.SELECCION_MULTIPLE)
        _respuestas[idx] = [];
      if (
        pregunta.tipo === TipoPregunta.CARGA_ARCHIVO ||
        pregunta.tipo === TipoPregunta.CARGA_VIDEO
      ) {
        if (pregunta.accionPermitida === "ENLACES") _respuestas[idx] = [""];
        if (pregunta.accionPermitida === "ARCHIVOS") _respuestas[idx] = [];
      }
    });
    setRespuestas(_respuestas);
    setActiveStep(0);
  }, [preguntas]);

  const handleSiguientePregunta = async () => {
    if (tipo === "ENTREGABLE") {
      /// evaluar pregunta enlaces
      switch (preguntas[activeStep].accionPermitida) {
        case "ENLACES":
          const erroresEnlaces: number[] = [];

          const respuesta = respuestas[activeStep] as string[];
          respuesta.forEach((_enlace: string, idx: number) => {
            if (!isValidUrl(_enlace)) {
              erroresEnlaces.push(idx + 1);
            }
          });
          if (erroresEnlaces.length) {
            Alerts.showError({
              title: "Enlaces inválidos",
              description: `${
                erroresEnlaces.length > 1 ? "Los enlaces" : "El enlace"
              }  ${erroresEnlaces.join("-")} ${
                erroresEnlaces.length > 1 ? " no son válidos" : " no es válido."
              }`,
            })
            // await alertContext.showErrorAlert({
            //   btnText: "Continuar",
            //   title: "Enlaces inválidos",
            //   message: `${
            //     erroresEnlaces.length > 1 ? "Los enlaces" : "El enlace"
            //   }  ${erroresEnlaces.join("-")} ${
            //     erroresEnlaces.length > 1 ? " no son válidos" : " no es válido."
            //   }`,
            // })
            return;
          }
          break;
      }
    }

    if (activeStep === maxSteps - 1) {
      Alerts.showConfirm({
        title:
          tipo === "QUIZ"
            ? "¿Desea finalizar el quiz?"
            : "¿Desea finalizar el contenido entregable?",
        description:
          "Tenga en cuenta que no podrá modificar sus respuestas luego de confirmar.",
        onConfirm: () => {
          printLog("Finalizar el quiz", respuestas);
          printLog("Adjuntos de respuestas ??? ", adjuntosRespuestas);
          onFinalizarQuiz({
            idxContenido,
            estado: "FINALIZADO",
            respuestas,
            adjuntosRespuestas,
          });
        },
      })
      // const confirmation = await alertContext.showConfirmation({
      //   title:
      //     tipo === "QUIZ"
      //       ? "¿Desea finalizar el quiz?"
      //       : "¿Desea finalizar el contenido entregable?",
      //   message:
      //     "Tenga en cuenta que no podrá modificar sus respuestas luego de confirmar.",
      //   })
      // if (confirmation) {
      //     printLog("Finalizar el quiz", respuestas);
      //     printLog("Adjuntos de respuestas ??? ", adjuntosRespuestas);
      //     onFinalizarQuiz({
      //       idxContenido,
      //       estado: "FINALIZADO",
      //       respuestas,
      //       adjuntosRespuestas,
      //     });
      // }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleAnteriorPregunta = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const setRespuestaUnica = (idxRespuesta: number, valor: string) => {
    const tmpRespuestas = { ...respuestas };
    tmpRespuestas[idxRespuesta] = valor;
    setRespuestas(tmpRespuestas);
  };

  const setRespuestaMultiple = (idxRespuesta: number, valor: string) => {
    const tmpRespuestas = { ...respuestas };
    const respuestaMultiple = tmpRespuestas[idxRespuesta] as string[];
    if (respuestaMultiple.includes(valor)) {
      const idxValor = respuestaMultiple.indexOf(valor);
      respuestaMultiple.splice(idxValor, 1);
    } else {
      respuestaMultiple.push(valor);
    }

    printLog("respuestas despues :: ", tmpRespuestas);
    printLog("respuestas despues :: ", JSON.stringify(tmpRespuestas));
    setRespuestas(tmpRespuestas);
  };

  const handleInputChange =
    (idxRespuesta: number) =>
      ({ target }: ChangeEvent<HTMLInputElement>) => {
        const tmpRespuestas = { ...respuestas };
        tmpRespuestas[idxRespuesta] = target.value;
        setRespuestas(tmpRespuestas);
      };

  const handleInputEnlaceChange =
    (idxRespuesta: number, idxEnlace: number) =>
      ({ target }: ChangeEvent<HTMLInputElement>) => {
        const tmpRespuestas = { ...respuestas };
        const respuesta = tmpRespuestas[idxRespuesta] as string[]
        printLog("evaluando enlace.....");
        respuesta[idxEnlace] = target.value;
        setRespuestas(tmpRespuestas);
      };

  const handleFileChange =
    (idxRespuesta: number) =>
      ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        if(!target.files || target.files.length === 0) {
          return;
        }

        console.log(idxRespuesta);
        const respuestaID = uuidv4();

        const respuesta: RespuestaEntregable = [
          ...(respuestas[idxRespuesta] as AdjuntoMetadata[]),
          {
            tempId: respuestaID,
            nombreArchivo: target.files[0].name,
          }
        ]

        const tmpRespuestas: RespuestasMap = {
          ...respuestas
        };
        tmpRespuestas[idxRespuesta] = respuesta;


        setRespuestas(tmpRespuestas);
        setAdjuntosRespuestas({
          ...adjuntosRespuestas,
          [respuestaID]: target.files[0],
        });
        console.log(tmpRespuestas, {
          ...adjuntosRespuestas,
          [respuestaID]: target.files[0],
        });
      };

  const handleDeleteFile = (
    idxRespuesta: number,
    idxAdjunto: number,
    adjunto: any
  ) => {
    const tmpRespuestas = { ...respuestas };
    let respuesta = tmpRespuestas[idxRespuesta] as AdjuntoMetadata[];
    respuesta.splice(idxAdjunto, 1);
    setRespuestas(tmpRespuestas);
    delete adjuntosRespuestas[adjunto.tempId];
    setAdjuntosRespuestas({ ...adjuntosRespuestas });
  };

  const añadirEnlaceRespuesta = (idxRespuesta: number) => {
    const tmpRespuestas = { ...respuestas };
    const respuestaEnlaces = tmpRespuestas[idxRespuesta] as string[];
    respuestaEnlaces.push("");
    setRespuestas(tmpRespuestas);
  };

  const eliminarEnlaceRespuesta = (idxRespuesta: number, idxEnlace: number) => {
    const tmpRespuestas = { ...respuestas };
    const respuesta = tmpRespuestas[idxRespuesta] as AdjuntoMetadata[]
    respuesta.splice(idxEnlace, 1);
    setRespuestas(tmpRespuestas);
  };

  const downloadAdjunto = (ev: any, _adjunto: any) => {
    ev.stopPropagation();
    printLog("descargar adjunto :: ", _adjunto);
    const link = document.createElement("a");
    const chunks = _adjunto.split("/");
    link.href = _adjunto;
    link.download = chunks[chunks.length - 1]; // nombre del archivo?
    link.target = "_blank";
    link.click();
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        justifyItems="center"
        alignItems="stretch"
      >
        {preguntas[activeStep] && (
          <Box flexGrow="1" display="flex" flexDirection="column" mt={2}>
            <Typography paragraph sx={{ wordBreak: "break-word" }}>
              {preguntas[activeStep].titulo}
            </Typography>
            <Typography
              sx={{
                color: "black",
                wordBreak: "break-word",
                textAlign: "justify",
              }}
            >
              {preguntas[activeStep].descripcion}
            </Typography>
            {/* <Box sx={{ wordBreak: 'break-word' }}>
            {JSON.stringify(preguntas[activeStep])}
          </Box> */}
            <Box my={2}>
              {preguntas[activeStep].urlAdjuntos.length > 0 && (
                <Typography component="span" sx={{ color: "black" }}>
                  Adjunto (s)
                </Typography>
              )}
              {preguntas[activeStep].urlAdjuntos.length > 0 &&
                preguntas[activeStep].urlAdjuntos.map(
                  (urlAdj: any, idxUrlAdj: number) => (
                    <Box key={idxUrlAdj}>
                      {urlAdj !== null && (
                        <>
                          <Box
                            sx={{ mt: 1, ml: 2 }}
                            display="inline-flex"
                            alignItems="center"
                            justifyContent="center"
                            onClick={(ev) => downloadAdjunto(ev, urlAdj)}
                          >
                            <Typography
                              component="span"
                              sx={{
                                textDecoration: "underline",
                              }}
                            >
                              *{" "}
                              {decodeURIComponent(urlAdj.split("/")[urlAdj.split("/").length - 1])}
                            </Typography>
                            <Tooltip title="Descargar adjunto">
                              <IconButton
                                onClick={(ev) => downloadAdjunto(ev, urlAdj)}
                              >
                                <DownloadDoneRounded />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          {isImage(urlAdj) && (
                            <Box
                              sx={{
                                height: "300px",
                                width: "300px",
                                [theme.breakpoints.down("sm")]: {
                                  width: "90%",
                                },
                                [theme.breakpoints.down("xs")]: {
                                  width: "100%",
                                },
                              }}
                            >
                              <img
                                src={urlAdj}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                  cursor: "pointer",
                                }}
                                onClick={() => setIsViewerOpen(true)}
                                alt={`img-curso-${idxUrlAdj}`}
                              />
                              {isViewerOpen && (
                                <ImageViewer
                                  src={[urlAdj]}
                                  currentIndex={0}
                                  onClose={() => setIsViewerOpen(false)}
                                  disableScroll={false}
                                  backgroundStyle={{
                                    backgroundColor: "rgba(0,0,0,0.8)",
                                  }}
                                  closeOnClickOutside={true}
                                />
                              )}
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  )
                )}
            </Box>
            {preguntas[activeStep].tipo === TipoPregunta.SELECCION_UNICA && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        ...classes.btnQuiz,
                        ...(respuestas[activeStep] != null &&
                          respuestas[activeStep] ===
                          preguntas[activeStep].opcion1.texto &&
                          classes.btnQuizSelected),
                        width: "100%",
                        wordBreak: "break-word",
                      }}
                      onClick={() =>
                        setRespuestaUnica(
                          activeStep,
                          preguntas[activeStep].opcion1.texto
                        )
                      }
                      display="flex"
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      {preguntas[activeStep].opcion1.texto}
                      {respuestas[activeStep] != null &&
                        respuestas[activeStep] ===
                        preguntas[activeStep].opcion1.texto && (
                          <Box
                            sx={{
                              border: "5px solid orange",
                              color: "orange",
                              background: "white",
                              borderRadius: "50%",
                              p: 0,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <DoneRounded
                              sx={{ color: "orange", padding: 0, margin: 0 }}
                            />
                          </Box>
                        )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        ...classes.btnQuiz,
                        ...(respuestas[activeStep] != null &&
                          respuestas[activeStep] ===
                          preguntas[activeStep].opcion2.texto &&
                          classes.btnQuizSelected),
                        width: "100%",
                        wordBreak: "break-word",
                      }}
                      onClick={() =>
                        setRespuestaUnica(
                          activeStep,
                          preguntas[activeStep].opcion2.texto
                        )
                      }
                      display="flex"
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      {preguntas[activeStep].opcion2.texto}
                      {respuestas[activeStep] != null &&
                        respuestas[activeStep] ===
                        preguntas[activeStep].opcion2.texto && (
                          <Box
                            sx={{
                              border: "5px solid orange",
                              color: "orange",
                              background: "white",
                              borderRadius: "50%",
                              p: 0,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <CloseRounded
                              sx={{ color: "orange", padding: 0, margin: 0 }}
                            />
                          </Box>
                        )}
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
            {preguntas[activeStep].tipo === TipoPregunta.SELECCION_MULTIPLE && (
              <>
                <Grid container spacing={2}>
                  {preguntas[activeStep].opciones.map(
                    (opcion: any, idxOpcion: number) => (
                      <Grid item xs={12} md={6} key={idxOpcion}>
                        <Box
                          sx={{
                            ...classes.btnQuiz,
                            ...(respuestas[activeStep] != null &&
                              respuestas[activeStep].includes(opcion.texto) &&
                              classes.btnQuizSelected),
                            width: "100%",
                            wordBreak: "break-word",
                          }}
                          onClick={() =>
                            setRespuestaMultiple(activeStep, opcion.texto)
                          }
                        >
                          {opcion.texto}
                        </Box>
                      </Grid>
                    )
                  )}
                </Grid>
              </>
            )}
            {preguntas[activeStep].tipo === TipoPregunta.PREGUNTA_ABIERTA && (
              <>
                <Typography sx={{ fontWeight: 700, mb: 1 }}>
                  RESPUESTA
                </Typography>
                <CInputForm
                  placeholder="Escribe tu respuesta"
                  value={respuestas[activeStep]}
                  autoComplete="off"
                  onChange={handleInputChange(activeStep)}
                />
              </>
            )}
            {(preguntas[activeStep].tipo === TipoPregunta.CARGA_ARCHIVO ||
              preguntas[activeStep].tipo === TipoPregunta.CARGA_VIDEO) && (
              <>
                {preguntas[activeStep].accionPermitida === "ARCHIVOS" && (
                  <>
                    <Typography
                      sx={{
                        color: theme.palette.neutral.main,
                        fontWeight: 700,
                      }}
                    >
                      RESPUESTA:
                    </Typography>
                    <CFilePicker
                      placeholder="Presione aquí para subir su archivo"
                      multiple={false}
                      handleChange={handleFileChange(activeStep)}
                    />
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      gap={2}
                      sx={{ whiteSpace: "nowrap", overflow: "hidden" }}
                    >
                      {respuestas[activeStep] &&
                        respuestas[activeStep].length > 0 &&
                        (respuestas[activeStep] as AdjuntoMetadata[]).map((adj, idxAdj) => (
                            <Box
                              key={idxAdj}
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              gap={1}
                              sx={{
                                border: `1px solid ${theme.palette.neutral.main}`,
                                borderRadius: "8px",
                                p: 1,
                              }}
                            >
                              <img
                                src={iconFileUploaded}
                                height={20}
                                alt="icon-uploaded"
                              />
                              {/* <IconButton
                                size="small"
                                sx={{ stroke: 'red' }}
                                onClick={() => handleDeleteFile(activeStep, idxAdj, adj)}
                              >
                                <CloseRounded width="20" height="20" />
                              </IconButton> */}

                              <Typography
                                sx={{
                                  flex: 2,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: 150,
                                }}
                              >
                                {adj.nombreArchivo}
                              </Typography>
                              <IconButton
                                size="small"
                                sx={{
                                  background:
                                    "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                                }}
                                onClick={() =>
                                  handleDeleteFile(activeStep, idxAdj, adj)
                                }
                              >
                                <CloseRounded
                                  sx={{
                                    color: "white",
                                    width: "15px",
                                    height: "15px",
                                  }}
                                />
                              </IconButton>
                            </Box>
                          )
                        )}
                    </Box>
                  </>
                )}
                {preguntas[activeStep].accionPermitida === "ENLACES" && (
                  <>
                    <Typography
                      sx={{
                        color: theme.palette.neutral.main,
                        fontWeight: 700,
                      }}
                    >
                      RESPUESTA:
                    </Typography>
                    {respuestas[activeStep] &&
                      (respuestas[activeStep] as string[]).map((enlace, idxEnlace) => (
                          <Box key={idxEnlace} mt={1}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent={"space-between"}
                              gap={1}
                            >
                              {/* <Typography component="span" sx={{ pr: 1.5 }}>
                                {idxEnlace + 1}
                              </Typography> */}
                              <CInputForm
                                id={`${idxEnlace}`}
                                sx={{ flex: 1 }}
                                placeholder="Ingresa el link de tu tarea"
                                value={enlace}
                                autoComplete="off"
                                onChange={handleInputEnlaceChange(
                                  activeStep,
                                  idxEnlace
                                )}
                              />
                              {respuestas[activeStep].length > 1 && (
                                <IconButton
                                  size="small"
                                  sx={{ background: theme.palette.grey[700] }}
                                  onClick={() =>
                                    eliminarEnlaceRespuesta(
                                      activeStep,
                                      idxEnlace
                                    )
                                  }
                                >
                                  <CloseRounded
                                    sx={{
                                      color: "white",
                                      width: "15px",
                                      height: "15px",
                                    }}
                                  />
                                </IconButton>
                              )}
                            </Box>
                            {idxEnlace ===
                              respuestas[activeStep].length - 1 && (
                                <Box
                                  component={Button}
                                  variant="text"
                                  display={"flex"}
                                  alignItems={"center"}
                                  gap={0.5}
                                  sx={{ mt: 1, color: theme.palette.info.main }}
                                  onClick={() =>
                                    añadirEnlaceRespuesta(activeStep)
                                  }
                                >
                                  <IconButton
                                    size="small"
                                    sx={{
                                      background:
                                        "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                                      stroke: "white",
                                    }}
                                  >
                                    <AddRounded
                                      sx={{
                                        color: "white",
                                        width: "16px",
                                        height: "16px",
                                      }}
                                    />
                                  </IconButton>
                                  Añadir opción
                                </Box>
                              )}
                          </Box>
                        )
                      )}
                  </>
                )}
                {/* {JSON.stringify(respuestas[activeStep])} */}
              </>
            )}
            <Box my={2}></Box>
          </Box>
        )}

        {/* <Box sx={{ alignSelf: 'center' }}>
        {JSON.stringify(respuestas)}
      </Box> */}
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position={actionsPosition}
          activeStep={activeStep}
          backButton={
            <Box
              component={Button}
              size={matchMobile ? "small" : "medium"}
              disabled={activeStep === 0}
              sx={{
                background:
                  "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                color: "white",
                py: 2,
                pl: 1,
                pr: 0,
                borderRadius: "8px",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                "&:disabled": {
                  background: "transparent",
                  border: `1px solid ${theme.palette.neutral.main}`,
                },
                [theme.breakpoints.down("md")]: {
                  py: 1.5,
                  pl: 0.5,
                  pr: 0,
                },
              }}
              onClick={handleAnteriorPregunta}
            >
              <ArrowBackRounded />
            </Box>
          }
          nextButton={
            <Box
              component={Button}
              size={matchMobile ? "small" : "medium"}
              sx={{
                background:
                  "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                color: "white",
                py: 2,
                pl: 1,
                pr: 0,
                borderRadius: "8px",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                "&:disabled": {
                  background: "transparent",
                  border: `1px solid ${theme.palette.neutral.main}`,
                },
                [theme.breakpoints.down("md")]: {
                  py: 1.5,
                  pl: 0.5,
                  pr: 0,
                },
              }}
              onClick={handleSiguientePregunta}
            >
              {/* {activeStep === maxSteps - 1 ? "Finalizar" : "Siguiente"} */}
              <ArrowForwardRounded />
            </Box>
          }
          sx={{
            textAlign: "center",
            fontWeight: 700,
            px: 0,
            color: theme.palette.info.hover,
          }}
        ></MobileStepper>
      </Box>
    </>
  );
};
