import {Box, styled, Typography} from "@mui/material";
import theme from "../../../../themes";
import bgPurple from "../../../../assets/GoodMunicipalPractices/bg_mid.jpg";
import listenIcon from "../../../../assets/GoodMunicipalPractices/icons/ico1_nomal.png";
import videoIcon from "../../../../assets/GoodMunicipalPractices/icons/ico4_nomal.png";
import photoIcon from "../../../../assets/GoodMunicipalPractices/icons/ico5_nomal.png";
import fileIcon from "../../../../assets/GoodMunicipalPractices/icons/ico3_nomal.png";

import {useGetFeaturedGoodPractice} from "../../../../context/app";
import {useEffect, useState} from "react";
import moment from "moment";
import FeaturedModal from "./FeaturedModal";

const StyledBox = styled(Box)(() => ({
  margin: "3em 0 0 0",
  padding: 0,
  width: "100%",
  borderRadius: "8px",
}));

const PurpleBox = styled(Box)(() => ({
  margin: "0em 0 0 0",
  padding: 0,
  width: "100%",
  height: 500,
  backgroundImage: `url(${bgPurple})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));

const StyledIcon = styled("img")(() => ({
  width: "24",
  height: "24",
  [theme.breakpoints.down("md")]: {
    width: "20",
    height: "20",
  },
}));

const FeaturedMunicipality = () => {

  const [featuredPractice, setFeaturedPractice] = useState<any>(null);
  // Resource modal
  const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
  const [modalResourceType, setModalResourceType] = useState('');
  const [modalUrl, setModalUrl] = useState<string>('');

  const getFeaturedPractice =  useGetFeaturedGoodPractice();

  const fetchFeaturedPractice = async () => {
    try {
      const featuredPractice = await getFeaturedPractice();
      setFeaturedPractice(featuredPractice);
    } catch (error) {
      console.log("Ocurrió un error al obtener featured practice :: ", error);
      setFeaturedPractice(null);
    }
  }

  useEffect(() => {
    fetchFeaturedPractice();
  }, []);
  console.log('featuredPractice')
  console.log(featuredPractice)

  const renderIcon = (resourceType:string) => {
    
    if (resourceType === "YOUTUBE")
      return <StyledIcon src={videoIcon} alt="video ico" />
    else if (resourceType === "SOUNDCLOUD")
      return <StyledIcon src={listenIcon} alt="audio ico" />
    else if(resourceType === "LINK_FOTOS")
      return <StyledIcon src={photoIcon} alt="link ico" />
    else if(resourceType === "LINK_ARCHIVO")
      return <StyledIcon src={fileIcon} alt="link ico" />
    else
      return null;

  }

  const renderLabel = (resourceType:string) => {
    
    if (resourceType === "YOUTUBE")
      return <Typography sx={{ fontFamily: 'Barlow', color: theme.palette.neutral.active, textTransform: 'uppercase' }} onClick={setModalValues}>
        Mira el video
      </Typography>
    else if (resourceType === "SOUNDCLOUD")
      return <Typography sx={{ fontFamily: 'Barlow', color: theme.palette.neutral.active, textTransform: 'uppercase' }} onClick={setModalValues}>
        Escucha el audio
      </Typography>
    else if(resourceType === "LINK_FOTOS")
      return <Typography sx={{ fontFamily: 'Barlow', color: theme.palette.neutral.active, textTransform: 'uppercase' }} onClick={openLink}>
        Mira las fotos
      </Typography>
    else if(resourceType === "LINK_ARCHIVO")
      return <Typography sx={{ fontFamily: 'Barlow', color: theme.palette.neutral.active, textTransform: 'uppercase' }} onClick={openLink}>
        Revisa el enlace
      </Typography>
  }

  const openLink = () => {
    if(featuredPractice.buena_recurso)
      window.open(featuredPractice.buena_recurso, '_blank')
  }

  const handleCloseModal = () => {
    setIsResourceModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsResourceModalOpen(true);
  };

  const handleModalResource = (resourceType:string, modalUrl:string) =>{
    setModalResourceType(resourceType);
    setModalUrl(modalUrl);
  }

  const setModalValues = () => {
    if(handleOpenModal)
      handleOpenModal()
    if(handleModalResource)
      handleModalResource(featuredPractice.tipo_recurso, featuredPractice.buena_recurso);
  }

  return (
    <StyledBox display="flex" justifyContent="center" id="municipioDestacado">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <PurpleBox display="flex">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{
              width: { xs: "100%", md: "50%" },
              height: "100%",
              margin: 0,
              padding: "0 1.5em 0 1.5em",
            }}
          >
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                fontFamily: "Barlow",
                fontSize: 22,
                color: "#e6ac0c",
              }}
            >
              Municipio destacado del mes
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform:'uppercase',
                fontFamily: "Barlow",
                fontSize: 42,
                color: "#FFFFFF",
                lineHeight: "1",
              }}
              my="0.5em"
            >
              {featuredPractice ? featuredPractice.municipio_desc : ''}
            </Typography>
            <Typography
              variant="h1"
              style={{
                textTransform: "uppercase",
                fontFamily: "Barlow",
                fontSize: 22,
                color: "#e6ac0c",
              }}
            >
              {`${moment(featuredPractice ? featuredPractice.fecha_publicacion : moment()).format("DD/MM/YYYY")} / ${featuredPractice ? featuredPractice.departamento_desc : ''}`}
            </Typography>
            <Typography
              style={{ fontFamily: "Ubuntu", fontSize: 16, color: "#FFFFFF" }}
            >
              {featuredPractice ? featuredPractice.buena_descripcion : ''}
            </Typography>
            {/*{ featuredPractice && featuredPractice.tipo_recurso ?*/}
            {/*  <Box*/}
            {/*    display="flex"*/}
            {/*    alignItems="center"*/}
            {/*    sx={{*/}
            {/*      width: "fit-content ",*/}
            {/*      backgroundColor: "#dedede",*/}
            {/*      borderRadius: "8px",*/}
            {/*      mt: "1.5em",*/}
            {/*      cursor: 'pointer'*/}
            {/*    }}*/}
            {/*    px={"12px"}*/}
            {/*    onClick={featuredPractice.tipo_recurso === "YOUTUBE" || featuredPractice.tipo_recurso === "SOUNDCLOUD" ? setModalValues : openLink}*/}
            {/*    // onClick={resourceType === "V" || resourceType === "U" ? setModalValues : openLink}*/}
            {/*  >*/}
            {/*    {renderIcon(featuredPractice.tipo_recurso)}*/}
            {/*    {renderLabel(featuredPractice.tipo_recurso)}*/}
            {/*  </Box> : null*/}
            {/*}*/}
          </Box>
        </PurpleBox>
      </Box>
      {isResourceModalOpen && (
        <FeaturedModal
          open={isResourceModalOpen}
          onClose={handleCloseModal}
          modalResourceType={modalResourceType}
          modalUrl={modalUrl}
        />
      )}
    </StyledBox>
  );
};

export default FeaturedMunicipality;
