import { Box } from "@mui/material";
import ReactPlayer from 'react-player';
import { CourseContentDialog } from "../../../GoodMunicipalPractices/components/shared/Elements";
import { YoutubePlayer } from "../../../../shared/components/curso/YoutubePlayer";

type AuthDialogProps = {
  open: boolean;
  onClose: () => void;
  modalResourceType: string;
  modalUrl:string;
}

const ResourceModal = ({ open, onClose, modalResourceType, modalUrl }: AuthDialogProps) => {
  const renderPlayer = () => {
    if(modalResourceType === 'YOUTUBE') {
      return(
        <Box width={'100%'}>
          <YoutubePlayer
            
            urlVideo={modalUrl}
          />
        </Box>
      );
    }
    else {
      return(
        <Box display="flex" justifyContent={'center'} width="100%">
          <ReactPlayer url={modalUrl} />
        </Box>
      );
    }
  }

  return (
    <CourseContentDialog
      open={open}
      onClose={onClose}
      withTitle={false}
      withActions={false}
    >
      <Box display="flex">
        {renderPlayer()}
      </Box>
    </CourseContentDialog>
  )
}

export default ResourceModal