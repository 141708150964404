// import useHttp from "../../common/useHttp";
// import { serialize } from "../../common/utils";
import useHttp from "../../shared/hooks/useHttp";
import {printLog, serialize} from "../../shared/utils";
import {
  GetCoursesResponse,
  ReporteCursosResponse,
  ReporteInscritosQueryParams,
  ReporteInscritosResponse,
  ReporteMachometroQueryParams,
  ReporteMachometroResponse,
} from "./ReporteApiModel";
import {useQuery} from "@tanstack/react-query";
import {GetMunicipiosResponse} from "../AdminDirectorio/DirectorioClient";

export function useGetReporteHome(): () => Promise<any> {
  const http = useHttp();
  return async () => {
    return await http.get("/reporte", {});
  };
}

export function useDepartamentos() {
  const http = useHttp();
  return useQuery({
    queryKey: ["municipio/porDepartamento"],
    queryFn: async () => (await http.get("/municipio/porDepartamento", {}) as GetMunicipiosResponse)
  })
}

export function useCourses() {
  const http = useHttp();
  return useQuery({
    queryKey: ["/plantilla_curso"],
    queryFn: async () => await http.get("/plantilla_curso", {}) as GetCoursesResponse
  })
}

export function useReporteInscritos(params: ReporteInscritosQueryParams) {
  const http = useHttp();
  return useQuery({
    queryKey: ["getReporteInscritos", params],
    queryFn: async () => {
      printLog("params :: ", params);
      let query = params ? "?" + serialize(params) : "?";
      printLog("Query generado :: ", query);
      return await http.get("/v2/admin/report/enrolment" + query, {}) as ReporteInscritosResponse;
    }
  })
}

export function useGetReporteInscritosExcel(): (
  params: ReporteInscritosQueryParams
) => Promise<any> {
  const http = useHttp();
  return async (params) => {
    printLog("params :: ", params);
    // /api/usuario?paginaActual=1&elementosPorPagina=10
    let query = params ? "?" + serialize(params) : "?";
    printLog("[excel] Query generado :: ", query);
    await http.file("/reporte/inscritos/excel" + query, "Reporte Inscritos");
  };
}

export function useCourseReport(params: ReporteInscritosQueryParams) {
  const http = useHttp();
  return useQuery({
    queryKey: ["getReporteCursos", params],
    queryFn: async () => {
      let query = params ? "?" + serialize(params) : "?";
      console.log("getReporteCursos - params: ", query)
      return await http.get("/v2/admin/report/course" + query, {}) as ReporteCursosResponse;
    }
  })
}

export function useMachometroReport(params: ReporteMachometroQueryParams) {
  const http = useHttp();
  return useQuery({
    queryKey: ["getReporteMachometro", params],
    queryFn: async () => {
      let query = params ? "?" + serialize(params) : "?";
      console.log("getReporteMachometro - params: ", query)
      return await http.get("/v2/admin/report/machometro" + query, {}) as ReporteMachometroResponse;
    }
  })
}