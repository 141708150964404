import { Box, Grid, SelectChangeEvent, Typography } from "@mui/material";
import produce from "immer";
import { PreguntaEntregableModel, PreguntaVacia } from "../../../types";
import CSelectForm from "../../../../../shared/components/form/CSelectForm";

type PreguntaEntregable = Exclude<PreguntaEntregableModel, PreguntaVacia>;
type DeliverableQuestionEditorProps = {
  pregunta: PreguntaEntregable;
  onPreguntaChange: (pregunta: PreguntaEntregable) => void;
};

export function DeliverableQuestionEditor({
  pregunta,
  onPreguntaChange,
}: DeliverableQuestionEditorProps) {
  const handleSelectChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (!(value === "ARCHIVOS" || value === "ENLACES")) {
      throw new Error("Opcion invalida");
    }

    onPreguntaChange(
      produce(pregunta, (draft) => {
        draft.accionPermitida = value;
      })
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Typography component="p" sx={{ mr: 2 }}>
          El participante podrá añadir:
        </Typography>
      </Grid>
      <Grid item xs={12} md={11}>
        <Box display="flex" flexDirection="column">
          <Box display="inline-flex">
            <CSelectForm
              placeholder="Selecciona una opción"
              items={[
                {
                  value: "ARCHIVOS",
                  label: "Archivos",
                },
                {
                  value: "ENLACES",
                  label: "Enlaces",
                },
              ]}
              value={pregunta.accionPermitida ? pregunta.accionPermitida : ""}
              onChange={handleSelectChange}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
