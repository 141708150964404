import React from 'react';
import { Box, Typography, Button, styled, Grid, Container } from "@mui/material";
import { printLog } from "../../../shared/utils";

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  backgroundColor: theme.palette.primary.main,
  borderRadius: "8px",
  color: theme.palette.secondary.active,
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: theme.palette.primary.hover,
    color: theme.palette.common.white,
  },
  opacity: 1,
}));

const StyledBox = styled(Box)(() => ({
  backgroundColor: "#E0E0E0",
  borderRadius: "12px",
  overflow: "hidden",
  padding: "20px",
  margin: "20px 0",
}));

const StyledImg = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  height: "auto",
  objectFit: "cover",
  transition: "transform 0.3s ease-in-out",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const handleClick = () => {
  printLog("Descargar guía");
  window.open("Guía EduActivistas 2024.pdf", "_blank");
};

export const FeaturedBanner = () => {
  return (
    <Container maxWidth="lg" sx={{ my: 4 }}>
      <StyledBox id="featuredBanner">
        <Grid container alignItems="center">
          <Grid item xs={12} md={8} sx={{ padding: "2em" }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={{ xs: "center", md: "start" }}
            >
              <Typography
                variant="h4"
                fontWeight="bold"
                color="rgb(74,12,85,0.8)"
                textAlign={{ xs: "center", md: "left" }}
                gutterBottom
              >
                Descarga nuestra última guía de trabajo: EDUACTIVISTAS
              </Typography>
              <StyledButton
                variant="contained"
                onClick={handleClick}
              >
                VER MÁS
              </StyledButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                overflow: "hidden",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              }}
            >
              <StyledImg
                src={require("./../../../assets/Communication/featured/featured-guia.png")}
                alt="Portada de la guía EDUACTIVISTAS"
                onClick={handleClick}
              />
            </Box>
          </Grid>
        </Grid>
      </StyledBox>
    </Container>
  );
};