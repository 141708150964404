import {Grid} from "@mui/material";
// import { useAuthContext } from "../../../common/auth/AuthContext";
// import useHttp from "../../../common/useHttp";
import {EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useHttp from "../../../../shared/hooks/useHttp";


type TextContentEditorProps = {
  // editorState?: string;
  // onEditorStateChange: (editorHtml: string) => void;
  editorState?: EditorState;
  onEditorStateChange: (editorState: EditorState) => void;
};
export function TextContentEditor(props: TextContentEditorProps) {
  const http = useHttp();
  // const authContext = useAuthContext();
  // const handleContenido = (contenido: string) => {
  //   printLog('value of content >>> ', contenido);
  //   props.onEditorStateChange(contenido)
  // }
  const editorState = props.editorState ?? EditorState.createEmpty();

  return (
    <Grid container>
      <Grid item>
        <Editor
          placeholder="Comienza a escribir el contenido..."
          editorState={editorState}
          onEditorStateChange={props.onEditorStateChange}
          wrapperClassName="wrapperClass"
          editorClassName="editorClass"
          toolbarClassName="toolbarClass"
          uploadCallback={(file: any) => {
            const formData = new FormData();
            formData.append("file", file as File);
            const result = http
              .post("/file", {
                payload: formData,
                // auth: authContext?.user?.token ?? undefined,
                withFiles: true,
              })
              .then((value: unknown) => ({
                data: {
                  link: encodeURI((value as { publicUrl: string }).publicUrl),
                },
              }));
            return result;
          }}
          localization={{
            locale: "es",
          }}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "list",
              "textAlign",
              "colorPicker",
              "link",
              "emoji",
              "image",
              "history",
            ],
            inline: {
              inDropdown: false,
              options: ["bold", "italic", "underline", "strikethrough"],
            },
            list: {
              inDropdown: false,
              options: ["unordered", "ordered"],
            },
            image: {
              uploadEnabled: true,
              previewImage: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
