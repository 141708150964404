import {
  Theme,
  Badge,
  Box,
  Container,
  CssBaseline,
  IconButton,
  List,
  ListItemIcon,
  Menu,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
  Avatar,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
// import { makeStyles } from "@mui/styles";
import ListItemButton from "@mui/material/ListItemButton";
import { colorsBase, DesignSystem } from "../../themes/theme";

// Hooks
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
// import { useLocation, useNavigate } from 'react-router-dom';

// Assets
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import AccountCircle from '@mui/icons-material/AccountCircle';
import { ReactComponent as IconoUsuarios } from "../../assets/icon/iconDrawerUsuarios.svg";
import { ReactComponent as IconoCursos } from "../../assets/icon/iconDrawerCursos.svg";
import { ReactComponent as IconoRegionales } from "../../assets/icon/iconDrawerRegionales.svg";
import { ReactComponent as IconoNoticias } from "../../assets/icon/iconDrawerNoticias.svg";
import { ReactComponent as IconoReportes } from "../../assets/icon/iconDrawerReportes.svg";
import { ReactComponent as IconoMenu } from "../../assets/icons/menu.svg";
import vdsvLogo from "../../assets/Color.jpg";
import { ReactComponent as CloseappIcon } from "../../assets/icons/closeApp.svg";

import { ReactComponent as MobileHomeIcon } from "../../assets/icons/mobileHome.svg";
import { ReactComponent as MobileNotificationsIcon } from "../../assets/icons/mobileNotifications.svg";
import { ReactComponent as MobileHelpIcon } from "../../assets/icons/mobileHelp.svg";
import { ReactComponent as MobileSettingsIcon } from "../../assets/icons/mobileSettings.svg";

import { ArrowForwardIos } from "@mui/icons-material";
import iconCommunicationColor from "./../../assets/Communication/admin/promotion-color.png";
import iconCommunication from "./../../assets/Communication/admin/promotion.png";

import iconNumbersBlanco from "./../../assets/admin/cifras-blanco.svg";
import iconNumbersAzul from "./../../assets/admin/cifras-azul.svg";
import iconDirectoryBlanco from "./../../assets/admin/directorio-blanco.svg";
import iconDirectoryAzul from "./../../assets/admin/directorio-azul.svg";
// import { useUnregisterDeviceToken } from "../../login/LoginClient";
// import { onMessageListener } from "../../common/auth/AuthFirebase";
import { SystemRoles } from "../../shared/common/constants";
import { useAuthContext } from "../../shared/auth/AuthContext";
import { printLog } from "../../shared/utils";

const drawerWidth: number = 250;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "white",
  elevation: "0",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    backgroundColor: "white",
    elevation: "0",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    // zIndex: '9999',
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    fill: colorsBase.Azul1,
    stroke: colorsBase.Azul1,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    // height: '100vh',
    backgroundColor: "#FFFFFF",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const LoggedInLayout = ({ children }: any) => {
  const [open, setOpen] = useState(true);
  const [notificationCounter, setNotificationCounter] = useState(0);
  const [show, setShow] = useState<boolean>(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const { pathname } = useLocation();

  // const navigate = useNavigate();
  const navigate = useHistory();

  const goToPage = (event: any, ruta: string) => {
    // navigate(ruta, {
    navigate.push(`/admin/${ruta}`, {
      replace: true,
    });
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const authContext = useAuthContext();
  // const unregisterDeviceToken = useUnregisterDeviceToken()

  useEffect(() => {
    printLog("LoggedInLayout::useEffect::authContext", authContext);
  }, [authContext]); // render de menu cuando se cambia el autContext

  const handleCloseSession = async () => {
    // await unregisterDeviceToken();
    authContext.logout({
      onSuccess: async () => {
        localStorage.removeItem("user");
        navigate.push("/login", { replace: true });
        // navigate('/login', { replace: true })
      },
      onFailure: () => {},
    });
  };

  const handleCambiarContrasena = () => {
    handleClose();
    // navigate('/changePassword')
    navigate.push("/changePassword");
  };

  // const matches = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down("sm"));

  if (authContext.user) {
    // onMessageListener().then((payload: any) => {
    //   printLog('push notification received ::: ', payload);
    //   setShow(true);
    //   setNotification({title: payload.notification.title, body: payload.notification.body})
    //   setNotificationCounter(notificationCounter + 1);
    // }).catch(err => printLog('messaging failed: ', err));
  }

  const handleCloseNotification = () => {
    setShow(false);
  };

  const verNotificaciones = (ev: any, ruta: string) => {
    setNotificationCounter(0);
    goToPage(ev, ruta);
  };

  const verReportes = () => {
    // navigate('/reporte', { state: { userRol: authContext.user?.rol } });
    navigate.push("/reporte", { state: { userRol: authContext.user?.rol } });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CssBaseline />
        {!matchesPhone && (
          <AppBar position="absolute" elevation={0} open={open}>
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
                borderBottom: "1px solid gray",
                borderBottomColor: "#cacaca",
              }}
            >
              <IconButton
                edge="start"
                color="primary"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                  // color: colorsBase.Azul1,
                  // stroke: colorsBase.Azul1
                }}
              >
                <IconoMenu />
              </IconButton>
              {/* <div></div> */}
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                {/* Dashboard */}
              </Typography>
              {/* <IconButton color="primary" onClick={(ev) => verNotificaciones(ev, 'notificaciones')}> */}
              {/* TODO: Obtener cantidad de notificaciones */}
              {/* <Badge badgeContent={notificationCounter} color="warning" invisible={notificationCounter === 0}> */}
              {/* <NotificationsIcon sx={{ color: colorsBase.Azul1 }} /> */}
              {/* <NotificationsIcon sx={{  }} /> */}
              {/* </Badge> */}
              {/* </IconButton> */}
              <Avatar
                sx={{
                  // border: `2px solid ${colorsBase.Naranja2}`,
                  border: `2px solid ${""}`,
                  ml: 2,
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                }}
                onClick={handleMenu}
              >
                <Typography sx={{ ...DesignSystem?.cuerpo1 }}>
                  {authContext.user?.displayName?.substring(0, 1)}
                </Typography>
              </Avatar>
              <div>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{
                    "& .MuiPaper-root": {
                      borderRadius: "20px",
                    },
                  }}
                >
                  <Box>
                    <Box sx={{ my: 2, display: "flex", px: 3, py: 1 }}>
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            ...DesignSystem?.titulo2,
                            color: colorsBase.Texto2,
                          }}
                        >
                          {authContext.user?.displayName ?? ""}
                        </Typography>
                        <Box mt={2}>
                          <Typography
                            sx={{
                              ...DesignSystem?.titulo4,
                              color: colorsBase.Naranja2,
                            }}
                          >
                            Rol
                          </Typography>
                          <Typography sx={{ ...DesignSystem?.cuerpo2 }}>
                            {authContext.user?.rol ?? ""}
                          </Typography>
                        </Box>
                        <Box mb={1}>
                          <Typography
                            sx={{
                              ...DesignSystem?.titulo4,
                              color: colorsBase.Naranja2,
                            }}
                          >
                            Correo electrónico
                          </Typography>
                          <Typography sx={{ ...DesignSystem?.cuerpo2 }}>
                            {authContext.user?.email ?? ""}
                          </Typography>
                        </Box>
                      </Box>
                      <Avatar
                        sx={{
                          border: `4px solid ${colorsBase.Naranja2}`,
                          width: "126px",
                          height: "auto",
                          [theme.breakpoints.down("md")]: {
                            width: "126px",
                            height: "auto",
                          },
                        }}
                      >
                        <Typography sx={{ ...DesignSystem?.cuerpo }}>
                          {authContext.user?.displayName?.substring(0, 1)}
                        </Typography>
                      </Avatar>
                      {/* <CardContent sx={{ display: 'flex' }}>
                      </CardContent> */}
                    </Box>
                    <Divider></Divider>
                    {/* <Box sx={{ borderRadius: '20px', display: 'flex', alignItems: 'center', py: .5, px: 3 }}>
                      <NotificacionesIcon />
                      <Typography sx={{ ...DesignSystem?.cuerpo3, flex: 1, ml: 2 }}>
                        Notificaciones push
                      </Typography>
                      <IconButton size="small" onClick={handleClose}>
                        <ArrowForwardIos />
                      </IconButton>
                    </Box>
                    <Divider></Divider> */}
                    {/* <Box sx={{ borderRadius: '20px', display: 'flex', alignItems: 'center', py: .5, px: 3 }}>
                      <LlaveIcon />
                      <Typography sx={{ ...DesignSystem?.cuerpo3, flex: 1, ml: 2 }}>
                        Cambiar contraseña
                      </Typography>
                      <IconButton size="small" onClick={handleCambiarContrasena}>
                        <ArrowForwardIos />
                      </IconButton>
                    </Box>
                    <Divider></Divider> */}
                    {/* <Box sx={{ borderRadius: '20px', display: 'flex', alignItems: 'center', py: .5, px: 3 }}>
                      <MobileHelpIcon />
                      <Typography sx={{ ...DesignSystem?.cuerpo3, flex: 1, ml: 2 }}>
                        Ayuda
                      </Typography>
                      <IconButton size="small" onClick={(ev) => goToPage(ev, "ayuda")}>
                        <ArrowForwardIos />
                      </IconButton>
                    </Box>
                    <Divider></Divider> */}
                    <Box
                      sx={{
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        py: 0.5,
                        px: 3,
                      }}
                    >
                      <CloseappIcon />
                      <Typography
                        sx={{ ...DesignSystem?.cuerpo3, flex: 1, ml: 2 }}
                      >
                        Cerrar sesión
                      </Typography>
                      <IconButton size="small" onClick={handleCloseSession}>
                        <ArrowForwardIos />
                      </IconButton>
                    </Box>
                  </Box>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
        )}
        {!matchesPhone && (
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <IconoMenu />
              </IconButton>
              <Box
                sx={{ textAlign: "center", width: "100%", cursor: "pointer" }}
                onClick={(event) => goToPage(event, "/")}
              >
                <img
                  src={vdsvLogo}
                  width={170}
                  style={{ transform: "translateY(8px)" }}
                  alt="logo-anfitriones"
                />
              </Box>
            </Toolbar>
            <List>
              <div>
                {(authContext.user?.rol === SystemRoles.MONITOR ||
                  authContext.user?.rol == null) && (
                  <ListItemUsuarioButton
                    onClick={(event) => goToPage(event, "usuario")}
                    // className={classes.itemUsuario}
                    selected={pathname === "/admin/usuario"}
                  >
                    <ListItemIcon>
                      <IconoUsuarios fill="current" />
                    </ListItemIcon>
                    <span>Usuarios</span>
                  </ListItemUsuarioButton>
                )}

                <ListItemCursosButton
                  onClick={(event) => goToPage(event, "cursos")}
                  // className={classes.itemCursos}
                  selected={pathname === "/admin/cursos"}
                >
                  <ListItemIcon>
                    <IconoCursos fill="current" />
                  </ListItemIcon>
                  <span>Cursos</span>
                </ListItemCursosButton>

                {authContext.user?.rol === SystemRoles.MONITOR && (
                  <ListItemRegionalesButton
                    onClick={(event) => {
                      event.preventDefault();
                      navigate.push("/admin/iniciativa", {
                        replace: true,
                        state: { isPractice: true },
                      });
                    }}
                    // className={classes.itemRegionales}
                    selected={pathname === "/admin/iniciativa"}
                  >
                    <ListItemIcon>
                      <IconoRegionales stroke="current" />
                    </ListItemIcon>
                    <span>Buenas prácticas</span>
                  </ListItemRegionalesButton>
                )}

                <ListItemNoticiasButton
                  onClick={(event) => goToPage(event, "noticia")}
                  // className={classes.itemNoticias}
                  selected={pathname === "/admin/noticia"}
                >
                  <ListItemIcon>
                    <IconoNoticias fill="current" />
                  </ListItemIcon>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>Noticias Y</span>
                    <span>Enlaces de interés</span>
                  </div>
                </ListItemNoticiasButton>

                {authContext.user?.rol !== SystemRoles.PARTICIPANTE && (
                  <ListItemReportesButton
                    // className={classes.itemReportes}
                    selected={pathname === "/admin/reporte"}
                    onClick={(event) => goToPage(event, "reporte")}
                  >
                    <ListItemIcon>
                      <IconoReportes fill="current" />
                    </ListItemIcon>
                    <span>Reportes</span>
                  </ListItemReportesButton>
                )}
                <ListItemDirectorioButton
                  onClick={(event) => goToPage(event, "directorio")}
                  // className={classes.itemNoticias}
                  selected={pathname === "/admin/directorio"}
                >
                  <ListItemIcon>
                    {/* <IconoReportes fill="current" width={30} height={30} /> */}
                    {pathname !== "/admin/directorio" ? (
                      <img
                        src={iconDirectoryAzul}
                        alt="icon-directory-azul"
                        width="30"
                        height="auto"
                      />
                    ) : (
                      <img
                        src={iconDirectoryBlanco}
                        alt="icon-directory-blanco"
                        width="30"
                        height="auto"
                      />
                    )}
                  </ListItemIcon>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>Directorio municipal</span>
                  </div>
                </ListItemDirectorioButton>
                <ListItemDirectorioButton
                  onClick={(event) => goToPage(event, "cifras")}
                  // className={classes.itemNoticias}
                  selected={pathname === "/admin/cifras"}
                >
                  <ListItemIcon>
                    {/* <BubbleChartOutlined fill="current" /> */}
                    {pathname !== "/admin/cifras" ? (
                      <img
                        src={iconNumbersAzul}
                        alt="icon-cifras-azul"
                        width="30"
                        height="auto"
                      />
                    ) : (
                      <img
                        src={iconNumbersBlanco}
                        alt="icon-cifras-blanco"
                        width="30"
                        height="auto"
                      />
                    )}
                  </ListItemIcon>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>Cifras de tu municipio</span>
                  </div>
                </ListItemDirectorioButton>
                <ListItemDirectorioButton
                  onClick={(event) => goToPage(event, "")}
                  // className={classes.itemNoticias}
                  selected={pathname === "/admin/comunicacion"}
                >
                  <ListItemIcon>
                    {/* <BubbleChartOutlined fill="current" /> */}
                    {pathname !== "/admin/comunicacion" ? (
                      <img
                        src={iconCommunicationColor}
                        alt="icon-communication-color"
                        width="30"
                        height="auto"
                      />
                    ) : (
                      <img
                        src={iconCommunication}
                        alt="icon-communication"
                        width="30"
                        height="auto"
                      />
                    )}
                  </ListItemIcon>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span>Comunicación</span>
                  </div>
                </ListItemDirectorioButton>

                {authContext.user?.rol === SystemRoles.MONITOR && (
                  <ListItemRegionalesButton2
                    onClick={(event) => {
                      event.preventDefault();
                      navigate.push("/admin/iniciativa", {
                        replace: true,
                        state: { isPractice: false },
                      });
                    }}
                    // className={classes.itemRegionales}
                    // selected={pathname === "/admin/iniciativa"}
                  >
                    <ListItemIcon>
                      <IconoRegionales stroke="current" />
                    </ListItemIcon>
                    <span>Experiencias innovadoras</span>
                  </ListItemRegionalesButton2>
                )}
              </div>
            </List>
            {/* <Box display="flex" flexDirection="column" alignItems="start" sx={{ position: 'fixed', bottom: 8, left: 8 }}>
              <Box sx={{ backgroundColor: colorsBase.Naranja2, borderRadius: '5px', textAlign: 'center' }} p={.5}>
                <Typography sx={{ ...DesignSystem.cuerpo4 }}>
                  <b>{process.env.NODE_ENV.substring(0, 3).toUpperCase()}</b>
                </Typography>
              </Box>
            </Box> */}
          </Drawer>
        )}
        <Box
          component="main"
          sx={{
            backgroundColor: "#ededed",
            // backgroundImage: `url(${bgTop})`,
            // background: `#f8f5f7af url(${bgImage})`,
            // backgroundSize: 'cover',
            // backgroundRepeat: 'no-repeat',
            // backgroundPosition: 'center',
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          {!matchesPhone && <Toolbar />}
          <Container
            maxWidth="xl"
            sx={{
              mt: 4,
              mb: 10,
            }}
          >
            {/* <Box sx={{ textAlign: 'center', ...DesignSystem?.cuerpo4 }}>
              { matchesPhone ? 'es telefono' : 'no es telefono' }
            </Box> */}
            {children}
          </Container>
        </Box>
        {matchesPhone && (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              right: 0,
              left: 0,
              margin: "10px",
              backgroundColor: "#FFFDFD",
              boxShadow: "5px 5px 8px rgba(0, 0, 0, 0.25)",
              borderRadius: "15px",
            }}
          >
            <Grid
              container
              spacing={2}
              alignContent="center"
              justifyContent="space-between"
              sx={{ px: 3, py: 1 }}
            >
              <Grid
                item
                container
                direction="column"
                xs={3}
                sx={{ p: 1 }}
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                onClick={(ev) => goToPage(ev, "/")}
              >
                <MobileHomeIcon />
                <Typography
                  component="span"
                  sx={{ ...DesignSystem?.cuerpo4, pt: 1 }}
                >
                  Inicio
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="column"
                xs={3}
                sx={{ p: 1 }}
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                onClick={(ev) => goToPage(ev, "notificaciones")}
              >
                <MobileNotificationsIcon />
                <Typography
                  component="span"
                  sx={{ ...DesignSystem?.cuerpo4, pt: 1 }}
                >
                  Notificaciones
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="column"
                xs={3}
                sx={{ p: 1 }}
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                onClick={(ev) => goToPage(ev, "ayuda")}
              >
                <MobileHelpIcon />
                <Typography
                  component="span"
                  sx={{ ...DesignSystem?.cuerpo4, pt: 1 }}
                >
                  Ayuda
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="column"
                xs={3}
                sx={{ p: 1 }}
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                onClick={(ev) => goToPage(ev, "ajustes")}
              >
                <MobileSettingsIcon />
                <Typography
                  component="span"
                  sx={{ ...DesignSystem?.cuerpo4, pt: 1 }}
                >
                  Ajustes
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={show}
        onClose={handleCloseNotification}
        key={"topright"}
      >
        <Alert
          onClose={handleCloseNotification}
          severity="success"
          color="info"
          sx={{ border: `1px solid ${colorsBase.Azul1}` }}
        >
          <Typography component={"p"} sx={{ ...DesignSystem?.titulo3 }}>
            {notification.title}
          </Typography>
          <Typography component={"p"} sx={{ ...DesignSystem?.cuerpo2 }}>
            {notification.body}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
};

const ListItemUsuarioButton = styled(ListItemButton)(({ theme }) => ({
  ...DesignSystem?.cuerpo2,
  marginTop: 10,
  marginBottom: 10,
  "&:hover": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    // backgroundColor: theme.palette.common.white,
    background: `linear-gradient(90deg, ${theme.palette.neutral.active} 0%, rgba(183, 56, 118, 0) 100%)`,
    fill: colorsBase.Blanco,
  },
  "&.Mui-selected": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    background: `linear-gradient(90deg, ${theme.palette.neutral.active} 0%, rgba(183, 56, 118, 0) 100%)`,
    fill: colorsBase.Blanco,
  },
}));

const ListItemCursosButton = styled(ListItemButton)(({ theme }) => ({
  ...DesignSystem?.cuerpo2,
  marginTop: 10,
  marginBottom: 10,
  "&:hover": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    // backgroundColor: theme.palette.common.white,
    background: `linear-gradient(90deg, ${theme.palette.info.main} 0%, rgba(183, 56, 118, 0) 100%)`,
    fill: colorsBase.Blanco,
  },
  "&.Mui-selected": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    background: `linear-gradient(90deg, ${theme.palette.info.main} 0%, rgba(183, 56, 118, 0) 100%)`,
    fill: colorsBase.Blanco,
  },
}));

const ListItemRegionalesButton = styled(ListItemButton)(({ theme }) => ({
  ...DesignSystem?.cuerpo2,
  marginTop: 10,
  marginBottom: 10,
  "&:hover": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    // backgroundColor: theme.palette.common.white,
    background: `linear-gradient(90deg, ${theme.palette.success.active} 0%, rgba(183, 56, 118, 0) 100%)`,
    stroke: colorsBase.Blanco,
  },
  "&.Mui-selected": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    background: `linear-gradient(90deg, ${theme.palette.success.active} 0%, rgba(183, 56, 118, 0) 100%)`,
    stroke: colorsBase.Blanco,
  },
}));

const ListItemRegionalesButton2 = styled(ListItemButton)(({ theme }) => ({
  ...DesignSystem?.cuerpo2,
  marginTop: 10,
  marginBottom: 10,
  "&:hover": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    // backgroundColor: theme.palette.common.white,
    background: `linear-gradient(90deg, ${theme.palette.success.active} 0%, rgba(183, 56, 118, 0) 100%)`,
    stroke: colorsBase.Blanco,
  },
  "&.Mui-selected": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    background: `linear-gradient(90deg, ${theme.palette.success.active} 0%, rgba(183, 56, 118, 0) 100%)`,
    stroke: colorsBase.Blanco,
  },
}));

const ListItemNoticiasButton = styled(ListItemButton)(({ theme }) => ({
  ...DesignSystem?.cuerpo2,
  marginTop: 10,
  marginBottom: 10,
  "&:hover": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    // backgroundColor: theme.palette.common.white,
    background: `linear-gradient(90deg, ${theme.palette.secondary.active} 0%, rgba(183, 56, 118, 0) 100%)`,
    fill: colorsBase.Blanco,
  },
  "&.Mui-selected": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    background: `linear-gradient(90deg, ${theme.palette.secondary.active} 0%, rgba(183, 56, 118, 0) 100%)`,
    fill: colorsBase.Blanco,
  },
}));

const ListItemDirectorioButton = styled(ListItemButton)(({ theme }) => ({
  ...DesignSystem?.cuerpo2,
  marginTop: 10,
  marginBottom: 10,
  "&:hover": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    // backgroundColor: theme.palette.common.white,
    background: `linear-gradient(90deg, ${theme.palette.secondary.active} 0%, rgba(183, 56, 118, 0) 100%)`,
    fill: colorsBase.Blanco,
  },
  "&.Mui-selected": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    background: `linear-gradient(90deg, ${theme.palette.secondary.active} 0%, rgba(183, 56, 118, 0) 100%)`,
    fill: colorsBase.Blanco,
  },
}));

const ListItemReportesButton = styled(ListItemButton)(({ theme }) => ({
  ...DesignSystem?.cuerpo2,
  marginTop: 10,
  marginBottom: 10,
  "&:hover": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    // backgroundColor: theme.palette.common.white,
    background: `linear-gradient(90deg, ${theme.palette.primary.active} 0%, rgba(183, 56, 118, 0) 100%)`,
    fill: colorsBase.Blanco,
  },
  "&.Mui-selected": {
    color: colorsBase.Blanco,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    background: `linear-gradient(90deg, ${theme.palette.primary.active} 0%, rgba(183, 56, 118, 0) 100%)`,
    fill: colorsBase.Blanco,
  },
}));

export default LoggedInLayout;
