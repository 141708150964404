export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isImage = (url: string) => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
};

export const isValidUrl = (_url: string) => {
  const urlRegex =
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z0-9\-]+)\.)+(?:com|org|net)\.?)(?::(\d{1,5}))?((\/+([a-zA-Z0-9\-~!@#$%&amp;*_+={}\(\):;'&lt;&gt;,.\/?]+))*)?$/;
  return urlRegex.test(_url);
};

export const getArrayKeyValues = (lista: string[]) => {
  return lista.map((item) => ({
    value: item,
    label: item,
  }));
};

export const getArrayKeyValuesFromObject = (
  lista: unknown[],
  key: string,
  value: string
) => {
  return lista.map((item: any) => ({
    value: item[key],
    label: item[value],
  }));
};

export const getArrayKeyValuesFromObjectToAutocomplete = (
  lista: unknown[],
  key: string,
  value: string
) => {
  return lista.map((item: any) => ({
    id: item[key],
    label: item[value],
  }));
};

export const getLabelFromValue = (
  lista: unknown[],
  key: string,
  value: string,
  valor: string
) => {
  const encontrado: any = lista.find((el: any) => el[key] === value);
  return encontrado ? encontrado[valor] : "";
};

export const serialize = (json: any) => {
  const string = [];
  for (const i in json) {
    if(json[i] !== undefined) {
      string.push(`${i}=${json[i]}`);
    }
  }
  return string.join("&");
};

export const titleCase = (s: string) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  );

const isHTML = RegExp.prototype.test.bind(/^(<([^>]+)>)$/i);

export const InterpreteMensajes = (mensaje: any): string => {
  try {
    const errorMessage = JSON.parse(JSON.stringify(mensaje));
    return (
      errorMessage.mensaje ??
      errorMessage.message ??
      errorMessage.error ??
      "Solicitud erronea 🚨"
    );
  } catch (e) {
    return isHTML(mensaje) ? "Solicitud erronea 🚨" : `${mensaje}`;
  }
};

const obtenerNombreFuncionAnterior = (d: number) => {
  try {
    const error = new Error();
    let r = "";
    if (error.stack != null) {
      const firefoxMatch = (error.stack.split("\n")[d].match(/^.*(?=@)/) ||
        [])[0];
      const chromeMatch = (
        (((error.stack.split("at ") || [])[1 + d] || "").match(
          /(^|\.| <| )(.*[^(<])( \()/
        ) || [])[2] || ""
      )
        .split(".")
        .pop();
      const safariMatch = error.stack.split("\n")[d];

      // firefoxMatch ? console.log('firefoxMatch', firefoxMatch) : void 0;
      // chromeMatch ? console.log('chromeMatch', chromeMatch) : void 0;
      // safariMatch ? console.log('safariMatch', safariMatch) : void 0;
      r = firefoxMatch || chromeMatch || safariMatch;
    }
    return r;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`🚨`, e);
    return "";
  }
};

export const printLog = (...mensaje: any[]) => {
  // mensaje = mensaje.filter((value) => value && Object.keys(value).length != 0)
  mensaje = mensaje.filter((value) => value !== undefined || value !== null);
  const funcionAnterior: string = obtenerNombreFuncionAnterior(2);
  const ocultarDescripcion: boolean =
    funcionAnterior.includes("callee") ||
    funcionAnterior.includes("eval") ||
    funcionAnterior.includes("@") ||
    funcionAnterior === "";
  const entorno = process.env.NODE_ENV;

  if (entorno !== "production") {
    // eslint-disable-next-line no-console
    if (ocultarDescripcion) console.log(`🖨 `, ...mensaje);
    // eslint-disable-next-line no-console
    else console.log(`🖨 `, `${funcionAnterior}`, ...mensaje);
  }
};

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export function isArray(x: unknown) : x is any[] {
  return Array.isArray(x);
}
