import React, { useContext } from "react";
import { LoginParams, LogoutParams, RegisterParams, UserRecord } from "./Auth";

export type AuthContextType = {
  user: UserRecord | null;
  login: (params: LoginParams) => void;
  logout: (params: LogoutParams) => void;
  setRol: (data: UserRecord | null) => void;
  resetPassword: (email: string) => void;
  updatePassword: (oldPassword: string, newPassword: string) => void;
  getCurrentTokenID: () => Promise<string | null>;
  register: (params: RegisterParams) => void;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("Context should be used within AuthContextProvider");
  }
  return context;
}

export default AuthContext;
