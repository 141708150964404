import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/Layout";
import MainBanner from "./components/MainBanner";
import GoodPracticeForm from "./components/GoodPracticeForm";
import FeaturedMunicipality from "./components/FeaturedMunicipality";
import Resources from "./components/Resources";
import NetworksBanner from "./components/NetworksBanner";
import Footer from "../../components/Footer";
import useAppContext from "../../shared/hooks/useAppContext";
import ReactGA from "react-ga4";

const GoodMunicipalPractices = () => {
  const { pathname, hash, key } = useLocation();
  const { getGoodPractices, getGoodPractices2 } = useAppContext();

  useEffect(() => {
    // https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (id === "formularioBuenasPrac") {
          if (element) {
            const offset = element.offsetTop - 70;
            window.scrollTo({ top: offset, behavior: "smooth" });
          }
        } else {
          if (element) {
            element.scrollIntoView();
          }
        }
      }, 0);
    }

    window.scrollTo(0, 0);
  }, [pathname, hash, key]);

  useEffect(() => {
    getGoodPractices2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGoodPractices]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/buenas-practicas",
      title: "Buenas prácticas",
    });
  }, []);

  return (
    <Layout>
      <MainBanner />
      <GoodPracticeForm />
      <FeaturedMunicipality />
      <Resources />
      <NetworksBanner />
      {/* <FundObjective /> */}
      {/* <FundBanner />
      <Announcement /> */}
      <Footer />
    </Layout>
  );
};
export default GoodMunicipalPractices;
