import { Box } from "@mui/material";
import Footer from "../../components/Footer";
import Layout from "../../components/Layout";
import { QuizViewerSection } from "../../shared/components/curso/QuizViewerSection";
import { machometroQuestions } from "../Courses/student/data/testMachometro";
import { TipoPregunta } from "../Courses/interface";
import { printLog } from "../../shared/utils";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

const MachometroPage = () => {
  const [resultado, setResultado] = useState<number | null>(null);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/machometro",
      title: "Machometro",
    });
  }, []);

  return (
    <Layout>
      <Box py={5}>
        <Box>
          <QuizViewerSection
            tituloQuiz="Machómetro"
            actionsPosition="static"
            withClose={false}
            idxContenido={undefined}
            tipo={"QUIZ"}
            loading={false}
            resetOnComplete={true}
            preguntas={machometroQuestions.map((item, idx: number) => ({
              indice: idx,
              titulo: item,
              tipo: TipoPregunta.SELECCION_UNICA,
              opcion1: { label: "Estoy de acuerdo", texto: "1" },
              opcion2: { label: "No estoy de acuerdo", texto: "0" },
            }))}
            result={resultado}
            onFinalizarQuiz={(data) => {
              printLog("Finalizar el quiz!!!! ", data);
              const valores = Object.values(data.respuestas);
              printLog("valores respuestas > ", valores);
              const nroAgrements = valores.reduce((acc, val) => {
                if (val === "1") acc++;
                return acc;
              });
              printLog("Cantidad de preguntas de acuerdo >>> ", nroAgrements);
              const resultadoPorcentaje = Math.round((nroAgrements * 100) / 26);
              setResultado(resultadoPorcentaje);
              printLog("Resultado evaluación > ", resultadoPorcentaje);
            }}
            isEvaluated={false}
            onCancel={() => {
              printLog("on cancel....");
            }}
          />
        </Box>
      </Box>
      <Footer />
    </Layout>
  );
};

export default MachometroPage;
