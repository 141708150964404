import {
  Box,
  Container,
  FormControl,
  TextField,
  TextFieldProps,
  Typography,
  alpha,
} from "@mui/material";
import { FieldError } from "react-hook-form";
import theme from "../../../themes";

type CInputFormProps = Omit<TextFieldProps, "error" | "variant"> & {
  inputIcon?: JSX.Element;
  error?: FieldError;
  textoInfo?: string;
};

const CInputForm = (props: CInputFormProps) => {
  const { inputIcon, error, textoInfo, ...inputProps } = props;

  return (
    <FormControl fullWidth>
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          backgroundColor: "#ECECEC",
          borderRadius: "8px",
          borderWidth: 0.5,
          borderStyle: "solid",
          borderColor: props.error ? theme.palette.error.main : "#ECECEC",
        }}
      >
        <TextField
          variant="standard"
          InputProps={{
            disableUnderline: true,
            endAdornment: inputIcon,
            // color: "info",
            // classes: { input: "{color: 'blue'}" },
          }}
          margin="none"
          autoComplete="off"
          style={{
            padding: 10,
          }}
          fullWidth
          sx={{
            input: {
              color: theme.palette.common.black,
              "&::placeholder": {
                color: alpha(theme.palette.common.black, 0.7),
                opacity: 1,
              },
            },
          }}
          {...inputProps}
        />
      </Box>
      {props.textoInfo && (
        <Container sx={{ textAlign: "left" }}>
          <Typography
            component={"span"}
            sx={{ color: theme.palette.warning.main, paddingTop: "2px" }}
          >
            {props.textoInfo}
          </Typography>
        </Container>
      )}
      {props.error && (
        <Typography
          component={"span"}
          sx={{
            textAlign: "left",
            fontSize: ".8rem",
            color: theme.palette.error.main,
          }}
        >
          {props.error && props.error.type && props.error.type === "required"
            ? "* "
            : ""}
          {props.error.message ? props.error.message : ""}
        </Typography>
      )}
    </FormControl>
  );
};

export default CInputForm;
