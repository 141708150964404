import {
  Alert,
  Box,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { ReactComponent as KeyboardBackspace } from "../../assets/icons/keyboard-backspace.svg";
import { colorsBase, DesignSystem } from "../../themes/theme";
import { ActionButton } from "../../shared/components/CButton";
import {
  useCreateNoticia,
  useGetNoticias,
  useDeleteNoticia,
  useGetMedia,
  useDeleteEnlace
} from "./NoticiaClient";
import { NoticiaDetalle } from "./NoticiaInterface";
import { Alerts } from "../../shared/hooks/useAlert"; 
import dayjs from "dayjs";
import { FormNoticiaComponent, NoticiaPayload } from "./components/FormNoticiaComponent";
import { useAuthContext } from "../../shared/auth/AuthContext";
import { SystemRoles } from "../../shared/common/constants";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import FeedIcon from '@mui/icons-material/Feed';
import LinkIcon from '@mui/icons-material/Link';
import { printLog } from "../../shared/utils";
import _ from "lodash";
import { CoursePrimaryButton } from "../Courses/shared/Elements";

export const NoticiaPage = () => {
  const navigate = useHistory();
  const { user } = useAuthContext();
  const [media, setMedia] = useState([]);
  const [noticias, setNoticias] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [noticiaDialog, setNoticiaDialog] = useState<boolean>(false);
  // const [params] = useSearchParams();
  const {state} = useLocation();
  printLog("state: " , state);
  const nombreRegional = state ? (state as {nombreRegional?: string}).nombreRegional : undefined;
  /// From Client
  const getNoticias = useGetNoticias();
  const createNoticia = useCreateNoticia();
  const deleteNoticia = useDeleteNoticia();
  const deleteEnlace = useDeleteEnlace();
  const getMedia = useGetMedia();

  const fetchNoticias = useCallback(async () => {
    try {
      const newsData = await getNoticias();
      
      let news = [...newsData.noticias, ...newsData.enlaces];
      let newsFormatted = news.map((item, index) => {
        
        const isLink = (Object.keys(item) as (keyof typeof item)[]).find((key) => {
          return key === 'prensa_cod';
        });
        
        return {
          id: isLink ? item.prensa_cod : item.noticia_cod,
          titulo: item.titulo,
          contenido: isLink ? '' :  item.contenido,
          estado: isLink ? 'PUBLICADO' : item.publicada ? 'PUBLICADO' : 'BORRADOR',
          esEnlaceDeInteres: isLink ? true : false,
          url: isLink ? item.prensa_enlace : ''
        };

      });
      
      const fetchedNoticias = _.orderBy(newsFormatted, ['ultimaModificacionFecha'],['desc']); 
      // const fetchedNoticias = noticiasFake;
      printLog("Noticias fetcheadas :: ", fetchedNoticias);
      setNoticias(fetchedNoticias as unknown as []);
      printLog("seteando loading a false");
      setLoading(false);
    } catch (error) {
      printLog("Ocurrió un error al obtener noticias :: ", error);
      setNoticias([]);
      setLoading(false);
    }
  }, []);

  const fetchMedia = useCallback(async () => {
    try {
      const mediaData = await getMedia();
      let mediaForSelect = mediaData.medios.map((item:any, index:any) => {
        return {
          value: item.medio_cod,
          label: item.medio_nombre
        };
      });
      setMedia(mediaForSelect as unknown as []);
      setLoading(false);
    } catch (error) {
      printLog("Ocurrió un error al obtener medios :: ", error);
      setMedia([]);
      setLoading(false);
    }
  }, []);

  // async function fetchNoticias() {
  //   try {
  //     const fetchedNoticias = await getNoticias();
  //     // const fetchedNoticias = noticiasFake;
  //     printLog('Noticias fetcheadas :: ', fetchedNoticias)
  //     setNoticias(fetchedNoticias as unknown as []);
  //     printLog('seteando loading a false');
  //     setLoading(false);
  //   } catch (error) {
  //     printLog('Ocurrió un error al obtener noticias :: ', error);
  //     setNoticias([]);
  //     setLoading(false);
  //   }
  // }

  const onDeleteNoticia = async (id: string) => {
    printLog("onDeleteNoticia -> ", id);
    await Alerts.showConfirm({
      title: "¿Estás segura(o) de eliminar la noticia?.",
      description: "",
      onConfirm: async () => {
        printLog("onDeleteNoticia -> onConfirm");
        setLoading(true);
        let error = false;
        try {
          printLog("Iniciando Borrado de noticia");
          await deleteNoticia(id);
          printLog("Finalizando Borrado de noticia");
        } catch (e) {
          error = true;
        }

        await fetchNoticias();
        setLoading(false);

        printLog("Listos para retomar. Errores? ", error);

        !error
          ? Alerts.showSuccess({
              confirmText: "Aceptar",
              description: "¡La noticia fue eliminada exitosamente!",
            })
          : Alerts.showError({
              confirmText: "Continuar",
              title: "Hubo un problema",
              description:
                "Lo sentimos. No pudimos eliminar la noticia seleccionada",
            });
      },
    });
  };

  const onDeleteEnlace = async (id: string) => {
    printLog("onDeleteEnlace -> ", id);
    await Alerts.showConfirm({
      title: "¿Estás segura(o) de eliminar el enlace de interés?.",
      description: "",
      onConfirm: async () => {
        printLog("onDeleteEnlace -> onConfirm");
        setLoading(true);
        let error = false;
        try {
          printLog("Iniciando Borrado de enlace");
          await deleteEnlace(id);
          printLog("Finalizando Borrado de enlace");
        } catch (e) {
          error = true;
        }

        await fetchNoticias();
        setLoading(false);

        printLog("Listos para retomar. Errores? ", error);

        !error
          ? Alerts.showSuccess({
              confirmText: "Aceptar",
              description: "¡El enlace fue eliminada exitosamente!",
            })
          : Alerts.showError({
              confirmText: "Continuar",
              title: "Hubo un problema",
              description:
                "Lo sentimos. No pudimos eliminar el enlace de interés seleccionado",
            });
      },
    });
  };

  useEffect(() => {
    fetchMedia();
    fetchNoticias();
  }, []);

  const onSubmitNoticia = async (data: NoticiaPayload) => {
    printLog("Guardar noticia :: ", data);
    const _data = { ...data };
    debugger;
    setLoading(true);
    const resultado = await createNoticia(_data);

    if (resultado) {
      setLoading(true);
      await fetchNoticias();
      setLoading(false);
      handleCloseDialogNoticia();
      if(data.tipo === 'NOTICIA') {
        Alerts.showSuccess({
          confirmText: "Aceptar",
          description:
            data.estado === "PUBLICADO"
              ? "¡La noticia fue publicada exitosamente!"
              : "¡El borrador de la noticia fue guardado exitosamente!",
        });
      } else {
        Alerts.showSuccess({
          confirmText: "Aceptar",
          description:
            data.estado === "PUBLICADO"
              ? "¡El enlace de interés fue publicado exitosamente!"
              : "¡El borrador del enlace de interés fue guardado exitosamente!",
        });
      }
      
    }
  };

  const handleCloseDialogNoticia = () => {
    setNoticiaDialog(false);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        mb={6}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            onClick={() =>
              navigate.push("/", {
                replace: true,
              })
            }
          >
            <KeyboardBackspace />
          </IconButton>
          <Typography sx={{ ...DesignSystem?.titulo1, pl: 2 }}>
            Noticias y enlaces de interés {nombreRegional ? `: ${nombreRegional}` : ""}
          </Typography>
        </Box>
        {user?.rol !== SystemRoles.PARTICIPANTE && (
          <Box>
            <CoursePrimaryButton onClick={() => setNoticiaDialog(true)}>
              Agregar +
            </CoursePrimaryButton>
          </Box>
        )}
      </Box>
      {loading ? (
        <LinearProgress />
      ) : (
        <Box
          sx={{
            background: "rgba(255, 255, 255, 0.5)",
            boxShadow: "1.5px 2.6px 10px rgba(119, 119, 119, 0.1)",
            boxSizing: "border-box",
            borderRadius: "8px",
            p: 4,
          }}
        >
          {noticias.map((noticia: NoticiaDetalle, index) => {
            return ( 
              <Box
                key={index}
                display="flex"
                flexDirection="row"
                alignContent="center"
                sx={{
                  py: 2,
                  px: 3,
                  borderBottom: `1px solid ${colorsBase.Verde3}`,
                  "&:hover": { cursor: "pointer" },
                }}
              >
                {user?.rol === SystemRoles.MONITOR && (
                  <Box alignSelf="center">
                    {
                      noticia.esEnlaceDeInteres ? 
                      <IconButton
                        size="small"
                        onClick={() => onDeleteEnlace(noticia.id)}
                        sx={{ fill: colorsBase.Azul1 }}
                      >
                        <TrashIcon />
                      </IconButton>
                      :
                      <IconButton
                        size="small"
                        onClick={() => onDeleteNoticia(noticia.id)}
                        sx={{ fill: colorsBase.Azul1 }}
                      >
                        <TrashIcon />
                      </IconButton>
                    }
                  </Box>
                )}
                {noticia.esEnlaceDeInteres === false && (
                <Box alignSelf="center">                 
                      <IconButton
                        size="small"
                      >
                        <FeedIcon />
                      </IconButton>                
                </Box>
                 )}
                {noticia.esEnlaceDeInteres === true && (
                <Box alignSelf="center">
                  <IconButton
                    size="small"
                    sx={{ fill: colorsBase.Azul1 }}
                  >
                    <LinkIcon />
                  </IconButton>
                </Box>
                 )}
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{
                    py: 2,
                    px: 3,

                    "&:hover": { cursor: "pointer" },
                  }}
                  onClick={() =>
                    navigate.push(`/admin/noticia/detalle`, {
                      noticiaId: noticia.id,
                      esEnlaceDeInteres: noticia.esEnlaceDeInteres
                    })
                  }
                >
                  <Typography component={"span"} sx={{ ...DesignSystem?.titulo2 }}>
                    {noticia.titulo}
                  </Typography>
                  <Typography
                    component={"span"}
                    sx={{ ...DesignSystem?.cuerpo3, pt: 0.5 }}
                  >
                    Fecha de publicación{" "}
                    {dayjs(noticia.fechaPublicacion).format("DD/MM/YYYY")}
                  </Typography>
                  {/* <Typography component={'span'} sx={{ ...DesignSystem.cuerpo3, pt: .5 }}>
                  { noticia.contenido }
                </Typography> */}
                </Box>
              </Box>
            )
          })}
          {noticias.length === 0 && (
            <Alert severity="warning">
              No se encontraron datos de Noticias
            </Alert>
          )}
        </Box>
      )}
      {noticiaDialog && (
        <FormNoticiaComponent
          noticiaDialog={noticiaDialog}
          onClose={handleCloseDialogNoticia}
          onSubmitNoticia={onSubmitNoticia}
          loading={loading}
          media={media}
        />
      )}
    </>
  );
};
