import {Box, Grid, IconButton, SelectChangeEvent} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import CInputForm from "../../../shared/components/form/CInputForm";
import {CoursePrimaryButton} from "../../Courses/shared/Elements";
import CSelectForm from "../../../shared/components/form/CSelectForm";
import {VisibilityOffRounded, VisibilityRounded} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {useAuthContext} from "../../../shared/auth/AuthContext";
import {useHistory} from "react-router-dom";
import {Alerts} from "../../../shared/hooks/useAlert";
import useHttp from "../../../shared/hooks/useHttp";
import CDatePicker from "../../../shared/components/form/CDatePicker";
import dayjs from "dayjs";
import {printLog} from "../../../shared/utils";
import {CInputRadio} from "../../../shared/components/form/CInputRadio";

export type RegisterFormType = {
  email: string;
  password: string;
  passwordConfirm: string;
  nombres: string;
  primerApellido: string;
  segundoApellido: string;
  birthDate: string;
  phoneNumber: string;
  departamento: string;
  municipio_cod: string;
  sexo: string;
  estadoCivil: string;
  tieneHijos: string;
  numeroHijos?: number;
  nivelEducativo: string;
  institution: string;
  position: string;
};

const RegisterForm = () => {
  const authContext = useAuthContext();
  const history = useHistory();
  const http = useHttp();
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((show) => !show);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleShowPassword2 = () => setShowPassword2((show) => !show);

  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  // const [departamentosForm, setDepartamentosForm] = useState([]);

  // const { control } = useForm<RegisterFormType>();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm<RegisterFormType>();

  useEffect(() => {
    obtenerDepartamentosMunicipios().finally();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const obtenerDepartamentosMunicipios = async () => {
    const response: any = await http.get("/municipio/porDepartamento", {});
    printLog("Respuesta deptos-municipios >> ", response);
    printLog("deptos >> ", response["departamentos"]);
    if (response) {
      setDepartamentos(response.departamentos);
      // const departamentosMapeado = response.departamentos.map((item: { nombre: string, municipios: any }) => {
      //   return {
      //     value: item.nombre,
      //     label: item.nombre,
      //   }
      // })
      // printLog('deptos mapeado >> ', departamentosMapeado)
      // setDepartamentosForm(departamentosMapeado)
    }
  };

  const onSubmit: SubmitHandler<RegisterFormType> = (data) => {
    printLog("Registrando participante con > ", data);
    printLog("fecha de nacimiento >> ", new Date(data.birthDate));
    printLog(
      "fecha de nacimiento con dayjs >> ",
      dayjs(data.birthDate).format("YYYY-MM-DD")
    );
    // TODO: validar que haya al menos un apellido de la persona
    // return;
    authContext.register({
      userRegister: {
        email: data.email,
        password: data.password,
        passwordConfirm: data.passwordConfirm,
        nombres: data.nombres,
        primerApellido: data.primerApellido,
        segundoApellido: data.segundoApellido,
        birthDate: dayjs(data.birthDate).format("YYYY-MM-DD"),
        phoneNumber: data.phoneNumber,
        municipio_cod: Number(data.municipio_cod),
        institution: data.institution,
        position: data.position,
        sexo: data.sexo,
        nivelEducativo: data.nivelEducativo,
        estadoCivil: data.estadoCivil,
        numeroHijos: data.numeroHijos ?? 0
      },
      onSuccess: async (userRecord: any) => {
        printLog("userRecord in login-register >> ", userRecord);
        localStorage.setItem(
          "user",
          JSON.stringify({
            username: data.email,
            password: data.password,
            rol: userRecord.rol,
          })
        );
        printLog("User logged in successfully:", userRecord);
        printLog("Navegando a '/catalogo-cursos'");
        // navigate('/usuario', { replace: true })
        history.push("/catalogo-cursos", { replace: true });
        Alerts.showInfo({
          description:
            "Para inscribirte en nuestros cursos, explora la lista de opciones y elige el que más te interese.",
          title: "¡Registro Exitoso!",
        });
        // if (userRecord.tokenFCM) {
        //   await registerDeviceToken(userRecord.uid, userRecord.tokenFCM!);
        // }
      },
      onFailure: (_message: any) => {
        printLog("Message error when register >> ", _message);
        Alerts.showError({
          // title: 'Error en la autenticación',
          description: _message,
        });
        // showAlertError({
        //   btnText: 'Aceptar',
        //   title: 'Error en la autenticación',
        //   message: _message,
        // })
      },
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={3} alignItems="center" mb={3}>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="nombres"
              control={control}
              defaultValue=""
              rules={{
                required: "El campo Nombres es requerido",
              }}
              render={({ field }) => (
                <CInputForm
                  type="text"
                  placeholder="Nombres *"
                  fullWidth
                  {...field}
                  ref={null}
                  error={errors.nombres}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="primerApellido"
              control={control}
              defaultValue=""
              // rules={{
              //   required: "El campo Primer Apellido es requerido",
              // }}
              render={({ field }) => (
                <CInputForm
                  type="text"
                  placeholder="Primer Apellido"
                  fullWidth
                  {...field}
                  ref={null}
                  error={errors.primerApellido}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="segundoApellido"
              control={control}
              defaultValue=""
              // rules={{
              //   required: "El campo Primer Apellido es requerido",
              // }}
              render={({ field }) => (
                <CInputForm
                  type="text"
                  placeholder="Segundo Apellido"
                  fullWidth
                  {...field}
                  ref={null}
                  error={errors.segundoApellido}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: "El campo Correo Electrónico es requerido",
            }}
            render={({ field }) => (
              <CInputForm
                type="email"
                placeholder="Correo electrónico *"
                fullWidth
                {...field}
                ref={null}
                error={errors.email}
              />
            )}
          />
        </Grid>

        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: "El campo Contraseña es requerido",
              }}
              render={({ field }) => (
                <CInputForm
                  type={showPassword ? "text" : "password"}
                  placeholder="Contraseña *"
                  fullWidth
                  {...field}
                  ref={null}
                  error={errors.password}
                  inputIcon={
                    <IconButton onClick={handleShowPassword}>
                      {!showPassword ? (
                        <VisibilityRounded />
                      ) : (
                        <VisibilityOffRounded />
                      )}
                    </IconButton>
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="passwordConfirm"
              control={control}
              defaultValue=""
              rules={{
                required: "La contraseña requiere ser confirmada",
              }}
              render={({ field }) => (
                <CInputForm
                  type={showPassword2 ? "text" : "password"}
                  placeholder="Confirmar contraseña *"
                  fullWidth
                  {...field}
                  ref={null}
                  error={errors.passwordConfirm}
                  inputIcon={
                    <IconButton onClick={handleShowPassword2}>
                      {!showPassword2 ? (
                        <VisibilityRounded />
                      ) : (
                        <VisibilityOffRounded />
                      )}
                    </IconButton>
                  }
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              name="birthDate"
              control={control}
              rules={{
                required: "El campo Fecha de nacimiento es requerido",
                validate: (value) =>
                  dayjs(value).year() < dayjs().year()
                    ? true
                    : "La fecha de nacimiento es incorrecta",
              }}
              render={({ field }) => (
                <CDatePicker
                  placeholder="Fecha de nacimiento *"
                  disableFuture={true}
                  {...field}
                  error={errors.birthDate}
                />
              )}
            />
            {/* <Controller
              name="birthDate"
              control={control}
              defaultValue=""
              rules={{
                required: "La fecha de nacimiento es requerido"
              }}
              render={({ field }) => (
                <CInputForm
                  type="text"
                  placeholder="Fecha de nacimiento *"
                  fullWidth
                  {...field}
                  ref={null}
                  error={errors.birthDate}
                />
              )}
            /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              rules={{
                required: "El campo Celular es requerido",
              }}
              render={({ field }) => (
                <CInputForm
                  type="text"
                  placeholder="Celular *"
                  fullWidth
                  {...field}
                  error={errors.phoneNumber}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              name="departamento"
              control={control}
              defaultValue=""
              // rules={{
              //   required: "El departamento es requerido"
              // }}
              render={({ field, formState }) => (
                <CSelectForm
                  placeholder="Departamento"
                  fullWidth
                  // items={departamentosForm}
                  items={departamentos.map((item: any) => ({
                    value: item.nombre,
                    label: item.nombre,
                  }))}
                  {...field}
                  error={errors.departamento}
                  onChange={(event: SelectChangeEvent) => {
                    printLog("errores de form > ", formState.errors);
                    setValue("departamento", event.target.value);
                    delete formState.errors.departamento;
                    setValue("municipio_cod", "");
                    const _depto: any = departamentos.find(
                      (item: any) => item.nombre === event.target.value
                    );
                    setMunicipios(_depto.municipios);
                  }}
                  ref={null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="municipio_cod"
              control={control}
              defaultValue={""}
              // rules={{
              //   required: "El municipio es requerido"
              // }}
              render={({ field }) => (
                <CSelectForm
                  placeholder="Municipio"
                  fullWidth
                  // items={departamentosForm}
                  items={municipios.map((item: any) => ({
                    value: item.municipio_cod,
                    label: item.municipio_desc,
                  }))}
                  {...field}
                  error={errors.municipio_cod}
                  ref={null}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              name="sexo"
              control={control}
              defaultValue={""}
              rules={{
                required: "El campo Sexo es requerido",
              }}
              render={({ field }) => (
                <CSelectForm
                  placeholder="Sexo *"
                  fullWidth
                  // items={departamentosForm}
                  items={[
                    { value: "MUJER", label: "Mujer" },
                    { value: "HOMBRE", label: "Hombre" },
                  ].map((item: any) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  {...field}
                  error={errors.sexo}
                  ref={null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="estadoCivil"
              control={control}
              defaultValue={""}
              rules={{
                required: "El campo Estado Civil es requerido",
              }}
              render={({ field }) => (
                <CSelectForm
                  placeholder="Estado Civil *"
                  fullWidth
                  items={[
                    { value: "SOLTERO", label: "Soltera/o" },
                    { value: "CASADO", label: "Casada/o" },
                    {
                      value: "CONVIVIENTE",
                      label: "Conviviente/Concubina/o",
                    },
                    {
                      value: "DIVORCIADO",
                      label: "Divorciada/Separada/o",
                    },
                    { value: "OTRO", label: "Otro" },
                  ].map((item: any) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  {...field}
                  error={errors.estadoCivil}
                  ref={null}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CInputRadio
              id="tieneHijos"
              row
              name="tieneHijos"
              control={control}
              label="Tiene Hijos? *"
              rules={{
                required: "El campo Tiene hijos es requerido",
              }}
              options={[
                { value: "SI", label: "Sí" },
                { value: "NO", label: "No" },
              ].map((item) => ({
                key: item.label,
                value: item.value,
                label: item.label,
              }))}
              // disabled={estado === EstadoSolicitud.APROBADO}
            />
          </Grid>

          {watch("tieneHijos") === "SI" && (
            <Grid item xs={12} md={6}>
              <Controller
                name="numeroHijos"
                control={control}
                // defaultValue={0}
                rules={{
                  required: "El campo Número de hijos es requerido",
                }}
                render={({ field }) => (
                  <CInputForm
                    type="number"
                    placeholder="Número de Hijos *"
                    fullWidth
                    {...field}
                    ref={null}
                    error={errors.numeroHijos}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} md={watch("tieneHijos") === "SI" ? 12 : 6}>
            <Controller
              name="nivelEducativo"
              control={control}
              defaultValue={""}
              rules={{
                required: "El campo Nivel Educativo es requerido",
              }}
              render={({ field }) => (
                <CSelectForm
                  placeholder="Nivel Educativo *"
                  fullWidth
                  items={[
                    { value: "NINGUNO", label: "Ninguno" },
                    {
                      value: "PRIMARIA",
                      label: "Educación primaria (de 1ro a 6to de primaria)",
                    },
                    {
                      value: "SECUNDARIA",
                      label:
                        "Educación secundaria (de 1ro a 6to de secundaria)",
                    },
                    {
                      value: "SUPERIOR_INCOMPLETO",
                      label:
                        "Superior incompleto (En proceso en un Insituto Técnico o Universidad)",
                    },
                    {
                      value: "SUPERIOR_COMPLETO",
                      label:
                        "Superior completo (Concluido el Instituto técnico o la Universidad)",
                    },
                  ].map((item: any) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                  {...field}
                  error={errors.nivelEducativo}
                  ref={null}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              name="institution"
              control={control}
              defaultValue=""
              rules={{
                required: "La institución es requerido",
              }}
              render={({ field }) => (
                <CInputForm
                  type="text"
                  placeholder="Institución *"
                  fullWidth
                  {...field}
                  ref={null}
                  error={errors.institution}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="position"
              control={control}
              defaultValue=""
              rules={{
                required: "El cargo es requerido",
              }}
              render={({ field }) => (
                <CInputForm
                  type="text"
                  placeholder="Cargo/Ocupación *"
                  fullWidth
                  {...field}
                  error={errors.position}
                  ref={null}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <CoursePrimaryButton
            fullWidth
            type="submit"
            disabled={
              errors.hasOwnProperty("email") ||
              errors.hasOwnProperty("password") ||
              errors.hasOwnProperty("passwordConfirm") ||
              errors.hasOwnProperty("fullName") ||
              errors.hasOwnProperty("birthDate") ||
              errors.hasOwnProperty("phoneNumber") ||
              errors.hasOwnProperty("departamento") ||
              errors.hasOwnProperty("municipio_cod") ||
              errors.hasOwnProperty("institution") ||
              errors.hasOwnProperty("position")
            }
          >
            Regístrate
          </CoursePrimaryButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterForm;
