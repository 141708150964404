import { Box, Button, useTheme } from "@mui/material";
import { CourseContentDialog } from "../Courses/shared/Elements"
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";
import { useState } from "react";

export enum AuthType {
  LOGIN,
  REGISTER,
}

type AuthDialogProps = {
  open: boolean;
  onClose: () => void;
  type?: AuthType
}

const AuthDialog = ({ open, onClose, type }: AuthDialogProps) => {
  const theme = useTheme()
  const [typeAuth, setTypeAuth] = useState<AuthType>(type ?? AuthType.LOGIN);

  return (
    <CourseContentDialog
      open={open}
      onClose={onClose}
      withTitle={false}
      withActions={false}
    >
      <Box>
        {/* TODO: pendiente tipado para typeAuth */}
        <Button
          onClick={() => setTypeAuth(AuthType.REGISTER)}
          variant="text"
          sx={{
            fontWeight: 500,
            fontSize: "1.5rem",
            color:
              typeAuth === AuthType.REGISTER
                ? theme.palette.info.main
                : theme.palette.neutral.main,
          }}
        >
          Crea tu cuenta
        </Button>
        <Button
          onClick={() => setTypeAuth(AuthType.LOGIN)}
          variant="text"
          sx={{
            fontWeight: 500,
            fontSize: "1.5rem",
            color:
              typeAuth === AuthType.LOGIN
                ? theme.palette.info.main
                : theme.palette.neutral.main,
          }}
        >
          Inicia sesión
        </Button>
      </Box>
      <Box>
        { typeAuth === AuthType.LOGIN && (<>
          <Box mt={2} mb={3}>
            <LoginForm />
          </Box>
        </>)}
        { typeAuth === AuthType.REGISTER && (<>
          <Box mt={2} mb={3}>
            <RegisterForm />
          </Box>
        </>)}
      </Box>
    </CourseContentDialog>
  )
}

export default AuthDialog