import { Box, Chip, Typography, styled, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";
import theme from "../../../../../themes";
import useAppContext from "../../../../../shared/hooks/useAppContext";

const StyledBox = styled(Box)(({ theme }) => ({
  margin: 0,
  padding: 0,
  width: "100%",
  // height: "310px",
  // maxHeight: "310px",
  borderBottom: `1px solid ${theme.palette.neutral.main}`,
  cursor: "pointer",
  // TODO: mobile breakpoints
  [theme.breakpoints.up("xs")]: {
    maxHeight: "fit-content",
    height: "auto",
  },
  // [theme.breakpoints.up("sm")]: {
  //   maxHeight: "360px",
  //   height: "360px",
  // },
  // [theme.breakpoints.up("md")]: {
  //   maxHeight: "366px",
  //   height: "366px",
  // },
  // [theme.breakpoints.up("lg")]: {
  //   maxHeight: "351px",
  //   height: "351px",
  // },
}));

const StyledImg = styled("img")(() => ({
  width: "100%",
  // width: 125,
  // height: "100%",
  height: 180,
  // maxHeight: 200,
  objectFit: "cover",
  borderRadius: "8px",
  [theme.breakpoints.down("sm")]: {
    // height: "35%",
    height: 125,
  },
}));

const MunicipalityCard: React.FC<any> = ({
  newsType,
  municipality,
  title,
  date,
  content,
  image,
  url,
  isInterestLink,
  mediaStr,
  htmlContent,
  lastDateModified,
  code,
  urlSufix,
  category,
  isSolidar,
}) => {
  const { setInterestLinkContent, setNewsContent } = useAppContext();
  let history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = () => {
    if (isInterestLink) {
      setInterestLinkContent(null);
      history.push(`/hemeroteca/link-de-interes/${code}`);
    } else {
      setNewsContent(null);
      history.push(`/hemeroteca/noticia/${code}`);
    }
  };

  return (
    <StyledBox
      display="flex"
      justifyContent="center"
      alignItems={"stretch"}
      onClick={handleClick}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          width: "65%",
          padding: "1.5em",
          maxHeight: "inherit",
          height: "inherit",
          position: "relative",
          [theme.breakpoints.down("sm")]: {
            width: "70%",
          },
        }}
      >
        <Box flex={1} sx={{ overflowY: "hidden" }}>
          <Box display="flex" flexWrap={"wrap"}>
            <Chip
              size={isMobile ? "small" : "medium"}
              label={isInterestLink ? " ENLACE DE INTERÉS" : "NOTICIA"}
              sx={{
                backgroundColor: "#818181",
                color: "#FFFFFF",
                [theme.breakpoints.down("sm")]: {
                  fontSize: 10,
                },
              }}
            />
            {mediaStr ? (
              <Chip
                size={isMobile ? "small" : "medium"}
                color="primary"
                label={mediaStr}
                sx={{
                  color: "#FFFFFF",
                  mx: "0.5em",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: 10,
                  },
                }}
              />
            ) : null}
            {isSolidar ? (
              <Chip
                size={isMobile ? "small" : "medium"}
                label={"SOLIDAR SUIZA"}
                sx={{
                  color: "#FFFFFF",
                  backgroundColor: "#ff0001",
                  mx: "0.5em",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: 10,
                  },
                }}
              />
            ) : null}
          </Box>
          <Box my={"0.5em"}>
            <Typography
              sx={{
                fontFamily: "Barlow",
                fontSize: { xs: 16, md: 22 },
                color: "#54595F",
                fontWeight: "bold",
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box display="flex" flexGrow={1}>
            <Typography sx={{ color: theme.palette.neutral.active }}>
              {`${date} / ${category}`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        sx={{
          width: "35%",
          // py: 3,
          padding: "1.5em 0",
          [theme.breakpoints.down("sm")]: {
            width: "30%",
          },
        }}
      >
        {/* <Box
          display="inline-block"
          height={"100%"}
          width={"100%"}
          sx={{
            padding: {
              xs: "1.5em 1.5em 0 0",
              sm: "1.5em 0 0 0",
              md: "1.5em 0 0 0",
            },
          }}
        >
        </Box> */}
        <StyledImg src={image} alt="noticia img" />
      </Box>
    </StyledBox>
  );
};

export default MunicipalityCard;
