import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
// import { colorsBase } from "../../../themes/theme";
import iconNoticias from "../../../assets/icon/iconNoticias.svg";
import iconCursos from "../../../assets/icon/iconCursos.svg";
import iconReportes from "../../../assets/icon/iconReportes.svg";
import iconCreacionUsuarios from "../../../assets/icon/iconCreacionUsuarios.svg";
import iconDirectory from "../../../assets/admin/directorio-blanco.svg";
import iconRegionales from "../../../assets/icon/iconRegionales.svg";
import iconCommunication from "../../../assets/Communication/admin/promotion.png";
// import iconExperiencias from "../../../assets/admin/experiencias-blanco.svg";
// import iconNumbers from "../../../assets/admin/cifras-circulo.svg";
// import iconDirectory from "../../../assets/icon/iconDirectory.svg";
import iconNumbers from "../../../assets/admin/cifras-blanco.svg"; // bien
import { useHistory } from "react-router-dom";
import theme from "../../../themes";

export const DashboardMonitor = ({ classes, goToPage }: any) => {
  const navigate = useHistory();
  const verReportes = () => {
    navigate.push("/reporte", { state: { userRol: "MONITOR" } });
  };

  return (
    <Grid container spacing={4} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={6} lg={4}>
        {/* <Card elevation={0} className={classes.cardUsuarios} onClick={(ev) => goToPage(ev, "/usuario")}> */}
        <Card elevation={0} onClick={(ev) => goToPage(ev, "/admin/usuario")}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                border: "3px solid white",
                backgroundColor: theme.palette.neutral.active,
                p: 2,
              }}
            >
              <img src={iconCreacionUsuarios} alt="icon-noticias" />
            </Box>
            <Typography component="div" className={classes.cardTitle} my={2}>
              Usuarios
            </Typography>
            {/* <Typography className={classes.cardText}>
            Creación de usuarios dentro de la plataforma
          </Typography> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card
          elevation={0}
          className={classes.cardCursos}
          onClick={(ev) => goToPage(ev, "/admin/cursos")}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                border: "3px solid white",
                backgroundColor: theme.palette.info.main,
                p: 2,
              }}
            >
              <img src={iconCursos} alt="icon-cursos" />
            </Box>
            <Typography component="div" className={classes.cardTitle} my={2}>
              Cursos
            </Typography>
            {/* <Typography className={classes.cardText}>
            Creación de cursos dentro de la plataforma
          </Typography> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card
          elevation={0}
          className={classes.cardRegionales}
          onClick={(ev) => {
            ev.preventDefault();
            navigate.push("/admin/iniciativa", { state: { isPractice: true } });
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                border: "3px solid white",
                backgroundColor: theme.palette.secondary.active,
                p: 2,
              }}
            >
              <img src={iconRegionales} alt="icon-regionales" />
            </Box>
            <Typography component="div" className={classes.cardTitle} my={2}>
              Buenas prácticas
            </Typography>
            {/* <Typography className={classes.cardText}>
            Creación de buenas prácticas y experiencias innovadoras
          </Typography> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card
          elevation={0}
          className={classes.cardRegionales}
          onClick={(ev) => {
            ev.preventDefault();
            navigate.push("/admin/iniciativa", {
              state: { isPractice: false },
            });
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                border: "3px solid white",
                backgroundColor: theme.palette.error.main,
                p: 2,
              }}
            >
              <img
                src={iconRegionales}
                alt="icon-experiencias"
                width="50"
                height="55"
              />
            </Box>
            <Typography component="div" className={classes.cardTitle} my={2}>
              Experiencias Innovadoras
            </Typography>
            {/* <Typography className={classes.cardText}>
            Creación de buenas prácticas y experiencias innovadoras
          </Typography> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card
          elevation={0}
          className={classes.cardNoticias}
          onClick={(ev) => goToPage(ev, "/admin/noticia")}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                border: "3px solid white",
                backgroundColor: theme.palette.success.active,
                p: 2,
              }}
            >
              <img src={iconNoticias} alt="icon-noticias" />
            </Box>
            <Typography component="div" className={classes.cardTitle} my={2}>
              Noticias y enlaces de interés
            </Typography>
            {/* <Typography className={classes.cardText}>
            Creación de noticias dentro de la plataforma
          </Typography> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card
          elevation={0}
          className={classes.cardReportes}
          onClick={(ev) => goToPage(ev, "/admin/reporte")}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                border: "3px solid white",
                backgroundColor: theme.palette.primary.main,
                p: 2,
              }}
            >
              <img src={iconReportes} alt="icon-reportes" />
            </Box>
            <Typography component="div" className={classes.cardTitle} my={1.6}>
              Reportes
            </Typography>
            {/* <Typography className={classes.cardText}>
            Visualización y descarga de reportes globales
          </Typography> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card
          elevation={0}
          className={classes.cardReportes}
          onClick={(ev) => goToPage(ev, "/admin/directorio")}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                border: "3px solid white",
                backgroundColor: "#C09CC3",
                p: 2,
              }}
            >
              <img
                src={iconDirectory}
                alt="icon-directorio"
                width="60"
                height="70"
              />
            </Box>
            <Typography component="div" className={classes.cardTitle} my={1.6}>
              Directorio municipal
            </Typography>
            {/* <Typography className={classes.cardText}>
            Visualización y descarga de reportes globales
          </Typography> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card
          elevation={0}
          className={classes.cardReportes}
          onClick={(ev) => goToPage(ev, "/admin/cifras")}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                border: "3px solid white",
                backgroundColor: "#0181B3",
                p: 2,
              }}
            >
              <img src={iconNumbers} alt="icon-cifras" width="60" height="70" />
            </Box>
            <Typography component="div" className={classes.cardTitle} my={1.6}>
              Cifras de tu municipio
            </Typography>
            {/* <Typography className={classes.cardText}>
            Visualización y descarga de reportes globales
          </Typography> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Card
          elevation={0}
          className={classes.cardReportes}
          onClick={(ev) => goToPage(ev, "/admin")}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                border: "3px solid white",
                backgroundColor: "#041c66",
                p: 2,
              }}
            >
              <img
                src={iconCommunication}
                alt="icon-comunicacion"
                width="60"
                height="70"
              />
            </Box>
            <Typography component="div" className={classes.cardTitle} my={1.6}>
              Comunicación
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
