import useHttp from "../../shared/hooks/useHttp";
import { useMutation, useQuery } from "@tanstack/react-query";

export type Municipio = {
  municipio_cod: number;
  municipio_desc: string;
};

export type Departamento = {
  departamento_cod: number,
  nombre: string,
  municipios: Array<Municipio>
}

export type GetMunicipiosResponse = {
  departamentos: Array<Departamento>
}

export function useGetMunicipios() {
  const http = useHttp();

  return useQuery({
    queryKey: ["departamentosPorMunicipio"],
    queryFn: async () => (await http.get("/municipio/porDepartamento", {}) as GetMunicipiosResponse)
  })
}

export type MunicipalityOverview = Municipio & {
  data: {
    [type: string]: {
      addresses: string[],
      phoneNumbers: string[],
      title: string
    }
  }
}

export function useGetMunicipalityOverview(municipio_cod: number) {
  const http = useHttp();

  return useQuery({
    queryKey: ["municipalDirectoryOverview", municipio_cod],
    queryFn: async () => (await http.get(`/municipalDirectory/${municipio_cod}`, {}) as MunicipalityOverview)
  })
}

export type SaveDirectoryRequest = {
  municipio_cod: number;
  data: {
    [type: string]: {
      addresses: string[],
      phoneNumbers: string[],
      title: string
    }
  }
}

export function useSaveDirectory() {
  const http = useHttp();
  return useMutation({
    mutationFn: (data: SaveDirectoryRequest) =>
      http.post("/municipalDirectory", { payload: data }),
    gcTime: 60 * 1000 // 1 minute
  });
}


