import { IconButton } from "@mui/material";
import { useState } from "react";
import theme from "../../../themes";

export type SocialItemProps = {
  withBorder: boolean;
  customAlt: string;
  iconPath: string;
  disableIconPath: string;
  action: () => void;
};

export const SocialItem = ({
  withBorder,
  disableIconPath,
  iconPath,
  customAlt,
  action,
}: SocialItemProps) => {
  const [imageSource, setImageSource] = useState(disableIconPath);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setImageSource(iconPath);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setImageSource(disableIconPath);
  };

  return (
    <IconButton
      size="small"
      onClick={action}
      sx={{
        border: withBorder ? `1px solid ${theme.palette.grey[400]}` : "none",
        padding: 0,
        margin: 0,
      }}
    >
      <img
        src={imageSource}
        alt={customAlt}
        style={{
          maxWidth: "45px",
          height: "auto",
          objectFit: "contain",
          transition: `opacity 0.5s ease-in-out, transform 0.4s ease-in-out`,
          opacity: isHovered ? 0.9 : 1,
          transform: isHovered ? "scale(1.1)" : "scale(1)",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </IconButton>
  );
};
