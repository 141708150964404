import {Box, Button, Card, CardMedia, Grid, Typography} from "@mui/material";
import {alpha, styled} from "@mui/material/styles";
import {useHistory} from "react-router-dom";
import {BannerProps} from "../../../../interfaces/CarouselBannerProps";
import theme from "../../../../themes";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  height: "100%",
  // background: 'linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2))',
}));

const StyledCardContainer = styled(Card)(() => ({
  // height: 400,
  height: "70vh",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "fit-content",
    height: 550,
  },
}));

const StyledBannerGrid = styled(Grid)(() => ({
  height: "100%",
  position: "relative",
}));

const StyledCardMedia = styled(CardMedia)(() => ({
  backgroundColor: "#fff",
  height: "100%",
  overflow: "hidden",
  position: "relative",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.up("lg")]: {
    backgroundPosition: "0% 60%",
  },
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "1.5em 3em 1.5em 1.5em",
  backgroundColor: alpha(theme.palette.secondary.active!, 0.8),
  width: "50%",
  height: "100%",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "3em 1.5em 1.5em 1.5em",
  },
}));

const StyledTransparentArea = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "0em 1.5em 1.5em 1.5em",
  marginTop: "-1em",
  backgroundColor: "transparent",
  width: "100%",
  height: "100%",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "3em 1.5em 1.5em 1.5em",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  backgroundColor: theme.palette.primary.main,
  borderRadius: "8px",
  width: "200px",
  height: "50px",
  color: theme.palette.secondary.active,
  "&:hover": {
    backgroundColor: theme.palette.primary.hover,
    color: "#FFFFFF",
  },
  opacity: 1,
}));

const Banner = (props: BannerProps) => {
  let history = useHistory();
  const { item } = props;

  const media = (
    <Grid item xs={12} key={item.title}>
      <StyledCardMedia image={item.image} title={item.title}>
        <StyledBox
          display="flex"
          justifyContent="center"
          sx={{
            background: !item.noContent
              ? "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2))"
              : "inherit",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "75%" },
              height: "100%",
              position: "relative",
              margin: 0,
              padding: 0,
            }}
          >
            {!item.noContent ? (
              <StyledDivMediaCaption>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="end"
                  sx={{ height: "100%", margin: 0, padding: 0 }}
                >
                  <Box mb={2}>
                    <Typography
                      variant="h1"
                      sx={{
                        textTransform: "uppercase",
                        fontFamily: "Barlow",
                        fontSize: { xs: "16px", md: "22px" },
                        color: "#e6ac0c",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        my: 1,
                        fontFamily: "Barlow",
                        fontWeight: 400,
                        // fontSize: 42,
                        fontSize: { xs: "36px", md: "36px" },
                        color: theme.palette.common.white,
                        lineHeight: 1,
                      }}
                    >
                      {item.caption}
                    </Typography>
                    <Typography
                      sx={{
                        mt: "1em",
                        fontFamily: "Ubuntu",
                        fontWeight: 300,
                        fontSize: 16,
                        color: theme.palette.common.white,
                      }}
                    >
                      {item.content}
                    </Typography>
                  </Box>
                  <Box mt={3} mb={1}>
                    <StyledButton
                      variant="contained"
                      onClick={() =>
                        history.push(item?.urlSection ? item?.urlSection : "/")
                      }
                    >
                      LEER COMPLETO
                    </StyledButton>
                  </Box>
                </Box>
              </StyledDivMediaCaption>
            ) : (
              <StyledTransparentArea>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent={{
                    xs: "flex-start",
                    md: "flex-start",
                  }}
                  alignItems="center"
                  sx={{
                    height: "100%",
                    marginY: "5rem",
                    padding: 0,
                  }}
                >
                  <Typography
                    sx={{
                      lineHeight: "1.2",
                      fontFamily: "Barlow",
                      fontSize: { xs: "25px", md: "42px" },
                      color: theme.palette.secondary.active,
                      fontWeight: 500,
                    }}
                  >
                    ¡Bienvenidos y bienvenidas!
                  </Typography>

                  <Box
                    display="flex"
                    sx={{
                      // justifyContent:'center',
                      // alignItems:'center'
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      align="center"
                      sx={{
                        lineHeight: "1.2",
                        fontFamily: "Barlow",
                        fontSize: { xs: "25px", md: "42px" },
                        color: theme.palette.secondary.active,
                        fontWeight: 500,
                      }}
                    >
                      Comunidad Virtual <strong>Vida Digna Sin Violencia</strong>
                    </Typography>
                  </Box>
                </Box>
              </StyledTransparentArea>
            )}
          </Box>
        </StyledBox>
      </StyledCardMedia>
    </Grid>
  );

  return (
    <StyledCardContainer raised>
      <StyledBannerGrid container spacing={0}>
        {media}
      </StyledBannerGrid>
    </StyledCardContainer>
  );
};

export default Banner;
