import Carousel from "react-material-ui-carousel";
import { useHistory } from "react-router-dom";
import Banner from "./Banner";
import { DefaultSettings } from "./DefaultSettings";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material";
import theme from "../../../../themes";
import useAppContext from "../../../../shared/hooks/useAppContext";

const StyledContainerBox = styled(Box)(({ theme }) => ({
  margin: "0 0 0 0",
  padding: "3em 0 0 0",
  width: "100%",
  backgroundColor: theme.palette.primary.main,
}));

const StyledCardMedia = styled(CardMedia)(() => ({
  backgroundColor: "black",
  height: 300,
  overflow: "hidden",
  position: "relative",
  transition: "300ms",
  cursor: "pointer",
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "15px",
  bottom: 0,
  color: "white",
  width: "100%",
  height: "45%",
  fontSize: "21px",
  fontWeight: "200",
  transition: "300ms",
  cursor: "pointer",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  borderRadius: '8px',
  background: "rgb(0 0 0 /0.4)",
  color: theme.palette.primary.main,
  width: "200px",
  height: "50px",
  "&:hover": {
    background: "rgb(0 0 0 /0.7)",
  },
  [theme.breakpoints.down("md")]: {
    width: "150px",
  },
}));

const StyledMobileButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  borderRadius: '8px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.active,
  bottom: "1em",
  "&:hover": {
    backgroundColor: theme.palette.primary.hover,
    color: "#FFFFFF",
  },
}));

const CourseCarousel = () => {
  const { homeCourses } = useAppContext();
  let history = useHistory();
  const matchMd: boolean = useMediaQuery(theme.breakpoints.down("md"));

  const renderMobileCards = () => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          padding: "1.5em 1.5em 1.5em 1.5em",
        }}
      >
        {homeCourses.map((item, index) => (
          <Box my={4} key={index}>
            <Card elevation={0} style={{ borderRadius: '8px', height: '100%', display: "flex", flexDirection: "column" }}>
              <StyledCardMedia image={item.urlImagen} title={item.nombre}>
                <Box
                  width="100%"
                  height="100%"
                  sx={{
                    background:
                      "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2))",
                  }}
                >
                  <StyledDivMediaCaption sx={{ px: 3, pb: 3 }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="end"
                      alignItems={'start'}
                      height="100%"
                      gap={2}
                    >
                      {item.type ? (
                        <Chip
                          label={item.type}
                          sx={{
                            backgroundColor: theme.palette.secondary.active,
                            fontWeight: 400,
                            color: theme.palette.common.white,
                          }}
                        />
                      ) : null}
                      <Box>
                        <Typography
                          sx={{
                            fontSize: { xs: 25, lg: 31 },
                            fontFamily: "Barlow",
                            lineHeight: "1",
                          }}
                        >
                          {item.nombre}
                        </Typography>
                      </Box>
                    </Box>
                  </StyledDivMediaCaption>
                </Box>
              </StyledCardMedia>
              <CardContent sx={{ px: 3, py: 3, flex: 1 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontSize: 14,
                    textAlign: 'justify',
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {item.descripcion}
                </Typography>
              </CardContent>
              <CardActions sx={{ py: 2, px: 3, }}>
                <StyledMobileButton
                  variant="contained"
                  onClick={() =>
                    history.push("/catalogo-cursos/detalle", {
                      course: item,
                    })
                  }
                >
                  LEER COMPLETO
                </StyledMobileButton>
              </CardActions>
            </Card>
          </Box>
        ))}
      </Box>
    );
  };

  const renderCarousel = () => {
    let cards = [...homeCourses];
    if (homeCourses && homeCourses.length % 3 !== 0) {
      let cardsToSlice = 3 - (homeCourses.length % 3);
      if (cardsToSlice) {
        let slicedArray: any = [];
        if (homeCourses.length >= 2) {
          slicedArray = homeCourses.slice(0, cardsToSlice);
        } else {
          for (let x = 1; x <= cardsToSlice; x++) {
            slicedArray.push(homeCourses[0]);
          }
        }
        cards = [...cards, ...slicedArray];
      }
    }

    let tmpArray: any = [];
    let cardsArray: any = [];
    for (let x = 0; x < cards.length; x++) {
      tmpArray.push(cards[x]);
      if (tmpArray.length === 3) {
        cardsArray.push(tmpArray);
        tmpArray = [];
      }
    }

    return (
      <Carousel
        {...DefaultSettings}
        navButtonsProps={{
          style: {
            backgroundColor: alpha(theme.palette.common.black, 0.4),
          },
        }}
      >
        {cardsArray.map((item: any, index: number) => {
          return <Banner items={item} key={index} />;
        })}
      </Carousel>
    );
  };

  return (
    <StyledContainerBox display="flex" flexDirection="column">
      <Box display="flex" justifyContent="center">
        <Box
          sx={{
            width: { xs: "100%", md: "75%" },
            height: "100%",
            padding: 0,
            margin: 0,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            mt={"3em"}
            mb={"1.5em"}
            padding={"0 1.5em 0 1.5em"}
          >
            <Typography
              variant="h1"
              sx={{ fontFamily: "Barlow", fontSize: 42, color: "#FFFFFF" }}
            >
              Cursos
            </Typography>
            <StyledButton
              variant="text"
              onClick={() => history.push("/catalogo-cursos")}
            >
              <Typography>Ver todos</Typography>
            </StyledButton>
          </Stack>
        </Box>
      </Box>
      <Box>{matchMd ? renderMobileCards() : renderCarousel()}</Box>
    </StyledContainerBox>
  );
};

export default CourseCarousel;
