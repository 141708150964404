import { useMediaQuery, useTheme } from "@mui/material";

const FacebookIframe = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <iframe
      src="https://www.facebook.com/v18.0/plugins/page.php?href=https://www.facebook.com/p/Vida-Digna-Sin-Violencia-61551458335758&show_posts=true"
      frameBorder="0"
      allowTransparency={true}
      allowFullScreen={true}
      scrolling="no"
      allow="encrypted-media"
      width={xs ? "100%" : "350px"}
      height="500px"
      style={{
        // border: "1px solid blue",
        visibility: "visible",
        display: "block",
        margin: "0 100px",
      }}
    ></iframe>
  );
};

export default FacebookIframe;
