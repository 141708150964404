import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/Layout";
import MainBanner from "./components/MainBanner";
import GreenExperiencesArea from "./components/GreenExperiencesArea";
import WhiteExperiencesArea from "./components/WhiteExperiencesArea";
import Footer from "../../components/Footer";
import useAppContext from "../../shared/hooks/useAppContext";
import ReactGA from "react-ga4";

const InnovativeExperiences = () => {
  const { pathname, hash, key } = useLocation();

  const { getInnovativeExperiences } = useAppContext();

  useEffect(() => {
    getInnovativeExperiences();
  }, [getInnovativeExperiences]);

  useEffect(() => {
    // https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }

    window.scrollTo(0, 0);
  }, [pathname, hash, key]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/experiencias-innovadoras",
      title: "Experiencias Innovadoras",
    });
  }, []);

  return (
    <Layout>
      <MainBanner />
      <GreenExperiencesArea />
      <WhiteExperiencesArea />
      {/* <WeavingAutonomies /> */}
      {/* <InformationAccordion /> */}
      {/* <Announcement /> */}
      <Footer />
    </Layout>
  );
};

export default InnovativeExperiences;
