import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { colorsBase, DesignSystem } from "../../../themes/theme";
import { CancelRounded, CloseRounded } from "@mui/icons-material";
import {
  CancelButton,
  ConfirmButton,
} from "../../../shared/components/CButton";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CFilePicker } from "../../../shared/components/form/CFilePicker";
import { ChangeEvent, useState } from "react";
import { printLog } from "../../../shared/utils";
import CSelectForm from "../../../shared/components/form/CSelectForm";

export interface ICifraForm {
  gestion: string;
}

type CifraFormDialogType = {
  open: boolean;
  onClose: () => void;
};

export const CifraFormDialog = ({ open, onClose }: CifraFormDialogType) => {
  const [adjuntos, setAdjuntos] = useState<File[]>([]);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    getValues,
    watch,
    setValue,
  } = useForm<ICifraForm>();

  const handleChangePicker = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files && target.files.length) {
      printLog(
        "[CifraForm] >  Archivos seleccionados de FilePicker :: target.files[0]",
        target.files![0]
      );
      setAdjuntos([...target.files]);
    }
  };

  const handleSubmitGuardar: SubmitHandler<ICifraForm> = async (data) => {
    printLog("guardar archivo de cifras :: ", data);
    printLog("archivos de cifras :: ", adjuntos);



  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ borderBottom: `1px solid ${colorsBase.BorderGray}` }}
        >
          <Typography component={"span"} sx={{ ...DesignSystem?.titulo1 }}>
            Agregar Cifras de tu municipio
          </Typography>
          <IconButton onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box component={"form"} onSubmit={handleSubmit(handleSubmitGuardar)}>
        <DialogContent>
          {/* <Grid container spacing={3} sx={{ px: 2 }}>
        <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Departamento</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Controller
                name="departamento_cod"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CSelectForm
                    placeholder="Seleccione el Departamento"
                    fullWidth
                    items={departamentos.map((item: any) => ({
                      value: item.departamento_cod,
                      label: item.nombre,
                    }))}
                    {...field}
                    ref={null}
                    onChange={(selectedValue:SelectChangeEvent) => {
                      field.onChange(selectedValue);
                      const _depto: any = departamentos.find((item: any) => item.departamento_cod ===selectedValue.target.value)
                      setMunicipios(_depto.municipios);}}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Municipio</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Controller
                name="municipio_cod"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CSelectForm
                    placeholder="Seleccione el Municipio"
                    fullWidth
                    items={municipios.map((item: any) => ({
                      value: item.municipio_cod,
                      label: item.municipio_desc,
                    }))}
                    {...field}
                    ref={null}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Item a seleccionar*</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Controller
                name="item"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CSelectForm
                    placeholder="Selecciona un item"
                    fullWidth
                    items={[
                      { value:'SLIM', label: "SLIM" },
                      { value:'DNA', label: "DNA" },
                      { value:'POLICIA', label: "POLICÍA" },
                      { value:'FELCV', label: "FELCV" },
                      { value:'JUZGADO', label: "JUZGADO" },
                      { value:'CENTROS_DE_SALUD', label: "CENTROS DE SALUD" },
                      { value:'MÉDICO_FORENSE_MUNICIPIO_COORDINA', label: "MÉDICO FORENSE / MUNICIPIO COORDINA" },
                      { value:'FISCALIA', label: "FISCALÍA" },
                      { value:'REFUGIO_TEMPORAL', label: "REFUGIO TEMPORAL" },
                      { value:'CASA_DE_ACOGIDA', label: "CASA DE ACOGIDA" }
                    ]}
                    {...field}
                    ref={null}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Dirección*</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Controller
                name="direccion"
                control={control}
                defaultValue=""
                rules={{
                  required: "El campo Dirección es requerido"
                }}
                render={({ field }) => (
                  <CInputForm
                    inputIcon={<RolIcon />}
                    type="text"
                    placeholder="Escribe la dirección"
                    {...field}
                    ref={null}
                    error={errors.direccion}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Número de contacto*</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Controller
                name="nroContacto"
                control={control}
                defaultValue=""
                rules={{
                  required: "El campo Número de contacto es requerido"
                }}
                render={({ field }) => (
                  <CInputForm
                    inputIcon={<RolIcon />}
                    type="text"
                    placeholder="Escribe el número de contacto"
                    {...field}
                    ref={null}
                    error={errors.nroContacto}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid> */}
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={"span"} sx={{ ...DesignSystem?.cuerpo2 }}>
                Gestión*
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Controller
                name="gestion"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <CSelectForm
                    placeholder="Selecciona la gestión de la cifra"
                    fullWidth
                    items={[
                      { value: "2023-LB", label: "2023 - Línea base" },
                      { value: "2023-M", label: "2023 - Monitoreo" },
                    ]}
                    {...field}
                    ref={null}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={"span"} sx={{ ...DesignSystem?.cuerpo2 }}>
                Archivo de cifras*
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <CFilePicker
                multiple={false}
                placeholder="Carga el archivo adjunto de cifras"
                handleChange={(ev: ChangeEvent<HTMLInputElement>) =>
                  handleChangePicker(ev)
                }
              />
              <Box>
                {adjuntos.map((adjunto: File, indiceAdjunto: number) => (
                  <Chip
                    deleteIcon={
                      <IconButton size="small" sx={{ stroke: colorsBase.Rojo }}>
                        <CancelRounded width="20" height="20" />
                      </IconButton>
                    }
                    onDelete={() => {
                      const _adj = [...adjuntos];
                      _adj.splice(indiceAdjunto, 1);
                      setAdjuntos(_adj);
                    }}
                    label={adjunto.name ? `${adjunto.name}` : ""}
                    key={indiceAdjunto}
                    sx={{
                      margin: 0.5,
                      border: `1px solid ${colorsBase.Verde3}`,
                    }}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            margin: 2,
            marginTop: 0,
            paddingTop: 1,
            borderTop: `1px solid ${colorsBase.BorderGray}`,
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <CancelButton onClick={onClose}>Cancelar</CancelButton>
            {/* <ConfirmButton
            form="form-noticia"
            onClick={handleSubmit(handleSubmitBorrador)}
            sx={{ ml: 2 }}
            loading={loading}
          >
            Guardar borrador
          </ConfirmButton>
          {/* <ConfirmButton disabled sx={{ ml: 2 }}>
            Vista previa
          </ConfirmButton> */}
            <ConfirmButton
              onClick={handleSubmit(handleSubmitGuardar)}
              sx={{ ml: 2 }}
              // loading={loading}
            >
              Guardar
            </ConfirmButton>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
