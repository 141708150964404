import { useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import listenIcon from "../../../../../../assets/GoodMunicipalPractices/icons/ico1_nomal.png";
import listenIconH from "../../../../../../assets/GoodMunicipalPractices/icons/ico1_hover.png";
import videoIcon from "../../../../../../assets/GoodMunicipalPractices/icons/ico4_nomal.png";
import videoIconH from "../../../../../../assets/GoodMunicipalPractices/icons/ico4_hover.png";
import infoIcon from "../../../../../../assets/GoodMunicipalPractices/icons/ico5_nomal.png";
import infoIconH from "../../../../../../assets/GoodMunicipalPractices/icons/ico5_hover.png";
import archIcon from "../../../../../../assets/GoodMunicipalPractices/icons/ico3_nomal.png";
import archIconH from "../../../../../../assets/GoodMunicipalPractices/icons/ico3_hover.png";
import ResourceItem from "./ResourceItem";
import theme from "../../../../../../themes";

const StyledBox = styled(Box)(() => ({
  margin: "1.5em 0 0 0",
  padding: "1.5em 1.5em 1.5em 1.5em",
  width: "100%",
  borderRadius: "8px",
  backgroundColor: '#a0a0a0',
}));

const ResourceSelector = ({ handleSetResourceType }: any) => {
  const [resource, setResource] = useState<string>("");

  const handleSetResource = (str: string) => {
    if (str === resource) {
      setResource("");
    } else {
      setResource(str);
    }
  };

  const handleSetFilterResource = (str: string) => {
    handleSetResourceType(str);
  };

  return (
    <StyledBox display="flex" flexDirection="column" justifyContent="center">
      <Box>
        <Typography
          sx={{
            fontFamily: "Barlow",
            fontSize: { xs: 20, lg: 22 },
            color: "#FFFFFF",
          }}
        >
          REALIZA UNA BÚSQUEDA POR TIPO DE RECURSO
        </Typography>
      </Box>
      <Box display="flex" sx={{ padding: "0.5em 0 0.5em 0" }}>
        <ResourceItem
          icon={listenIcon}
          hoverIcon={listenIconH}
          text="audios"
          tipoRecurso="SOUNDCLOUD"
          resource={resource}
          handleSetResource={handleSetResource}
          handleSetFilterResource={handleSetFilterResource}
        />
        <ResourceItem
          icon={videoIcon}
          hoverIcon={videoIconH}
          text="videos"
          tipoRecurso="YOUTUBE"
          resource={resource}
          handleSetResource={handleSetResource}
          handleSetFilterResource={handleSetFilterResource}
        />
      </Box>
      <Box display="flex" sx={{ padding: "0.5em 0 0.5em 0" }}>
        <ResourceItem
          icon={infoIcon}
          hoverIcon={infoIconH}
          text="fotos"
          tipoRecurso="LINK_FOTOS"
          resource={resource}
          handleSetResource={handleSetResource}
          handleSetFilterResource={handleSetFilterResource}
        />
        <ResourceItem
          icon={archIcon}
          hoverIcon={archIconH}
          text="archivos"
          tipoRecurso="LINK_ARCHIVO"
          resource={resource}
          handleSetResource={handleSetResource}
          handleSetFilterResource={handleSetFilterResource}
        />
      </Box>
    </StyledBox>
  );
};

export default ResourceSelector;
