import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import theme from "../../../../themes";
import { Box, Button, styled } from "@mui/material";
import bgGray from "../../../../assets/bg_timeline.png";
import TimelineTree from "../../../../components/TimelineTree";

const StyledBox = styled(Box)(() => ({
  margin: "3em 0 0 0",
  padding: "3em 0 6em 0",
  // position: 'relative',
  width: "100%",
  backgroundImage: `url(${bgGray})`,
  backgroundSize: 'contain',
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom",
  backgroundColor: "#e8e8e8",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  backgroundColor: theme.palette.primary.main,
  borderRadius: "8px",
  color: theme.palette.secondary.active,
  width: "200px",
  height: "50px",
  "&:hover": {
    backgroundColor: theme.palette.primary.hover,
    color: "#FFFFFF",
  },
  [theme.breakpoints.down("md")]: {
    width: "150px",
  },
}));

const HistoryTimeline = () => {
  let history = useHistory();

  return (
    <StyledBox display="flex" justifyContent="center" alignItems="center">
      <Box sx={{ width: { xs: "100%", md: "75%" }, height: "100%" }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ padding: "0 1.5em 0 1.5em", width: "100%" }}
        >
          <Box display="flex" pb={1}>
            <Typography
              variant="h1"
              sx={{
                fontFamily: "Barlow",
                fontSize: "22px",
                textTransform: 'uppercase',
                fontWeight: 400,
                color: theme.palette.neutral.hover,
              }}
              align="center"
              pt={5}
            >
              El camino recorrido
            </Typography>
          </Box>
          <Typography
            variant="h1"
            sx={{
              fontFamily: "Barlow",
              fontSize: "42px",
              color: theme.palette.neutral.hover,
            }}
            align="center"
          >
            Nuestra historia desde el inicio
          </Typography>

          <TimelineTree nodes={3} />

          <Box display="flex" justifyContent="center" py={2}>
            <StyledButton
              onClick={() => history.push("/quienes-somos#nuestraHistoria")}
            >
              Ver más
            </StyledButton>
          </Box>
        </Box>
      </Box>
      {/* <Box
        component={"img"}
        src={bgGray}
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          zIndex: 1,
        }}
      ></Box> */}
      {/* <Box
        sx={{
          height: ''
          position: 'absolute',
          backgroundImage: `url(${bgGray})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
        }}
      ></Box> */}
    </StyledBox>
  );
};

export default HistoryTimeline;
