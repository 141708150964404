import {alpha, Box, FormControl, MenuItem, Select, SelectProps, Typography} from "@mui/material";
import theme from "../../../themes";
import React, {ReactNode} from "react";


interface CSelectFormProps<T> extends Omit<SelectProps<T>, "error" | "items" | "placeholder"> {
  error?: {
    type?: string;
    message?: string;
  }
  items?: ItemProp[];
  placeholder?: string;
  inputIcon?: ReactNode;
}

interface ItemProp {
  value: string | number;
  label: string;
}

const CSelectForm = <T extends string | number | undefined>(props: CSelectFormProps<T>) => {
  const {
    error: _error, items: _items, placeholder: _placeholder,
    ...inputProps
  } = props;

  const truncateLabel = (label: string) => {
    if (window.innerWidth <= 768) {
      return label.length > 30 ? label.slice(0, 30) + "..." : label;
    }
    return label;
  };

  return (
    <FormControl fullWidth variant="standard">
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          backgroundColor: "#ECECEC",
          borderRadius: "8px",
          borderWidth: 0.5,
          borderStyle: "solid",
          borderColor: props.error ? theme.palette.error.main : "#ECECEC",
        }}
      >
        <Select
          // SelectDisplayProps={{ style: { ...DesignSystem.cuerpo3 }}}
          disableUnderline
          // defaultValue=""
          displayEmpty
          fullWidth
          sx={{
            px: 1,
            py: 1,
            pl: "10px",
          }}
          {...inputProps}
        >
          <MenuItem disabled value="">
            <em style={{ color: alpha(theme.palette.common.black, 0.7) }}>
              {props.placeholder ? props.placeholder : "Seleccione un ítem"}
            </em>
          </MenuItem>
          {props.items && props.items.length ? (
            props.items.map((item: ItemProp) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {truncateLabel(item.label)}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value="none" disabled>
              <em>No hay items disponibles</em>
            </MenuItem>
          )}
        </Select>
      </Box>
      {props.error && (
        <Typography
          component={"span"}
          sx={{
            textAlign: "left",
            fontSize: ".8rem",
            color: theme.palette.error.main,
          }}
        >
          {props.error && props.error.type && props.error.type === "required"
            ? "* "
            : ""}
          {props.error.message ? props.error.message : ""}
        </Typography>
      )}
    </FormControl>
  );
};

export default CSelectForm;
