import { Box, Grid, Typography, styled } from "@mui/material";
import { ResourceCard } from "./ResourceCard";
import { recursosData } from "../data";

// const StyledButton = styled(Button)(({ theme }) => ({
//   textTransform: "uppercase",
//   fontFamily: "Barlow",
//   fontSize: 14,
//   backgroundColor: theme.palette.primary.main,
//   borderRadius: "8px",
//   color: theme.palette.secondary.active,
//   "&:hover": {
//     backgroundColor: theme.palette.primary.hover,
//     color: theme.palette.common.white,
//   },
//   opacity: 1,
// }));

const StyledBox = styled(Box)(() => ({
  margin: "0 0 0 0",
  padding: 0,
  width: "100%",
  // backgroundColor: "#fcf8de",
}));

const StyledImg = styled("img")(() => ({
  maxWidth: "100%",
  height: "100%",
  objectFit: "contain",
}));

export const TipoBloque = {
  REDES_LUCHA: "REDES_LUCHA",
  MUJERES: "MUJERES",
  OTRAS_MASCULINIDADES: "OTRAS_MASCULINIDADES",
  EDUACTIVISTAS: "EDUACTIVISTAS",
};

export const ResourcesSection = () => {
  return (
    <StyledBox display="flex" justifyContent="center" id="campaign">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          margin: 0,
          padding: 0,
          pt: 5,
          px: 3,
          pb: 10,
        }}
      >
        <Typography
          variant="h4"
          textAlign={"center"}
          fontWeight={"bold"}
          color={"rgb(74,12,85,0.8)"}
        >
          OTROS RECURSOS DE COMUNICACIÓN
        </Typography>
        <Box my={2} />
        <Typography variant="body1" textAlign={"center"}>
          Materiales y productos para intervenir en diferentes espacios con
          diversos actores y aportar a la prevención de la violencia hacia las
          mujeres en tu municipio.
        </Typography>
        <Box my={7} />
        <Box>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <ResourceCard
                color="#48974d"
                titulo="Red de Lucha Contra la Violencia"
                descripcion={
                  "Redes municipales que trabajan de forma coordinada para brindar mejores servicios de prevención, atención y protección a las mujeres en situación o riesgo de violencia."
                }
                recursos={recursosData.filter(
                  (el) => el.bloque === TipoBloque.REDES_LUCHA
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ResourceCard
                color="#742394"
                titulo="Mujeres"
                descripcion={
                  "Mujeres fortalecidas trabajan por su autonomía, hablan de sus derechos y generan alianzas municipales desde sus propuestas de incidencia."
                }
                recursos={recursosData.filter(
                  (el) => el.bloque === TipoBloque.MUJERES
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ResourceCard
                color="#e19e35"
                titulo="Otras Masculinidades"
                descripcion={
                  "Reflexión y acción para la construcción de nuevas masculinidades que contribuyan a una vida digna y sin violencia."
                }
                recursos={recursosData.filter(
                  (el) => el.bloque === TipoBloque.OTRAS_MASCULINIDADES
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ResourceCard
                color="#5a9ec7"
                titulo="EduActivistas"
                descripcion={
                  "¡Jóvenes en acción por una vida libre de violencia! Trabajo activo de la Red Nacional de EduActivistas de universidades y de las y los jóvenes en municipios, que realizan acciones de prevención de la violencia hacia las mujeres."
                }
                recursos={recursosData.filter(
                  (el) => el.bloque === TipoBloque.EDUACTIVISTAS
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </StyledBox>
  );
};
