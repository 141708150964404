import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ArrowBackRounded } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import GeneralCourseData from "./components/GeneralCourseData";
import UnitsCourseContent from "./components/UnitsCourseContent";
import { useHistory, useLocation } from "react-router-dom";
import {
  InternUnit,
  extractAttachments,
  extractUnits,
  useCourseContext,
  withCourseContextProvider,
} from "./context";
import { useCreateCourse, useGetCourseById, useUpdateCourse } from "./data";
import { CursoModel, Unidad } from "./interface";
import { AdjuntosContenido } from "./components/DialogContenidoForm";
// import { showAlertSuccess } from "../../shared/hooks/useAlert";
import Layout from "../../components/Layout";
import { VistaPrevia } from "./components/VistaPrevia";
import { StyledCoursesCard } from "./CoursePage";
import { CourseNeutralButton } from "./shared/Elements";
import { Alerts } from "../../shared/hooks/useAlert";
import { printLog } from "../../shared/utils";
// import { useAlertModalContext } from "../../shared/context/modalAlertContext";

function CoursesDetail() {
  const theme = useTheme();
  let history = useHistory();
  let location = useLocation();
  const matchesPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const getCourse = useGetCourseById();
  const createCourse = useCreateCourse();
  const updateCourse = useUpdateCourse();

  /// Context de alertas
  // const alertContext = useAlertModalContext();
  const myState: any = location.state;
  printLog("myState.courseId >>> ", myState.courseId);
  const [course, dispatch] = useCourseContext();

  const fetchCourse = useCallback(async (courseId: string) => {
    const fetchedCourse = await getCourse(courseId);
    printLog("Course fetched :: ", fetchedCourse);
    dispatch({ type: "LOAD_CURSO", curso: fetchedCourse });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myState.courseId) {
      fetchCourse(myState.courseId);
    }
  }, [fetchCourse, myState.courseId]);

  const [tabValue, setTabValue] = useState<number>(0);
  const handleChange = (newTabValue: number) => {
    setTabValue(newTabValue);
  };

  // const previewCourse = () => {
  //   printLog("Vista previa del curso...");
  // };

  const handleCloseDetailCourse = () => {
    history.goBack();
  };

  const createOrUpdateCurso = async (unidades: InternUnit[]) => {
    printLog("Datos curso :: ", course);
    printLog("Unidades Internas :: ", unidades);
    // Convertir del modelo de unidades interno al modelo que necesita la API
    // unidades[n].contenido: { instancia: {...}, adjuntos: {...} } ==> unidades[n].contenido: { ...instancia }
    const unidadesCurso: Unidad[] = extractUnits(unidades);
    printLog(" >>> unidades para mandar por la API");
    // Recolectar los adjuntos de todos los contenidos en un solo objecto
    const adjuntosCurso: AdjuntosContenido = extractAttachments(unidades);
    printLog(" >>> adjuntos del contenido del curso :: ", adjuntosCurso);

    const courseData: CursoModel = {
      ...course,
      unidades: unidadesCurso,
    };

    if (courseData.id) {
      printLog("actualizar curso :: ");
      const resultUpdate = await updateCourse(
        courseData,
        courseData.id,
        adjuntosCurso
      );
      if (resultUpdate) {
        Alerts.showSuccess({
          // title: "¡Curso Actualizado!",
          description: "El curso fue actualizado exitosamente.",
        })
        // await alertContext.showSuccessAlert({
        //   btnText: "Aceptar",
        //   // title: "¡Curso Actualizado!",
        //   message: "El curso fue actualizado exitosamente.",
        // })
        // showAlertSuccess({
        //   btnText: "Aceptar",
        //   title: "¡Curso Actualizado!",
        //   message: "El curso fue actualizado exitosamente.",
        // });
        handleCloseDetailCourse();
      }
    } else {
      printLog("crear curso :: ");
      const resultCreate = await createCourse(courseData, adjuntosCurso);
      if (resultCreate) {
        Alerts.showSuccess({
          // title: "¡Curso Creado!",
          description: "El curso fue creado exitosamente.",
        })
        // await alertContext.showSuccessAlert({
        //   btnText: "Aceptar",
        //   // title: "¡Curso Creado!",
        //   message: "El curso fue creado exitosamente.",
        // })
        // showAlertSuccess({
        //   btnText: "Aceptar",
        //   title: "¡Curso Creado!",
        //   message: "El curso fue creado exitosamente.",
        // });
        handleCloseDetailCourse();
      }
    }
  };

  const [vistaPrevia, setVistaPrevia] = useState(false);

  const handleOpenVistaPrevia = () => {
    setVistaPrevia(true);
  };

  const handleCloseVistaPrevia = () => {
    setVistaPrevia(false);
  };

  return (
    <React.Fragment>
      <StyledCoursesCard>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          alignContent="center"
          sx={{
            borderBottom: "1px solid #cacaca",
            paddingX: "2rem",
            paddingY: "1rem",
          }}
        >
          <Typography
            sx={{
              flex: 1,
              fontSize: matchesPhone ? 12 : 18,
              fontWeight: 500,
            }}
          >
            {myState.cursoId !== "none"
              ? `Curso ${course ? course.nombre : ""}`
              : "Creación de nuevo curso"}
          </Typography>
          <CourseNeutralButton onClick={handleCloseDetailCourse}>
            <ArrowBackRounded />
          </CourseNeutralButton>
          <CourseNeutralButton onClick={handleOpenVistaPrevia}>
            Vista previa
          </CourseNeutralButton>
        </Box>
        <Box></Box>
        <Box sx={{ paddingX: "2rem" }}>
          <Box display="flex">
            <Box
              display={"flex"}
              gap={1}
              alignItems={"center"}
              sx={{ cursor: "initial" }}
              onClick={() => handleChange(0)}
            >
              <Box
                sx={{
                  background:
                    tabValue === 0
                      ? "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)"
                      : "initial",
                  border:
                    tabValue !== 0
                      ? `.5px solid ${theme.palette.neutral.main}`
                      : "none",
                  padding: ".6rem .8rem",
                  borderRadius: "10px",
                  color: tabValue === 0 ? "white" : theme.palette.neutral.main,
                  fontSize: "14pt",
                }}
              >
                01
              </Box>
              <Typography sx={{ fontWeight: 500, color: tabValue === 0 ? theme.palette.info.main : theme.palette.neutral.main }}>
                DATOS GENERALES DEL CURSO
              </Typography>
            </Box>
            <Box
              display={"flex"}
              gap={1}
              alignItems={"center"}
              sx={{ p: 2, cursor: "initial" }}
              onClick={() => handleChange(1)}
            >
              <Box
                sx={{
                  background:
                    tabValue === 1
                      ? "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)"
                      : "initial",
                  border:
                    tabValue !== 1
                      ? `.5px solid ${theme.palette.neutral.main}`
                      : "none",
                  padding: ".6rem .8rem",
                  borderRadius: "10px",
                  color: tabValue === 1 ? "white" : theme.palette.neutral.main,
                  fontSize: "14pt",
                }}
              >
                02
              </Box>
              <Typography sx={{ fontWeight: 500, color: tabValue === 1 ? theme.palette.info.main : theme.palette.neutral.main }}>
                CONTENIDOS
              </Typography>
            </Box>
          </Box>
          {tabValue === 0 && (
            <GeneralCourseData
              onCancel={handleCloseDetailCourse}
              onNextStep={() => setTabValue(1)}
            />
          )}
          {tabValue === 1 && (
            <UnitsCourseContent
              onBack={() => setTabValue(0)}
              onClose={handleCloseDetailCourse}
              onCreateOrUpdateCurso={createOrUpdateCurso}
            />
          )}
        </Box>
      </StyledCoursesCard>
      <Box mt={8}>&nbsp;</Box>
      <VistaPrevia
        open={vistaPrevia}
        plantillaCursoInterna={course}
        onClose={handleCloseVistaPrevia}
      />
    </React.Fragment>
  );
}
// <Layout bgColor="#e0e1e0"></Layout>

export default withCourseContextProvider(CoursesDetail);
