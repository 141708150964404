import {Close} from "@mui/icons-material"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  SelectChangeEvent,
  Typography
} from "@mui/material"
// import CInputForm from "../../../common/components/CInputForm"
import CInputForm from "../../../shared/components/form/CInputForm";
// import { colorsBase, DesignSystem } from "../../../common/theme"
import {colorsBase, DesignSystem} from "../../../themes/theme";

import {ReactComponent as MailIcon} from "../../../assets/icons/mail.svg";
import {ReactComponent as RolIcon} from "../../../assets/icons/rol.svg";
import {ReactComponent as TelephoneIcon} from "../../../assets/icons/telephone.svg";
import {ReactComponent as UserIcon} from "../../../assets/icons/user.svg";
import {ReactComponent as EyeIcon} from "../../../assets/icons/eye.svg";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
// import CSelectForm from "../../../common/components/forms/CSelectForm";
import CSelectForm from "../../../shared/components/form/CSelectForm";
// import { getArrayKeyValues, getArrayKeyValuesFromObject } from "../../../common/utils";
import {getArrayKeyValues} from "../../../shared/utils";
// import CDatePicker from "../../../common/components/forms/CDatePicker";
import CDatePicker from "../../../shared/components/form/CDatePicker";
import {CancelButton, ConfirmButton} from "../../../shared/components/CButton";
import {UsuarioDetalles} from "../interfaces/UsuarioInterface";
import {useEffect, useState} from "react";
import useHttp from "../../../shared/hooks/useHttp";

type UsuarioFormProps = {
  usuario: any; userDialog: boolean; usuariosData: any; onClose: () => void; onCreateOrUpdate: (data: any) => void;
}

export const UsuarioForm = ({
                              usuario, userDialog, usuariosData, onClose, onCreateOrUpdate,
                            }: UsuarioFormProps) => {

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: {errors},
    getValues,


  } = useForm<UsuarioDetalles>();
  const [loading, setLoading] = useState(false);
  const http = useHttp();

  const onSubmit: SubmitHandler<any> = async (data) => {
    setLoading(true);
    onCreateOrUpdate(data);
    // setLoading(false);
  };

  const rol = watch("rol");

  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);

  useEffect(() => {
    obtenerDepartamentosMunicipios().finally();
  }, []);

  useEffect(() => {
    if (usuario) {
      reset(usuario);
    } else {
      reset({}, {keepValues: false}); // reset modal usuarios
    }
  }, [departamentos]);

  const obtenerDepartamentosMunicipios = async () => {
    const response: any = await http.get('/municipio/porDepartamento?esMunicipioVdsv=true', {});
    if (response) {
      setDepartamentos(response.departamentos);
      if (usuario) {
        const _depto: any = response.departamentos.find((item: any) => item.departamento_cod === getValues().departamento_cod);
        setMunicipios(_depto.municipios);
        //debugger;
        reset(usuario)
      }
    }
  }

  return <Dialog
    open={userDialog}
    fullWidth
    maxWidth="md"
    onClose={onClose}
  >
    <DialogTitle>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{borderBottom: `1px solid ${colorsBase.BorderGray}`}}
      >
        <Typography component={"span"} sx={{...DesignSystem?.titulo1}}>
          {usuario ? "Edición de usuario" : "Creación de nuevo usuario"}
        </Typography>
        <IconButton onClick={onClose}>
          <Close/>
        </IconButton>
      </Box>
    </DialogTitle>
    <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <Box>
          <Box
            sx={{
              borderBottom: `1px solid ${colorsBase.BorderGray}`, pb: 3,
            }}
          >
            <Grid container spacing={3}>
              <Grid item container xs={12} md={6} order={{xs: 1, md: 1}}>
                <Grid item xs={12} md={3}>
                  <Typography
                    component={"span"}
                    sx={{...DesignSystem?.cuerpo2}}
                  >
                    Correo electrónico*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "El campo Correo electrónico es requerido",
                    }}
                    render={({field}) => (<CInputForm
                      inputIcon={<MailIcon/>}
                      autoComplete="none"
                      type="email"
                      placeholder="Ingresa el correo electrónico"
                      {...field}
                      ref={null}
                      error={errors.email}
                    />)}
                  />
                </Grid>
              </Grid>
              {!usuario && (<Grid item container xs={12} md={6} order={{xs: 2, md: 2}}>
                <Grid item xs={12} md={3}>
                  <Typography
                    component={"span"}
                    sx={{...DesignSystem?.cuerpo2}}
                  >
                    Contraseña*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "El campo Contraseña es requerido",
                      minLength: {value: 6, message: 'El campo Contraseña requiere un mínimo de 6 caractéres'}
                    }}
                    render={({field}) => (<CInputForm
                      inputIcon={<EyeIcon/>}
                      autoComplete="new-password"
                      type="password"
                      placeholder="Contraseña"
                      {...field}
                      ref={null}
                      error={errors.password}
                    />)}
                  />
                </Grid>
              </Grid>)}
              <Grid item container xs={12} md={6} order={{xs: 4, md: 3}}>
                <Grid item xs={12} md={3}>
                  <Typography
                    component={"span"}
                    sx={{...DesignSystem?.cuerpo2, flex: 1}}
                  >
                    Rol*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Controller
                    name="rol"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "El campo Rol es requerido",
                    }}
                    render={({field}) => (<CSelectForm
                      inputIcon={<RolIcon/>}
                      placeholder="Selecciona un rol"
                      items={[...getArrayKeyValues(usuariosData?.roles ? usuariosData?.roles : []), {
                        value: "PARTICIPANTE", label: "Participante"
                      }, {value: "MONITOR", label: "Monitor"},]}
                      disabled={!!usuario}
                      {...field}
                      ref={null}
                      error={errors.rol}
                    />)}
                  />
                </Grid>
              </Grid>
              {!usuario && (<Grid item container xs={12} md={6} order={{xs: 3, md: 4}}>
                <Grid item xs={12} md={3}>
                  <Typography
                    component={"span"}
                    sx={{...DesignSystem?.cuerpo2}}
                  >
                    Confirma contraseña*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Controller
                    name="passwordConfirm"
                    control={control}
                    defaultValue=""
                    rules={{
                      validate: (value) => value === getValues("password") || "La contraseña debe coincidir", // required: "El campo Contraseña es requerido"
                    }}
                    render={({field}) => (<CInputForm
                      inputIcon={<EyeIcon/>}
                      type="password"
                      placeholder="Confirma la contraseña"
                      {...field}
                      ref={null}
                      error={errors.passwordConfirm}
                    />)}
                  />
                </Grid>
              </Grid>)}
            </Grid>
          </Box>
          <Box pt={3}>
            <Grid container spacing={3}>
              <Grid item container xs={12} md={12} order={{xs: 1, md: 1}}>
                <Grid item xs={12} md={2}>
                  <Typography
                    component={"span"}
                    sx={{...DesignSystem?.cuerpo2}}
                  >
                    Nombres*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Controller
                    name="names"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "El campo Nombres es requerido",
                    }}
                    render={({field}) => (<CInputForm
                      inputIcon={<UserIcon/>}
                      type="text"
                      placeholder="Ingresa los nombres"
                      {...field}
                      ref={null}
                      error={errors.names}
                    />)}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={12} md={6} order={{xs: 1, md: 1}}>
                <Grid item xs={12} md={4}>
                  <Typography
                    component={"span"}
                    sx={{...DesignSystem?.cuerpo2}}
                  >
                    Primer Apellido*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="lastName1"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "El campo Primer Apellido es requerido",
                    }}
                    render={({field}) => (<CInputForm
                      inputIcon={<UserIcon/>}
                      type="text"
                      placeholder="Ingresa el primer apellido"
                      {...field}
                      ref={null}
                      error={errors.lastName1}
                    />)}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={12} md={6} order={{xs: 1, md: 1}}>
                <Grid item xs={12} md={4}>
                  <Typography
                    component={"span"}
                    sx={{...DesignSystem?.cuerpo2}}
                  >
                    Segundo Apellido
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="lastName2"
                    control={control}
                    defaultValue=""
                    rules={{}}
                    render={({field}) => (<CInputForm
                      inputIcon={<UserIcon/>}
                      type="text"
                      placeholder="Ingresa el Segundo Apellido"
                      {...field}
                      ref={null}
                      error={errors.lastName2}
                    />)}
                  />
                </Grid>
              </Grid>

              {rol === "PARTICIPANTE" && (<>
                <Grid item container xs={12} md={6} order={{xs: 7, md: 6}}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      component={"span"}
                      sx={{...DesignSystem?.cuerpo2}}
                    >
                      Fecha de nacimiento*
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Controller
                      name="birthDate"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "El campo Fecha de nacimiento es requerido",
                      }}
                      render={({field}) => (<CDatePicker
                        // ref={null}
                        placeholder="Ingresa la fecha de nacimiento"
                        {...field}
                        error={errors.birthDate}
                      />)}
                    />
                  </Grid>
                </Grid>

                <Grid item container xs={12} md={6} order={{xs: 5, md: 9}}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      component={"span"}
                      sx={{...DesignSystem?.cuerpo2}}
                    >
                      Teléfono*
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "El campo Telefono es requerido",
                      }}
                      render={({field}) => (<CInputForm
                        inputIcon={<TelephoneIcon/>}
                        type="text"
                        placeholder="Ingresa el teléfono"
                        {...field}
                        ref={null}
                        error={errors.phoneNumber}
                      />)}
                    />
                  </Grid>
                </Grid>

                <Grid item container xs={12} md={6} order={{xs: 5, md: 11}}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      component={"span"}
                      sx={{...DesignSystem?.cuerpo2}}
                    >
                      Departamento*
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Controller
                      name="departamento_cod"
                      control={control}
                      defaultValue=""
                      render={({field}) => (<CSelectForm
                        placeholder="Seleccione el Departamento"
                        fullWidth
                        items={departamentos.map((item: any) => ({
                          value: item.departamento_cod, label: item.nombre,
                        }))}
                        {...field}
                        ref={null}
                        onChange={(selectedValue: SelectChangeEvent) => {
                          field.onChange(selectedValue);
                          const _depto: any = departamentos.find((item: any) => item.departamento_cod === selectedValue.target.value)
                          setMunicipios(_depto.municipios);
                        }}
                        required
                      />)}
                    />
                  </Grid>
                </Grid>

                <Grid item container xs={12} md={6} order={{xs: 5, md: 12}}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      component={"span"}
                      sx={{...DesignSystem?.cuerpo2}}
                    >
                      Municipio*
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Controller
                      name="municipio_cod"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "El campo de Municipio es requerido",
                      }}
                      render={({field}) => (<CSelectForm
                        placeholder="Seleccione el Municipio"
                        fullWidth
                        items={municipios.map((item: any) => ({
                          value: item.municipio_cod, label: item.municipio_desc,
                        }))}
                        {...field}
                        ref={null}
                        error={errors.municipio_cod}
                        required
                      />)}
                    />
                  </Grid>
                </Grid>

                <Grid item container xs={12} md={6} order={{xs: 5, md: 13}}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      component={"span"}
                      sx={{...DesignSystem?.cuerpo2}}
                    >
                      Institución*
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Controller
                      name="institution"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "El campo institución es requerido",
                      }}
                      render={({field}) => (<CInputForm
                        inputIcon={<UserIcon/>}
                        autoComplete="none"
                        type="institution"
                        placeholder="Ingrese su Institución. "
                        {...field}
                        ref={null}
                        error={errors.institution}
                      />)}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} md={6} order={{xs: 5, md: 14}}>
                  <Grid item xs={12} md={4}>
                    <Typography
                      component={"span"}
                      sx={{...DesignSystem?.cuerpo2}}
                    >
                      Posición*
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Controller
                      name="position"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "El campo posición es requerido",
                      }}
                      render={({field}) => (<CInputForm
                        inputIcon={<UserIcon/>}
                        autoComplete="none"
                        type="position"
                        placeholder="Ingrese su posición. "
                        {...field}
                        ref={null}
                        error={errors.position}
                      />)}
                    />
                  </Grid>
                </Grid>
              </>)}
            </Grid>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          margin: 2, marginTop: 0, paddingTop: 1, borderTop: `1px solid ${colorsBase.BorderGray}`,
        }}
      >
        <Box display="flex" justifyContent="flex-end">
          <CancelButton onClick={onClose}>
            Cancelar
          </CancelButton>
          <ConfirmButton
            loading={loading}
            type="submit"
            sx={{ml: 2}}
          >
            {usuario ? "Guardar" : "Crear"}
          </ConfirmButton>
        </Box>
      </DialogActions>
    </Box>
  </Dialog>
}
