import { createTheme } from "@mui/material/styles";
import { esES } from "@mui/material/locale";
import "typeface-barlow";
import "typeface-ubuntu";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    courseSecondary: Palette["primary"];
  }

  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
    courseSecondary: PaletteOptions["primary"];
  }

  interface PaletteColor {
    hover?: string;
    active?: string;
  }

  interface SimplePaletteColorOptions {
    hover?: string;
    active?: string;
  }
}

const theme = createTheme(
  {
    typography: {
      fontFamily: ["Barlow", "Ubuntu", "Arial"].join(","),
    },
    palette: {
      primary: {
        hover: "#dd8508",
        main: "#e6ac0c",
        active: "#bc8006",
      },
      secondary: {
        hover: "#801283",
        main: "#b120b4",
        active: "#4A0C55",
      },
      success: {
        hover: "#00864c",
        main: "#00c280",
        active: "#007c3c",
      },
      info: {
        hover: "#3785c4",
        main: "#5baad2",
        active: "#2a7694",
      },
      neutral: {
        hover: "#898686",
        main: "#b4b4b4",
        active: "#4d4d4d",
      },
      courseSecondary: {
        main: "#7F1389",
      },
    },
  },
  esES
);

export default theme;
