import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { colorsBase, DesignSystem } from '../../../themes/theme';
import { NoticiaDetalle, Adjunto } from '../NoticiaInterface';
import dayjs from 'dayjs'
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { ActionButton, FilterButton } from '../../../shared/components/CButton';
import { convertFromRaw, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';
import { useEditNoticia, useGetEnlaceById, useGetMedia, useGetNoticiaById } from '../NoticiaClient';
import { ReactComponent as KeyboardBackspace } from '../../../assets/icons/keyboard-backspace.svg';
import { ReactComponent as FilePdfIcon } from '../../../assets/icons/filePdf.svg';
import { FormNoticiaComponent } from './FormNoticiaComponent';
import { Alerts } from '../../../shared/hooks/useAlert';
import { useAuthContext } from '../../../shared/auth/AuthContext';
import { SystemRoles } from '../../../shared/common/constants';
import { printLog, isJsonString } from '../../../shared/utils';


export default function NoticiaOverview() {
  const theme = useTheme();
  const { state }: any = useLocation();
  const { user } = useAuthContext();

  const getMedia = useGetMedia();

  const [media, setMedia] = useState([]);

  const fetchMedia = useCallback(async () => {
    try {
      const mediaData = await getMedia();
      let mediaForSelect = mediaData.medios.map((item:any, index:any) => {
        return {
          value: item.medio_cod,
          label: item.medio_nombre
        };
      });
      setMedia(mediaForSelect as unknown as []);
    } catch (error) {
      printLog("Ocurrió un error al obtener medios :: ", error);
      setMedia([]);
    }
  }, []);


  const params = useParams()
  printLog('params >>> ', params)
  printLog('state >>> ', state)
  const { noticiaId, esEnlaceDeInteres } = state || params;

  printLog('[NoticiaOverview.tsx] noticia id :: ', noticiaId);

  const navigate = useHistory();
  
  if (!noticiaId) {
    printLog(' no hay noticia id :: ')
    navigate.push('/admin/noticia');
  }

  const [noticia, setNoticia] = useState<NoticiaDetalle>();
  const [noticiaDialog, setNoticiaDialog] = useState<boolean>(false);
  const [convertedContent, setConvertedContent] = useState<any>();

  // From noticia client
  const getNoticia = useGetNoticiaById();
  const getEnlace = useGetEnlaceById();
  const editNoticia = useEditNoticia();

  async function fetchNoticia(noticiaId: string) {
    try {
      const fetchedNoticia = await getNoticia( noticiaId );
      printLog('Noticia fetcheada :: ', fetchedNoticia)
      debugger;
      if (fetchedNoticia) {
        if (isJsonString(fetchedNoticia.contenido) && JSON.parse(fetchedNoticia.contenido)) {
          const parseado = JSON.parse(fetchedNoticia.contenido);
          const editorContent = EditorState.createWithContent(convertFromRaw(parseado))
          const rawContentState = convertToRaw(editorContent.getCurrentContent());
          let markup = draftToHtml(rawContentState);
          markup = markup.replace(/(width\s*:\s*auto)/ig, "width: 100%");
          setConvertedContent(markup)
        } else {
          setConvertedContent('<p>Sin contenido</p>')
        }
      }
      setNoticia(fetchedNoticia);
    } catch (error) {
      printLog('😾 no hay respuesta!!!!!');
      navigate.push('/admin/noticia');
    }
  }

  async function fetchEnlace(noticiaId: string) {
    try {
      const fetchedEnlace = await getEnlace( noticiaId );
      printLog('Enlace fetcheada :: ', fetchedEnlace)
      debugger;
      
      setNoticia(fetchedEnlace);
    } catch (error) {
      printLog('😾 no hay respuesta!!!!!');
      navigate.push('/admin/noticia');
    }
  }


  useEffect(() => {
    fetchMedia();
    if(esEnlaceDeInteres)
      fetchEnlace(noticiaId);
    else
      fetchNoticia(noticiaId);
  }, [setNoticia]);


  const createMarkup = (html: any) => {
    return  {
      __html: DOMPurify.sanitize(html)
    }
  }

  const descargarAdjunto = (adjunto: Adjunto) => {
    printLog('Descargar adjunto :: ', adjunto.url);
    const link = document.createElement('a');
    link.href = adjunto.url;
    link.download = adjunto.nombre;
    link.target = '_blank';
    link.click();
  }

  const handleOpenNoticia = () => {
    setNoticiaDialog(true);
  }

  const handleCloseNoticia = () => {
    setNoticiaDialog(false);
  }

  const onEditNoticia = async (data: any) => {
    printLog('Editando la noticia ::: ', data);
    const resultado = await editNoticia(data, noticiaId);

    if (resultado) {
      debugger;
      if(data.tipo === 'NOTICIA')
        await fetchNoticia(noticiaId);
      else
        await fetchEnlace(noticiaId);

      handleCloseNoticia();
      
      if(data.tipo === 'NOTICIA')
        Alerts.showSuccess({
          confirmText: 'Aceptar',
          description: '¡La noticia fue actualizada exitosamente!'
        })
      else
        Alerts.showSuccess({
          confirmText: 'Aceptar',
          description: '¡El enlace de interés fue actualizado exitosamente!'
        })
    }
  }

  return <>
    <Box display="flex" justifyContent="space-between" alignItems="center" alignContent="center" mb={6}>
      <Box display="flex" alignItems="center">
        {/* <IconButton size="small" onClick={() => navigate(-1)}> */}
        <IconButton size="small" onClick={() => navigate.push('/admin/noticia')}>
          <KeyboardBackspace />
        </IconButton>
        <Typography sx={{ ...DesignSystem?.titulo1, pl: 2 }}>
          Atrás
        </Typography>
      </Box>
      { user?.rol !== SystemRoles.PARTICIPANTE && <Box>
        <ActionButton onClick={handleOpenNoticia}>
          Editar noticia
        </ActionButton>
      </Box> }
    </Box>
    { noticia && <Box sx={{
      background: "rgba(255, 255, 255, 0.5)",
      boxShadow: "1.5px 2.6px 10px rgba(119, 119, 119, 0.1)",
      boxSizing: "border-box",
      borderRadius: "8px",
      p: 4,
      marginRight: 5,
      marginLeft: 5,
      [theme.breakpoints.down('md')]: {
        p: 3,
        marginRight: 3,
        marginLeft: 3,
      },
      [theme.breakpoints.down('sm')]: {
        p: 2,
        marginRight: 0,
        marginLeft: 0,
      },
    }}>
      <Typography component='p' sx={{ ...DesignSystem?.titulo1 }}>
        { noticia.titulo }
      </Typography>
      <Typography component='p' sx={{ ...DesignSystem?.cuerpo2, marginTop: 2, marginBottom: 2 }}>
        Fecha de publicación: { dayjs(noticia.fechaPublicacion).format('DD/MM/YYYY') }
      </Typography>
      { noticia.contenido && convertedContent && (
        <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>
      )}

      { noticia.adjuntos && noticia.adjuntos.length > 0 && <Box>
        <Typography component='p' sx={{ ...DesignSystem?.titulo3, py: 3 }}>
          Este documento tiene archivos adjuntos disponibles para su descarga
        </Typography>
        { noticia.adjuntos!.map((adjunto: Adjunto, idxNoticia: number) => (
          <FilterButton
            key={idxNoticia}
            sx={{ mx: 1, color: colorsBase.Naranja1 }}
            onClick={() => descargarAdjunto(adjunto)}
          >
            <FilePdfIcon style={{ fill: colorsBase.Naranja1, marginRight: 8 }} />
            { adjunto.nombre }
          </FilterButton>
        )) }
      </Box>}
    </Box>}
    { noticiaDialog && (
      <FormNoticiaComponent
        noticia={noticia}
        noticiaDialog={noticiaDialog}
        onClose={handleCloseNoticia}
        onSubmitNoticia={onEditNoticia}
        loading={false}
        media={media}
        esEnlaceDeInteres={esEnlaceDeInteres}
      />
    )}
  </>
}
