import Layout from "../../../components/Layout";
import StudentCoursePage from "./StudentCoursePage";
import { Box } from "@mui/material";

function StudentCourses() {
  return (
    <>
      {/* <Layout bgColor="#e0e1e0"> */}
      <Layout bgColor="custom">
        <Box pt={4}>
          <StudentCoursePage />
        </Box>
      </Layout>
      {/* <Footer /> */}
    </>
  );
}

export default StudentCourses;
