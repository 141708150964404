import {Close} from "@mui/icons-material";
import {
  Box,
  Card,
  CardMedia,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
// import CInputForm from "../../../common/components/CInputForm";
import CInputForm from "../../../shared/components/form/CInputForm";
// import { colorsBase, DesignSystem } from "../../../common/theme";
import {colorsBase, DesignSystem} from "../../../themes/theme";
// import { CFilePicker } from "../../../common/components/forms/CFilePicker";
import {CFilePicker} from "../../../shared/components/form/CFilePicker";
import {Adjunto, IIniciativaForm, IniciativaDetalle} from '../IniciativaInterface';
import {ChangeEvent, useEffect, useState} from "react";
// import { CancelButton, ConfirmButton } from "../../../common/components/CButton";
import {CancelButton, ConfirmButton} from "../../../shared/components/CButton";

import {ReactComponent as RolIcon} from '../../../assets/icons/rol.svg'
import {ReactComponent as CancelCircleIcon} from '../../../assets/icons/cancelCircle.svg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import useHttp from "../../../common/useHttp";
// import { useAuthContext } from "../../../common/auth/AuthContext";
import useHttp from "../../../shared/hooks/useHttp";
import {useAuthContext} from "../../../shared/auth/AuthContext";
import CSelectForm from "../../../shared/components/form/CSelectForm";
import {printLog} from "../../../shared/utils";

export type ExperienciaPracticaPayload = IIniciativaForm & {
  archivos: File[];
  adjuntosEliminados: Adjunto[];
}

type FormIniciativaProps = {
  iniciativa?: IniciativaDetalle,
  noticiaDialog: boolean,
  onClose: () => void,
  onSubmitIniciativa: (data: ExperienciaPracticaPayload) => void,
  loading: boolean,
  stockImages: string[],
  isPractice: boolean
}


export const FormIniciativaComponent = ({
  iniciativa,
  noticiaDialog,
  onClose,
  onSubmitIniciativa,
  loading,
  stockImages,
  isPractice
}: FormIniciativaProps) => {
  console.log('stock')
  console.log(stockImages)
  console.log(isPractice)
  const [adjuntos, setAdjuntos] = useState<File[]>([]);
  const [currentAdjuntos, setCurrentAdjuntos] = useState<Adjunto[]>();
  const [deletedAdjuntos, setDeletedAdjuntos] = useState<Adjunto[]>([]);

  const { handleSubmit, control, reset, formState: { errors }, getValues, watch, setValue } = useForm<IIniciativaForm>();
  const http = useHttp();
  const authContext = useAuthContext();
  useEffect(() => {debugger;
    if ( iniciativa ) {
      reset({
        nombre: iniciativa.nombre,
        celular: iniciativa.celular,
        fondo: iniciativa.fondo,
        titulo: iniciativa.titulo,
        descripcion: iniciativa.descripcion,
        //TODO revisar con Carlos
        departamento_cod: iniciativa.departamento_cod,
        // municipio_cod: iniciativa.municipio_cod,
        gestion: iniciativa.gestion,
        destacada: iniciativa.destacada,
        tipoRecurso: iniciativa.tipoRecurso,
        recurso: iniciativa.recurso,
        estado: iniciativa.estado,
        stockImageUrl: iniciativa.stockImageUrl,
      })

      setValue('departamento_cod', iniciativa.departamento_cod);

      
      setValue('municipio_cod', iniciativa.municipio_cod);
      if(iniciativa.fondo === 'FONDO_BUENAS_PRACTICAS')
        setValue('bloque', iniciativa.bloque);

      setCurrentAdjuntos(iniciativa.adjuntos);
    }
    else {
      if(isPractice)
        setValue('fondo', 'FONDO_BUENAS_PRACTICAS');
      else
        setValue('fondo', 'FONDO_EXPERIENCIAS_INNOVADORAS');
    }
  }, []);

  const fondoSelection = watch('fondo');

  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);

  useEffect(() => {
    obtenerDepartamentosMunicipios().finally();
  }, []);

  const obtenerDepartamentosMunicipios = async () => {
    const response: any = await http.get('/municipio/porDepartamento?esMunicipioVdsv=true', {});
    if (response) {
      setDepartamentos(response.departamentos);
      if(iniciativa){
        const _depto: any = response.departamentos.find((item: any) => item.departamento_cod ===iniciativa.departamento_cod)
        setMunicipios(_depto.municipios);
      }
    }
  }

  

  const handleChangePicker = ({ target }: ChangeEvent<HTMLInputElement>) => {
    // printLog('[NoticiaPage] >  Archivos seleccionados de FilePicker :: target.files ', target.files);
    if (target.files && target.files.length) {
      printLog('[NoticiaPage] >  Archivos seleccionados de FilePicker :: target.files[0]', target.files![0]);
      // if (multiple)
      setAdjuntos([
        ...adjuntos,
        ...target.files
      ]);
    }
  }

  const handleSubmitPublicar: SubmitHandler<IIniciativaForm> = async (data) => {
    printLog('PUBLICAR iniciativa :: ', data);
    printLog('Adjuntos de la iniciativa :: ', adjuntos);

    const datos: any = {
      nombre: data.nombre,
      celular: data.celular,
      fondo: data.fondo,
      bloque: data.bloque,
      titulo: data.titulo,
      descripcion: data.descripcion,
      departamento_cod: data.departamento_cod,
      municipio_cod: data.municipio_cod,
      gestion: data.gestion,
      destacada: data.destacada ? true  : false,
      tipoRecurso: data.tipoRecurso,
      recurso: data.recurso,
      adjunto: adjuntos,
      publicada: true,
      adjuntosEliminados: deletedAdjuntos,
      stockImageUrl: data.stockImageUrl,
      estado: 'PUBLICADO'
    }
    
      debugger;
    onSubmitIniciativa(datos);
    
  }
  const handleSubmitBorrador: SubmitHandler<IIniciativaForm> = async (data) => {
    printLog('BORRADOR noticia :: ', data);
    printLog('Adjuntos de la noticia :: ', adjuntos);
    debugger;
    if(iniciativa) {
      
      const datos: any = {
        nombre: data.nombre,
        celular: data.celular,
        fondo: data.fondo,
        bloque: data.bloque,
        titulo: data.titulo,
        descripcion: data.descripcion,
        departamento_cod: data.departamento_cod,
        municipio_cod: data.municipio_cod,
        gestion: data.gestion,
        destacada: data.destacada ? true  : false,
        tipoRecurso: data.tipoRecurso,
        recurso: data.recurso,
        adjunto: adjuntos,
        publicada: false,
        adjuntosEliminados: deletedAdjuntos,
        stockImageUrl: data.stockImageUrl,
        estado: 'BORRADOR'
      }

      onSubmitIniciativa(datos);

    } else {

      const datos: any = {
        fondo: data.fondo,
        bloque: data.bloque,
        titulo: data.titulo,
        descripcion: data.descripcion,
        departamento_cod: data.departamento_cod,
        municipio_cod: data.municipio_cod,
        gestion: data.gestion,
        destacada: data.destacada ? true  : false,
        tipoRecurso: data.tipoRecurso,
        recurso: data.recurso,
        adjunto: adjuntos,
        publicada: false,
        adjuntosEliminados: deletedAdjuntos,
        stockImageUrl: data.stockImageUrl, 
        estado: 'BORRADOR'
      }

      onSubmitIniciativa(datos);
      
    }
  };

  const handleCloseDialogNoticia = () => {
    setAdjuntos([]);
    reset();
    onClose();
  }

  return <Dialog
    open={noticiaDialog}
    fullWidth
    maxWidth='md'
    onClose={handleCloseDialogNoticia}
  >
    <DialogTitle>
      <Box display='flex' justifyContent="space-between" sx={{ borderBottom: `1px solid ${colorsBase.BorderGray}` }}>
        <Typography component={'span'} sx={{ ...DesignSystem?.titulo1 }}>
          Formulario de creación
        </Typography>
        <IconButton onClick={handleCloseDialogNoticia}>
          <Close />
        </IconButton>
      </Box>
    </DialogTitle>
    <Box component={'form'} onSubmit={handleSubmit(handleSubmitBorrador)}>
      <DialogContent>
        <Grid container spacing={3} sx={{ px: 2 }}>
          { iniciativa ? 
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Nombre Completo*</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="nombre"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "El nombre es requerido"
                  }}
                  render={({ field }) => (
                    <CInputForm
                      inputIcon={<RolIcon />}
                      type="text"
                      placeholder="Nombre Completo"
                      {...field}
                      ref={null}
                      error={errors.nombre}
                    />
                  )}
                />
              </Grid>
            </Grid> : null
          }
          { iniciativa &&  fondoSelection === 'FONDO_BUENAS_PRACTICAS' ?
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Numero de Celular*</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="celular"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "El numero es requerido"
                  }}
                  render={({ field }) => (
                    <CInputForm
                      inputIcon={<RolIcon />}
                      type="text"
                      placeholder="Numero de Celular"
                      {...field}
                      ref={null}
                      error={errors.celular}
                    />
                  )}
                />
              </Grid>
            </Grid> : null
          }

          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2} sx={{display:'none'}}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Fondo*</Typography>
            </Grid>
            <Grid item xs={12} md={10} sx={{display:'none'}}>
              <Controller
                name="fondo"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CSelectForm
                    placeholder="Selecciona el Fondo en el que participas"
                    fullWidth
                    items={[
                      { value:'FONDO_BUENAS_PRACTICAS', label: "Fondo por una Vida Digna Sin Violencia para las Mujeres" },
                      { value:'FONDO_EXPERIENCIAS_INNOVADORAS', label: "Fondo Hilando Autonomias" }
                    ]}
                    {...field}
                    ref={null}
                    required
                    disabled={true}
                  />
                )}
              />
            </Grid>
          </Grid>
          {fondoSelection === 'FONDO_BUENAS_PRACTICAS' ? 
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Bloque*</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="bloque"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CSelectForm
                      placeholder="Soy responsable del bloque*"
                      fullWidth
                      items={[
                        { value: 'GESTION_PUBLICA', label: "Gestión pública"},
                        { value: 'COMUNICACION_PUBLICA', label: "Comunicación pública"},
                        { value: 'REPLICAS', label: "Réplicas"},
                        { value: 'PREVENCION', label: "Prevención"},
                        
                      ]}
                      {...field}
                      ref={null}
                    />
                  )}
                />
              </Grid>
            </Grid> : null
          }
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Título*</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Controller
                name="titulo"
                control={control}
                defaultValue=""
                rules={{
                  required: "El campo Titulo es requerido"
                }}
                render={({ field }) => (
                  <CInputForm
                    inputIcon={<RolIcon />}
                    type="text"
                    placeholder="Escribe el título"
                    {...field}
                    ref={null}
                    error={errors.titulo}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Descripción*</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Controller
                name="descripcion"
                control={control}
                defaultValue=""
                rules={{
                  required: "La descripcion es requerida"
                }}
                render={({ field }) => (
                  <CInputForm
                    inputIcon={<RolIcon />}
                    type="text"
                    placeholder="Escribe la descripción"
                    {...field}
                    ref={null}
                    error={errors.descripcion}
                  />
                )}
              />
            </Grid>
          </Grid>        

          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Departamento</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Controller
                name="departamento_cod"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CSelectForm
                    placeholder="Seleccione el Departamento"
                    fullWidth
                    items={departamentos.map((item: any) => ({
                      value: item.departamento_cod,
                      label: item.nombre,
                    }))}
                    {...field}
                    ref={null}
                    onChange={(selectedValue:SelectChangeEvent) => {
                      field.onChange(selectedValue); 
                      const _depto: any = departamentos.find((item: any) => item.departamento_cod ===selectedValue.target.value)
                      setMunicipios(_depto.municipios);}}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Municipio</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Controller
                name="municipio_cod"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CSelectForm
                    placeholder="Seleccione el Municipio"
                    fullWidth
                    items={municipios.map((item: any) => ({
                      value: item.municipio_cod,
                      label: item.municipio_desc,
                    }))}
                    {...field}
                    ref={null}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Gestion*</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Controller
                name="gestion"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <CSelectForm
                    placeholder="Seleccione la gestión"
                    fullWidth
                    items={[
                      { value: 2023, label: "2023" },
                      { value: 2024, label: "2024" },
                      { value: 2025, label: "2025" }
                    ]}
                    {...field}
                    ref={null}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                Es destacada:
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="destacada"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      ref={null}
                      //TODO add validation for news and interest link
                      // required
                    />
                  )}
                />
              </Grid>
            </Grid>

          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Tipo de recurso*</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
                <Controller
                  name="tipoRecurso"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CSelectForm
                      placeholder="Seleccione tipo de recurso"
                      fullWidth
                      items={[
                        { value: 'YOUTUBE', label: "YouTube" },
                        { value: 'SOUNDCLOUD', label: "SoundCloud" },
                        { value: 'LINK_ARCHIVO', label: "Link Archivo" },
                        { value: 'LINK_FOTOS', label: "Link Fotos" }
                      ]}
                      {...field}
                      ref={null}
                      required
                    />
                  )}
                />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>URL recurso*</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Controller
                name="recurso"
                control={control}
                defaultValue=""
                rules={{
                  required: "El campo Titulo es requerido"
                }}
                render={({ field }) => (
                  <CInputForm
                    inputIcon={<RolIcon />}
                    type="text"
                    placeholder="Escribe la url del recurso"
                    {...field}
                    ref={null}
                    error={errors.url}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Añadir adjunto</Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Box display='flex' flexDirection="column">
                <CFilePicker
                  multiple={true}
                  placeholder='Carga el archivo adjunto'
                  handleChange={(ev: ChangeEvent<HTMLInputElement>) => handleChangePicker(ev)}
                />
                <Box>
                  { adjuntos.map((adjunto: File, indiceAdjunto: number) => (
                    <Chip
                      deleteIcon={<IconButton size="small" sx={{ stroke: colorsBase.Rojo }}>
                        <CancelCircleIcon width="20" height="20" />
                      </IconButton>}
                      onDelete={() => {
                        const _adj = [...adjuntos];
                        _adj.splice(indiceAdjunto, 1);
                        setAdjuntos(_adj)
                      }}
                      label={adjunto.name ? adjunto.name : ''}
                      key={indiceAdjunto}
                      sx={{ margin: .5, border: `1px solid ${colorsBase.Verde3}` }}
                    />
                  ))}
                </Box>
                <Box>
                { !!currentAdjuntos && currentAdjuntos.length > 0 && currentAdjuntos.map((adjunto: Adjunto, idxCurrentAdjunto: number) => (
                    <Chip
                      deleteIcon={
                        <IconButton size="small" sx={{ stroke: colorsBase.Rojo }}>
                          <CancelCircleIcon width="20" height="20" />
                        </IconButton>
                      }
                      onDelete={() => {
                        printLog('eliminando adjunto!!')
                        const _adj = [...currentAdjuntos];
                        _adj.splice(idxCurrentAdjunto, 1);
                        setCurrentAdjuntos(_adj)
                        const _deletedAdj = [...deletedAdjuntos];
                        _deletedAdj.push(adjunto);
                        setDeletedAdjuntos(_deletedAdj);
                      }}
                      label={adjunto.nombre}
                      key={idxCurrentAdjunto}
                      sx={{ margin: .5, border: `1px solid ${colorsBase.Verde3}` }}
                    />
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
          </Grid>
          {fondoSelection === 'FONDO_EXPERIENCIAS_INNOVADORAS' ?
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography component={'span'} sx={{ ...DesignSystem?.cuerpo2 }}>Imagen </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="stockImageUrl"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    
                    <RadioGroup
                      {...field}
                      row
                      onChange={(event, value) => field.onChange(value)}
                      value={field.value}
                    >
                      {stockImages.map((option, index) => (
                        <Grid display={'flex'} xs={12}>
                          <FormControlLabel
                            key={index}
                            value={option}
                            control={<Radio />}
                            label={''}
                          />
                          <Card sx={{ maxWidth: 345, width:200, height:250,  backgroundColor: 'red' }} >
                            <CardMedia
                              sx={{ height: 250 }}
                              image={option}
                              title="img"
                            />
                          </Card>
                        </Grid>
                      ))}
                    </RadioGroup>
                  )}
                />
              </Grid>
          </Grid> : null }
      </DialogContent>
      <DialogActions sx={{ margin: 2, marginTop: 0, paddingTop: 1, borderTop: `1px solid ${colorsBase.BorderGray}` }}>
        <Box display="flex" justifyContent="flex-end">
          <CancelButton onClick={handleCloseDialogNoticia}>
            Cancelar
          </CancelButton>
          <ConfirmButton
            form="form-noticia"
            onClick={handleSubmit(handleSubmitBorrador)}
            sx={{ ml: 2 }}
            loading={loading}
          >
            Guardar borrador
          </ConfirmButton>
          {/* <ConfirmButton disabled sx={{ ml: 2 }}>
            Vista previa
          </ConfirmButton> */}
          <ConfirmButton
            onClick={handleSubmit(handleSubmitPublicar)}
            sx={{ ml: 2 }}
            loading={loading}
          >
            Publicar
          </ConfirmButton>
        </Box>
      </DialogActions>
    </Box>
  </Dialog>
};
