import DOMPurify from 'dompurify';
import './contentViewer.styles.css'

type ContentViewerProps = {
  textoHTML: string;
}

export const ContentViewer = ({
  textoHTML
}: ContentViewerProps) => {
  const createMarkup = (html: any) => {
    return  {
      __html: DOMPurify.sanitize(html)
    }
  }

  return (
    <div
      className="preview"
      dangerouslySetInnerHTML={createMarkup(textoHTML)}
    ></div>
  )
}
