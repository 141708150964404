import { Chip, Grid, IconButton } from "@mui/material";
import React from "react";
import { CancelOutlined } from '@mui/icons-material'
import { AdjuntoModel, AdjuntoNuevo } from "../../../types";
import { CFilePicker } from "../../../../../shared/components/form/CFilePicker";
// import { colorsBase } from "../../../../common/theme";

type EditorAdjuntoProps = {
  adjunto?: AdjuntoModel;
  onAdjuntoChange: (adjunto: AdjuntoNuevo) => void;
  onDelete: () => void;
};

export function EditorAdjunto({
  adjunto,
  onAdjuntoChange,
  onDelete,
}: EditorAdjuntoProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    if (!file) return;

    onAdjuntoChange({
      tipo: "NUEVO",
      archivo: file,
    });
  };
  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12} md={2}></Grid> */}
      <Grid item xs={12} md={11} container direction="column">
        {adjunto === undefined && (
          <Grid item>
            <CFilePicker
              placeholder="Carga adjuntos"
              handleChange={handleChange}
            />
          </Grid>
        )}
        {adjunto !== undefined && (
          <Grid item alignSelf="flex-start">
            <Chip
              deleteIcon={
                <IconButton size="small" sx={{ stroke: 'red' }}>
                  <CancelOutlined width="20" height="20" />
                </IconButton>
              }
              // Temporalmente Permitir Borrado solo en modo Creación
              onDelete={onDelete}
              label={
                adjunto.tipo === "NUEVO"
                  ? adjunto.archivo.name
                  : adjunto.url?.split("/").pop()
              }
              sx={{
                margin: 0.5,
                // border: `1px solid ${colorsBase.Verde3}`,
                border: `1px solid green`,
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={1}></Grid>
    </Grid>
  );
}
