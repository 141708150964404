import {Box, IconButton, Typography, useTheme} from '@mui/material';
// import { colorsBase, DesignSystem } from '../../../common/theme';
import {colorsBase, DesignSystem} from '../../../themes/theme';
import {Adjunto, IniciativaDetalle} from '../IniciativaInterface';
import dayjs from 'dayjs'
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useCallback, useEffect, useState} from 'react';
// import { ActionButton, FilterButton } from '../../../common/components/CButton';
import {ActionButton, FilterButton} from '../../../shared/components/CButton';
import DOMPurify from 'dompurify';
import {useEditNoticia, useGetNoticiaById, useGetStockImages} from '../IniciativaClient';

import {ReactComponent as KeyboardBackspace} from '../../../assets/icons/keyboard-backspace.svg';
import {ReactComponent as FileAttachedIcon} from '../../../assets/icons/fileAttached.svg';
import {FormIniciativaComponent} from './FormIniciativaComponent';
// import { showAlertSuccess } from '../../../common/hooks/useAlert';
import {Alerts} from '../../../shared/hooks/useAlert';
// import { useAuthContext } from '../../../common/auth/AuthContext';
// import { SystemRoles } from '../../../common/constants';
// import { isJsonString } from '../../../common/utils';
import {useAuthContext} from '../../../shared/auth/AuthContext';
import {SystemRoles} from '../../../shared/common/constants';
import {printLog} from '../../../shared/utils';


export default function IniciativaOverview() {
  const theme = useTheme();
  const { state }: any = useLocation();
  const { user } = useAuthContext();

  const params = useParams()
  printLog('params >>> ', params)
  printLog('state >>> ', state)

  const { noticiaId, isPractice } = state || params;

  printLog('[IniciativaOverview.tsx] iniciativa es id :: ', noticiaId);
  printLog('[IniciativaOverview.tsx] isPractice :: ', isPractice);
  const navigate = useHistory();
  if (!noticiaId) {
    printLog(' no hay iniciativa id :: ')
    // navigate.push(-1);
    navigate.push('/iniciativa');
  }

  const [stockImages, setStockImages] = useState([]);
  const [iniciativa, setIniciativa] = useState<IniciativaDetalle>();
  const [iniciativaDialog, setIniciativaDialog] = useState<boolean>(false);
  const [convertedContent, setConvertedContent] = useState<any>();

  // From noticia client
  const getStockImages =  useGetStockImages();
  const getNoticia = useGetNoticiaById()
  const editNoticia = useEditNoticia();

  const fetchStockImages = useCallback(async () => {
    try {
      const imagesData = await getStockImages();
      
      setStockImages(imagesData as unknown as []);
    } catch (error) {
      printLog("Ocurrió un error al obtener medios :: ", error);
      setStockImages([]);
    }
  }, []);

  async function fetchNoticia(noticiaId: string) {
    try {
      const fetchedNoticia = await getNoticia( noticiaId );
      printLog('Iniciativa fetcheada :: ', fetchedNoticia)
      debugger;
      setIniciativa(fetchedNoticia);
    } catch (error) {
      // printLog(InterpreteMensajes(error));
      printLog('😾 no hay respuesta!!!!!');
      // navigate(-1);
      navigate.push('/admin/noticia');
    }
    // printLog('seteando loading a false');
    // setLoading(false);
  }


  useEffect(() => {
    fetchStockImages();
    fetchNoticia(noticiaId);
  }, [setIniciativa]);


  const createMarkup = (html: any) => {
    return  {
      __html: DOMPurify.sanitize(html)
    }
  }

  const descargarAdjunto = (adjunto: Adjunto) => {
    printLog('Descargar adjunto :: ', adjunto.url);
    const link = document.createElement('a');
    link.href = adjunto.url;
    link.download = adjunto.nombre;
    link.target = '_blank';
    link.click();
  }

  const handleOpenNoticia = () => {
    setIniciativaDialog(true);
  }

  const handleCloseNoticia = () => {
    setIniciativaDialog(false);
  }

  const onEditNoticia = async (data: any) => {debugger;
    printLog('Editando la iniciativa ::: ', data);
    const resultado = await editNoticia(data, noticiaId);

    if (resultado) {
      await fetchNoticia(noticiaId);
      handleCloseNoticia();
      Alerts.showSuccess({
        confirmText: 'Aceptar',
        description: '¡La iniciativa fue actualizada exitosamente!'
      })
    }
  }
  return <>
    <Box display="flex" justifyContent="space-between" alignItems="center" alignContent="center" mb={6}>
      <Box display="flex" alignItems="center">
        {/* <IconButton size="small" onClick={() => navigate(-1)}> */}
        <IconButton size="small" onClick={() => {
                   console.log('isPractice')
                   console.log(isPractice)
                   navigate.push(`/admin/iniciativa`, {
                      state:{isPractice: isPractice}
                    })
        }}>
          <KeyboardBackspace />
        </IconButton>
        <Typography sx={{ ...DesignSystem?.titulo1, pl: 2 }}>
          Atrás
        </Typography>
      </Box>
      { user?.rol !== SystemRoles.PARTICIPANTE && <Box>
        <ActionButton onClick={handleOpenNoticia}>
          Editar Iniciativa
        </ActionButton>
      </Box> }
    </Box>
    { iniciativa && <Box sx={{
      background: "rgba(255, 255, 255, 0.5)",
      boxShadow: "1.5px 2.6px 10px rgba(119, 119, 119, 0.1)",
      boxSizing: "border-box",
      borderRadius: "8px",
      p: 4,
      marginRight: 5,
      marginLeft: 5,
      [theme.breakpoints.down('md')]: {
        p: 3,
        marginRight: 3,
        marginLeft: 3,
      },
      [theme.breakpoints.down('sm')]: {
        p: 2,
        marginRight: 0,
        marginLeft: 0,
      },
    }}>
      <Typography component='p' sx={{ ...DesignSystem?.titulo1 }}>
        { iniciativa.titulo }
      </Typography>
      <Typography  component='p' sx={{ ...DesignSystem?.cuerpo2, wordBreak: "break-word"  }}>
        { iniciativa?.descripcion }
      </Typography>
      <Typography component='p' sx={{ ...DesignSystem?.cuerpo2, marginTop: 2, marginBottom: 2 }}>
        Fecha de publicación: { dayjs(iniciativa.fechaPublicacion).format('DD/MM/YYYY') }
      </Typography>
      { iniciativa.contenido && convertedContent && (
        <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>
      )}

      { iniciativa.adjuntos && iniciativa.adjuntos.length > 0 && <Box>
        <Typography component='p' sx={{ ...DesignSystem?.titulo3, py: 3 }}>
          Este documento tiene archivos adjuntos disponibles para su descarga
        </Typography>
        { iniciativa.adjuntos!.map((adjunto: Adjunto, idxNoticia: number) => (
          <FilterButton
            key={idxNoticia}
            sx={{ mx: 1, color: colorsBase.Naranja1, my: 1 }}
            onClick={() => descargarAdjunto(adjunto)}
          >
            <FileAttachedIcon style={{ fill: colorsBase.Naranja1, marginRight: 8 }} />
            { adjunto.nombre }
          </FilterButton>
        )) }
      </Box>}
    </Box>}
    { iniciativaDialog && (
      <FormIniciativaComponent
        iniciativa={iniciativa}
        noticiaDialog={iniciativaDialog}
        onClose={handleCloseNoticia}
        onSubmitIniciativa={onEditNoticia}
        loading={false}
        stockImages={stockImages}
        isPractice={isPractice}
      />
    )}
  </>
}
