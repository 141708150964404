import {
  Box,
  Button,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import bgTop from "./../../../assets/Communication/banner/main-bg.jpg";

import { SocialItem } from "./SocialItem";

import SolidarIcon from "./../../../assets/Communication/socials/solidar.png";

import YoutubeColorIcon from "./../../../assets/Communication/socials/youtube-color.png";
import YoutubeIcon from "./../../../assets/Communication/socials/youtube.png";

import InstagramColorIcon from "./../../../assets/Communication/socials/instagram-color.png";
import InstagramIcon from "./../../../assets/Communication/socials/instagram.png";

import FacebookColorIcon from "./../../../assets/Communication/socials/facebook-color.png";
import FacebookIcon from "./../../../assets/Communication/socials/facebook.png";
import { ComunicacionEnlaces } from "../constants";
import theme from "../../../themes";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  // height: 400,
  height: "70vh",
  backgroundImage: `url(${bgTop})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "fit-content",
    height: 500,
  },
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "3em 1.5em 1.5em 1.5em",
  backgroundColor: "rgb(74,12,85,0.8)",
  width: "50%",
  height: "100%",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  backgroundColor: theme.palette.primary.main,
  borderRadius: "8px",
  color: theme.palette.secondary.active,
  "&:hover": {
    backgroundColor: theme.palette.primary.hover,
    color: theme.palette.common.white,
  },
  opacity: 1,
}));

const StyledWhiteButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  borderRadius: "8px",
  backgroundColor: alpha(theme.palette.common.white, 0.85),
  color: theme.palette.secondary.active,
  "&:hover": {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.hover,
  },
  opacity: 1,
}));

const MainBanner = () => {
  const theme = useTheme();
  // const xs = useMediaQuery(theme.breakpoints.only("xs"));
  let history = useHistory();

  const goToLink = (enlace: string) => {
    const link = document.createElement("a");
    link.href = enlace;
    link.target = "_blank";
    link.click();
  };

  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          height: "100%",
          position: "absolute",
          right: 50,
        }}
      >
        <Box
          sx={{
            py: 2,
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            px: 1,
          }}
        >
          <SocialItem
            withBorder
            customAlt={"solidar-icon"}
            iconPath={SolidarIcon}
            disableIconPath={SolidarIcon}
            action={() => goToLink(ComunicacionEnlaces.SOLIDAR)}
          />
          <SocialItem
            withBorder
            customAlt={"facebook-icon"}
            iconPath={FacebookColorIcon}
            disableIconPath={FacebookIcon}
            action={() => goToLink(ComunicacionEnlaces.FACEBOOK)}
          />
          <SocialItem
            withBorder
            customAlt={"instagram-icon"}
            iconPath={InstagramColorIcon}
            disableIconPath={InstagramIcon}
            action={() => goToLink(ComunicacionEnlaces.INSTAGRAM)}
          />
          <SocialItem
            withBorder
            customAlt={"youtube-icon"}
            iconPath={YoutubeColorIcon}
            disableIconPath={YoutubeIcon}
            action={() => goToLink(ComunicacionEnlaces.YOUTUBE)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledDivMediaCaption>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            sx={{ height: "100%", margin: 0, padding: 0 }}
          >
            <Box mb={2}>
              <Typography
                variant="h1"
                sx={{
                  fontFamily: "Barlow",
                  fontSize: 24,
                  fontWeight: 400,
                  color: theme.palette.primary.main,
                  lineHeight: 2,
                }}
              >
                LO QUE HACEMOS
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  fontFamily: "Barlow",
                  fontSize: 42,
                  fontWeight: 400,
                  color: theme.palette.common.white,
                  lineHeight: 1,
                }}
              >
                Comunicación
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 300,
                  color: theme.palette.common.white,
                  mt: "1em",
                }}
              >
                Siendo la comunicación un pilar importante para contribuir a que
                las mujeres alcancen una vida digna sin violencia, en este
                espacio se visibilizará las acciones comunicacionales sostenidas
                y la difusión de buenas prácticas para alentar a la réplica.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 300,
                  color: theme.palette.common.white,
                  mt: "1em",
                }}
              >
                Para ello, se pondrá a disposición de todas y todos los
                interesados, productos multimedia difundidos a nivel nacional,
                regional y local.
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              mt={"1rem"}
              gap={1.5}
              alignItems={"start"}
            >
              <StyledButton
                variant="contained"
                onClick={() => history.push("/comunicacion#campania")}
              >
                CAMPAÑAS
              </StyledButton>
              <StyledWhiteButton
                variant="contained"
                onClick={() => history.push("/comunicacion#noticias")}
              >
                NOTICIAS
              </StyledWhiteButton>
            </Box>
          </Box>
        </StyledDivMediaCaption>
      </Box>
    </StyledBox>
  );
};

export default MainBanner;
