import { Box, Grid, IconButton } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CInputForm from "../../../shared/components/form/CInputForm";
import { CoursePrimaryButton } from "../../Courses/shared/Elements";
import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";
import { useState } from "react";
import { useAuthContext } from "../../../shared/auth/AuthContext";
import { UserRecord } from "../../../shared/auth/Auth";
import { useHistory } from "react-router-dom";
import { Alerts } from "../../../shared/hooks/useAlert";
import { printLog } from "../../../shared/utils";

export type LoginFormType = {
  email: string;
  password: string;
};

const LoginForm = () => {
  const authContext = useAuthContext();
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginFormType>();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((show) => !show);

  const onSubmit: SubmitHandler<LoginFormType> = (data) => {
    printLog("Iniciando sesion con > ", data);
    try {
      authContext.login({
        username: data.email,
        password: data.password,
        onSuccess: async (userRecord: UserRecord) => {
          printLog("userRecord >> ", userRecord);
          localStorage.setItem(
            "user",
            JSON.stringify({
              username: data.email,
              password: data.password,
              rol: userRecord.rol,
            })
          );
          printLog("User logged in successfully:", userRecord);
          printLog("Navegando a '/'");
          // navigate('/usuario', { replace: true })
          if (userRecord.rol === "MONITOR") {
            history.push("/admin", { replace: true });
          } else if (userRecord.rol === "PARTICIPANTE") {
            history.push("/catalogo-cursos", { replace: true });
            Alerts.showInfo({
              title: "¡Bienvenido!",
              description:
                "Explora los cursos disponibles o accede a tus cursos inscritos.",
            });
          } else {
            history.push("/", { replace: true });
          }
          // if (userRecord.tokenFCM) {
          //   await registerDeviceToken(userRecord.uid, userRecord.tokenFCM!);
          // }
        },
        onFailure: (_message: any) => {
          console.log("erooooooooooooooooooor");
          Alerts.showError({
            // title: 'Error en la autenticación',
            description: _message,
          });
          // showAlertError({
          //   btnText: 'Aceptar',
          //   title: 'Error en la autenticación',
          //   message: _message,
          // })
        },
      });
    } catch (error) {
      console.log("something fallo");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={3} alignItems="center" mb={3}>
        <Grid item container xs={12}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: "El correo electrónico es requerido",
            }}
            render={({ field }) => (
              <CInputForm
                type="text"
                placeholder="Correo electrónico"
                fullWidth
                autoComplete="off"
                autoFocus
                {...field}
                ref={null}
                error={errors.email}
              />
            )}
          />
        </Grid>
        <Grid item container xs={12}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: "El campo Contraseña es requerido.",
              minLength: {
                value: 6,
                message: "La contraseña debe tener al menos 6 dígitos.",
              },
            }}
            render={({ field }) => (
              <CInputForm
                type={showPassword ? "text" : "password"}
                placeholder="Contraseña"
                fullWidth
                {...field}
                ref={null}
                error={errors.password}
                id="password"
                autoComplete="current-password"
                inputIcon={
                  <IconButton onClick={handleShowPassword}>
                    {!showPassword ? (
                      <VisibilityRounded />
                    ) : (
                      <VisibilityOffRounded />
                    )}
                  </IconButton>
                }
              />
            )}
          />
        </Grid>
        <Grid item container xs={12}>
          <CoursePrimaryButton
            fullWidth
            type="submit"
            disabled={
              errors.hasOwnProperty("email") ||
              errors.hasOwnProperty("password")
            }
          >
            Ingresar
          </CoursePrimaryButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginForm;
