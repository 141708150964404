import {
  Box,
  IconButton,
  Typography,
  Tooltip,
  useTheme,
  LinearProgress,
  Button,
  Card,
  styled,
  CardProps,
} from "@mui/material";
import {
  RefreshOutlined,
  DeleteOutline,
  EditOutlined,
} from "@mui/icons-material";
// import { showConfirm } from "../../shared/hooks/useAlert";
import { useEffect, useState } from "react";
import { useDeleteCourse, useGetCourses } from "./data";
import { useHistory } from "react-router-dom";
import { CourseNeutralButton, CoursePrimaryButton } from "./shared/Elements";
import { Alerts } from "../../shared/hooks/useAlert";
import { printLog } from "../../shared/utils";
// import { useAlertModalContext } from "../../shared/context/modalAlertContext";

export const StyledCoursesCard = styled((props: CardProps) => (
  <Card elevation={0} {...props}>
    {props.children}
  </Card>
))(({ theme }) => ({
  // margin: '3rem 4rem',
  marginLeft: "2rem",
  marginRight: "2rem",
  marginTop: "2rem",
  borderRadius: "8px",
}));

export const StyledPrimaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.hover,
  },
}));

export const StyledSecondaryButton = styled(Button)(({ theme }) => ({
  color: theme.palette.neutral.main,
  minWidth: "initial",
  borderColor: theme.palette.neutral.main,
  "&:hover": {
    borderColor: theme.palette.neutral.hover,
    color: theme.palette.neutral.hover,
  },
}));

function CoursePage() {
  const theme = useTheme();
  let history = useHistory();
  // const matchesPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState<boolean>(false);
  const [courses, setCourses] = useState([]);
  const getCourses = useGetCourses();
  const deleteCourse = useDeleteCourse();

  /// Context de alertas
  // const alertContext = useAlertModalContext();

  async function fetchCourses() {
    setLoading(true);
    const fetchedCourses = await getCourses();
    printLog("cursos fetcheados :: ", fetchedCourses);
    setCourses(fetchedCourses as unknown as []);
    setLoading(false);
  }

  useEffect(() => {
    fetchCourses();
    return () => {
      setCourses([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gotoCourseDetail = () => {
    printLog("Create course >> ");
    history.push("/admin/cursos/detalle", { courseId: null });
  };

  const handleUpdateCourse = (_course: any) => {
    // TODO: pendiente tipado para _course
    printLog("Edit course >> ", _course);
    history.push("/admin/cursos/detalle", { courseId: _course.id });
  };

  const handleDeleteCourse = async (courseId: number) => {
    printLog("Delete course id ", courseId);
    await Alerts.showConfirm({
      title: "¿Estás segura(o) de eliminar el curso?",
      description: "",
      onConfirm: async () => {
        await deleteCourse(courseId);
        await fetchCourses();
      },
    });
    // if (confirmation) {
    //   await deleteCourse(courseId);
    //   await fetchCourses();
    // }
    // showConfirm({
    //   title: "¿Estás segura(o) de eliminar el curso?.",
    //   description: "",
    //   onConfirm: async () => {
    //     await deleteCourse(courseId);
    //     await fetchCourses();
    //   },
    // });
  };

  return (
    <StyledCoursesCard elevation={0}>
      <Box
        display="flex"
        justifyContent="space-between"
        gap={2}
        alignItems="center"
        alignContent="center"
        sx={{ borderBottom: "1px solid #cacaca", padding: "1rem" }}
      >
        <Typography sx={{ flex: 1, pl: 2, fontSize: 30, fontWeight: 500 }}>
          Cursos
        </Typography>
        <CourseNeutralButton onClick={fetchCourses}>
          <RefreshOutlined />
        </CourseNeutralButton>
        <CoursePrimaryButton onClick={gotoCourseDetail}>
          Agregar +
        </CoursePrimaryButton>
        {/* <Tooltip title="Refrescar datos" sx={{ mr: 1 }}>
        </Tooltip>
        <Tooltip title="Crear curso">
        </Tooltip> */}
      </Box>
      <Box my={4} mx={4}>
        {loading && <LinearProgress />}
        {!loading &&
          courses.length > 0 &&
          courses.map((plantillaCurso: any, idxPlantilla: number) => (
            <Box
              key={idxPlantilla}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={4}
              pb={1}
              mx={3}
              borderBottom={1}
              borderColor={theme.palette.neutral.main}
            >
              <Typography component={"span"}>
                {plantillaCurso.nombre}
              </Typography>
              <Box>
                <Tooltip title="Editar curso">
                  <IconButton
                    size="small"
                    onClick={() => handleUpdateCourse(plantillaCurso)}
                  >
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar curso">
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteCourse(plantillaCurso.id)}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ))}
      </Box>
    </StyledCoursesCard>
  );
}

export default CoursePage;
