import {
  Breakpoint,
  Dialog,
  PaperProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BaseSyntheticEvent, FC, PropsWithChildren } from "react";
import { PortalProps } from "@mui/base/Portal";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  fullScreen?: boolean;
  maxWidth?: Breakpoint | undefined;
  paperProps?: Partial<PaperProps>;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  scroll?: "body" | "paper";
  disablePortal?: PortalProps["disablePortal"];
  disableScrollLock?: boolean;
}

export const CDialog: FC<PropsWithChildren<Props>> = ({
  isOpen,
  handleClose,
  children,
  fullScreen = false,
  maxWidth,
  paperProps,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  scroll = "body",
  disablePortal,
  disableScrollLock,
}) => {
  const theme = useTheme();
  let dsm = useMediaQuery(theme.breakpoints.down("sm"));

  const cerrarDialog = (event: BaseSyntheticEvent, reason: string) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return false;
    }
    if (disableEscapeKeyDown && reason === "escapeKeyDown") {
      return false;
    }
    handleClose();
  };
  return (
    <Dialog
      PaperProps={paperProps}
      fullScreen={fullScreen || dsm}
      fullWidth={true}
      maxWidth={maxWidth}
      open={isOpen}
      // TransitionComponent={dsm ? TransitionSlide : TransitionZoom}
      onClose={cerrarDialog}
      scroll={scroll}
      disablePortal={disablePortal}
      disableScrollLock={disableScrollLock}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 3,
        },
      }}
    >
      {children}
    </Dialog>
  );
};
