import { Box, Chip, Typography, styled } from "@mui/material";
import { useHistory } from "react-router-dom";
import theme from "../../../../../../themes";
import { NewsCardProps } from "../../../../../../types/NewsCard";
import useAppContext from "../../../../../../shared/hooks/useAppContext";
import moment from "moment";

const StyledBox = styled(Box)(({ theme }) => ({
  margin: 0,
  padding: 0,
  width: '100%',
  maxHeight: '210px',
  height: '210px',
  cursor: 'pointer',
  // TODO: mobile breakpoints
  // [theme.breakpoints.up('md')]: {
  //   maxHeight: '300px',
  //   height: '300px'
  // },
  [theme.breakpoints.up('lg')]: {
    maxHeight: '200px',
    height: '200px',
  }
}));

const StyledImg = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '8px',
  [theme.breakpoints.up('md')]: {
    height: '100%'
  },
  [theme.breakpoints.up('lg')]: {
    height: '100%'
  }
}));

const NewsCard = ({ image, content, date, clipLabel, isInterestLink, code, urlSufix, displayAsColumn, cardWidth, cardHeight, category,clipLabel2 }: NewsCardProps) => {
  let history = useHistory();
  const { setInterestLinkContent, setNewsContent } = useAppContext();

  const handleClick = () => {
    
    if(isInterestLink) {
      setInterestLinkContent(null);
      // history.push(`/hemeroteca/link-de-interes/${urlSufix}_${code}`)
      history.push(`/hemeroteca/link-de-interes/${code}`)
    }
    else{
      setNewsContent(null);
      history.push(`/hemeroteca/noticia/${code}`)
    }
      
      
  }

  return (
    <StyledBox display="flex" justifyContent="center" onClick={handleClick}>
      <Box display="flex" sx={{ width: '35%' }}>
        <Box display="inline-block" height={'100%'} width={'100%'} style={{ padding: '1.5em 0 0 0' }}>

          <StyledImg src={image} alt="img" />

        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ width: '65%', padding: '1.5em 1.5em 1.5em 1.5em' }}
      >
        <Box display="flex">
          <Chip label={clipLabel} sx={{ backgroundColor: theme.palette.secondary.active, color: '#FFFFFF' }} />
          {clipLabel2 !== "" && <Chip label={clipLabel2} sx={{ backgroundColor: '#ff0001', color: '#FFFFFF',mx: '0.5em', }} />}
        </Box>
        <Box my={'0.5em'}>
          <Typography sx={{
            fontFamily: 'Barlow',
            lineHeight: { xs: 1, md: 1.2 },
            fontSize: { xs: 18, lg: 22, },
            color: '#54595F',
            fontWeight: 'bold',
            [theme.breakpoints.down(366)]: {
              fontSize: 16
            },
          }}>
            {content}
          </Typography>
        </Box>
        <Box>
          <Typography color="primary">
            {date ? `${moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY')} / ${category}` : ` / ${category}`}
          </Typography>
        </Box>
      </Box>
    </StyledBox>
  )
};

export default NewsCard;