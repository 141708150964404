import { Box, Button, Typography, styled } from "@mui/material";
import theme from "../../../../themes";
import map from "../../../../assets/WhoWeAre/mapa_completo.png";

const StyledBox = styled(Box)(() => ({
  margin: '0 0 6em 0',
  padding: 0,
  backgroundColor: '#CECECE'
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  fontFamily: 'Barlow',
  fontSize: 14,
  borderRadius: '8px',
  border: '1px solid #808080',
  color: '#808080',
  width: '200px',
  height: '50px',
  '&:hover': {
    backgroundColor: '#808080',
    color: '#E8E8E8',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));

// const StyledImg = styled('img')(() => ({
//   maxWidth: '100%',
//   height: '100%',
//   objectFit: 'contain',
// }));

const MunicipalitiesWhiteArea = () => {
  return (
    <StyledBox display="flex" justifyContent="center">
      <Box sx={{ width: { xs: '100%', md: '75%' }, height: '100%', margin: 0, padding: '5em 1.5em 5em 1.5em', borderRadius: '8px' }}>
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent="space-between" sx={{ flexWrap:'wrap', height: '100%', margin: 0, padding: 0, backgroundColor: '#FFFFFF', borderRadius: '8px' }}>
          <Box 
            display="flex" 
            sx={{
              width: '100%', 
              // backgroundColor:'red',
              margin: '3em 0 0em 0', 
              padding: { xs: '0em 1.5em 0em 1.5em', md: '0 0 0 1.5em' }
            }}>
              <Typography sx={{ color: theme.palette.success.hover, fontFamily: 'Barlow', fontSize: '42px', lineHeight: '1' }}>
                Municipios que son parte del desafío
              </Typography>
          </Box>
          <Box display="flex" flexDirection="column" sx={{ margin: '3em 0 3em 0', padding: { xs: '0em 1.5em 0em 1.5em', md: '0 0 0 1.5em' }, width: { xs: '100%', md: '60%' } }}>
            <Typography sx={{ mt: '0em', fontFamily: 'Barlow', fontSize: 16, color: theme.palette.neutral.hover }}>
              Actualmente, el proyecto está trabajando en 51 municipios de los 9 departamentos del país, a través de las redes de lucha contra la violencia en alianza con los Gobiernos Autónomos Municipales
            </Typography>
          </Box>
          {/* <Box sx={{ xs: '100%', md: '30%' }}>
            <StyledImg src={map} alt="map" />
          </Box> */}
          <Box display="flex" alignItems="center" sx={{ marginLeft: '6em', width: { xs: '100%', md: '40%' }, margin: '0em 0 0em 0' }}>
            <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>            
              <StyledButton variant="text" sx={{ marginRight: '1.5em', marginLeft: { xs: '1.5em', md: 0 }, marginBottom: { xs: '3em', md: 0 } }} onClick={() =>(window.location.href ="https://drive.google.com/file/d/1Id3dhqfLaOOd4HRHcsxgNEpIeHbz5p0u/view?usp=sharing")}>
                conocer más
              </StyledButton>
            </Box>
          </Box>
        </Box>
        
        <Box display="flex" 
          flexDirection="column"
            sx={{
              width: '100%', 
              // border: '1px solid red',
              margin: '3em 0 0em 0', 
              padding: { xs: '0em 1.5em 0em 1.5em', md: '0 0 0 1.5em' }
        }}>
          <Typography sx={{ color: theme.palette.neutral.active, fontFamily: 'Barlow', fontSize: '30px', lineHeight: '1',padding: "0em 0em 0.4em 0em", }}>
            Cobertura Vida Digna Sin Violencia
          </Typography>
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontSize: 16,
              color: theme.palette.neutral.hover,
            }}
          >
            Implementación  primer año 2023
          </Typography>
          <Box mt={2} width='100%' sx={{ alignSelf: 'center', textAlign: 'center', height: {xs: 450, md: 700} }}>
            <iframe 
              title="Cobertura del proyecto" 
              aria-label="Map" 
              id="datawrapper-chart-NyHje" 
              src="https://datawrapper.dwcdn.net/NyHje/1/" 
              scrolling="no" 
              frameBorder="0" 
              style={{width: '100%', minWidth: '100% !important', border: 'none'}}
              height="100%" 
              data-external="1"
            >
            </iframe>
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{ __html:
              `!function(){"use strict";window.addEventListener("message",(function(a){if(void 0!==a.data["datawrapper-height"]){var e=document.querySelectorAll("iframe");for(var t in a.data["datawrapper-height"])for(var r=0;r<e.length;r++)if(e[r].contentWindow===a.source){var i=a.data["datawrapper-height"][t]+"px";e[r].style.height=i}}}))}();`
            }}
            >
            </script>
          {/* <script type="text/javascript">!function(){"use strict";window.addEventListener("message",(function(a){if(void 0!==a.data["datawrapper-height"]){var e=document.querySelectorAll("iframe");for(var t in a.data["datawrapper-height"])for(var r=0;r<e.length;r++)if(e[r].contentWindow===a.source){var i=a.data["datawrapper-height"][t]+"px";e[r].style.height=i}}}))}();
          </script> */}
          </Box>
        </Box>
      </Box>
    </StyledBox>
  )
};

export default MunicipalitiesWhiteArea;