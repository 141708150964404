import { Box, Button, Typography, alpha, styled } from "@mui/material";
import { useHistory } from "react-router-dom";
import theme from "../../../../themes";
import bgTop from "../../../../assets/innovativeExperiences/bg_top.jpg";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  // height: 400,
  height: "70vh",
  backgroundImage: `url(${bgTop})`,
  backgroundSize: "cover",
  backgroundAttachment: 'fixed',
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "fit-content",
    height: 500,
  },
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "3em 1.5em 1.5em 1.5em",
  backgroundColor: "rgb(74,12,85,0.8)",
  width: "50%",
  height: "100%",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  backgroundColor: theme.palette.primary.main,
  borderRadius: '8px',
  color: theme.palette.secondary.active,
  "&:hover": {
    backgroundColor: theme.palette.primary.hover,
    color: theme.palette.common.white,
  },
  opacity: 1,
}));

const StyledWhiteButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  borderRadius: '8px',
  backgroundColor: alpha(theme.palette.common.white, 0.85),
  color: theme.palette.secondary.active,
  "&:hover": {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.hover,
  },
  opacity: 1,
}));

const MainBanner = () => {
  let history = useHistory();

  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledDivMediaCaption>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            sx={{ height: "100%", margin: 0, padding: 0 }}
          >
            <Box mb={2}>
              <Typography
                variant="h1"
                sx={{
                  fontFamily: "Barlow",
                  fontSize: 42,
                  fontWeight: 400,
                  color: theme.palette.common.white,
                  lineHeight: 1,
                }}
              >
                Experiencias innovadoras
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  fontWeight: 300,
                  color: theme.palette.common.white,
                  mt: "1em",
                }}
              >
                ¡Nuevas ideas que están cambiando la vida de cientos de mujeres
                en todo el país! Distintas organizaciones bolivianas hace mucho
                tiempo vienen impulsando acciones innovadoras y sistemáticas
                para prevenir y frenar la violencia hacia las mujeres.
              </Typography>
            </Box>

            {/* <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              mt={'1rem'}
              gap={1.5}
              alignItems={"start"}
            >
              <StyledButton
                variant="contained"
                onClick={() =>
                  history.push("/experiencias-innovadoras#maxExperiencias")
                }
              >
                Experiencias innovadoras
              </StyledButton>
              <StyledWhiteButton
                variant="contained"
                onClick={() =>
                  history.push("/experiencias-innovadoras#hilandoAutonomias")
                }
              >
                FONDO HILANDO AUTONOMIAS
              </StyledWhiteButton>
            </Box> */}
          </Box>
        </StyledDivMediaCaption>
      </Box>
    </StyledBox>
  );
};

export default MainBanner;
