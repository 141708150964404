import { Box, Grid, Typography, styled } from "@mui/material";
import theme from "../../../../themes";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  backgroundColor: "#ededed",
}));

const GrayDescription = () => {
  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: "100%", height: "100%" }}
        >
          <Box display="flex" flexDirection="column">
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                padding: "1.5em 1.5em 1.5em 1.5em",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: 16,
                  color: theme.palette.neutral.active,
                }}
              >
                Es por eso que ahora nos toca avanzar y plantearnos el desafío de ver otros aspectos para que las mujeres logren una vida
                digna sin violencia: contribuyendo a generar oportunidades para su autonomía económica, acceso a la salud sexual y contar con 
                espacios de apoyo y protección.<br/>Este proyecto es implementado por Solidar Suiza en Bolivia, con el apoyo de la Agencia Sueca de para el Desarrollo Internacional (Asdi).
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </StyledBox>
  );
};

export default GrayDescription;
