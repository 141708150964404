// import { delay, eliminarCookie, guardarCookie, leerCookie } from '../utils'
// import { printLog } from '../utils/printLog'
import {
  estadosSinPermiso,
  peticionFormatoMetodo,
  Servicios,
} from "../services";
// import { Constantes } from '../../config'
// import { useFullScreenLoading } from '../../context/ui'
// import { useRouter } from 'next/router'
import { useFullScreenLoading } from "../../context/ui/FullScreenLoadingProvider";
import { delay, printLog } from "../utils";
import { BaseURL } from "../constants";
import { useHistory } from "react-router-dom";

export const useSession = () => {
  const history = useHistory();

  const { mostrarFullScreen, ocultarFullScreen } = useFullScreenLoading();
  const requestSession = async ({
    url,
    tipo = "get",
    body,
    headers,
    params,
    responseType,
    withCredentials,
  }: peticionFormatoMetodo) => {
    try {
      const cabeceras = {
        accept: "application/json",
        // Authorization: `Bearer ${leerCookie('token') ?? ''}`, // no hay authorization
        ...headers,
      };
      const _url = `${BaseURL}${url}`;

      printLog(`enviando 🔐🌍`, body, tipo, _url, cabeceras);
      const response = await Servicios.peticionHTTP({
        url: _url,
        tipo,
        headers: cabeceras,
        body,
        params,
        responseType,
        withCredentials,
      });
      printLog("respuesta 🔐📡", body, tipo, url, response);
      return response.data;
    } catch (e: import("axios").AxiosError | any) {
      if (e.code === "ECONNABORTED") {
        throw new Error("La petición está tardando demasiado");
      }

      if (Servicios.isNetworkError(e)) {
        throw new Error("Error en la conexión 🌎");
      }

      if (estadosSinPermiso.includes(e.response?.status)) {
        mostrarFullScreen();
        await cerrarSesion();
        ocultarFullScreen();
        return;
      }

      throw e.response?.data || "Ocurrio un error desconocido";
    }
  };

  const cerrarSesion = async () => {
    try {
      mostrarFullScreen();
      await delay(1000);

      const respuesta = await Servicios.get({
        headers: {
          accept: "application/json",
          // Authorization: `Bearer ${token}`,
        },
        url: `${BaseURL}/logout`,
      });
      printLog(`finalizando con respuesta`, respuesta);

      // if (respuesta?.url) {
      //   window.location.href = respuesta?.url;
      // } else {
      //   // router.reload()
      // }
      history.go(0);
    } catch (e) {
      printLog(`Error al cerrar sesión: `, e);
      // router.reload()
      history.go(0);
    } finally {
      ocultarFullScreen();
    }
  };

  // const actualizarSesion = async () => {
  //   printLog(`Actualizando token 🚨`)

  //   try {
  //     const token = leerCookie('token')
  //     const respuesta = await Servicios.post({
  //       url: `${Constantes.baseUrl}/token`,
  //       body: { token },
  //     })

  //     guardarCookie('token', respuesta.datos?.access_token)

  //     await delay(500)
  //   } catch (e) {
  //     await cerrarSesion()
  //   }
  // }

  return { requestSession, cerrarSesion };
};
