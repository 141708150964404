import { Box, Card, CardMedia, alpha, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { TimelineCardProps } from "../../types/TimelineCard";
import theme from "../../themes";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  padding: 0,
  margin: 0,
  height: 151,
  maxHeight: 151,
}));

const TimelineCard = ({ image, year, content, link }: TimelineCardProps) => {

  let htmlContent = link ? `${content} <a href=${link}  style="text-decoration: none; display: inline-block; padding: 8px 15px; background-color: #e6ac0c; color: black; border-radius: 5px;" >LINK</a>` : `${content}`;
  // debugger;
  return (
    <Item display="flex">
      <Card
        elevation={0}
        sx={{
          display: "flex",
          borderRadius: '8px',
          backgroundColor: "#e8e8e8",
          margin: 0,
          padding: 0,
          width: "100%",
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: 151,
            height: 151,
            [theme.breakpoints.up("xs")]: {
              width: 100,
            },
            [theme.breakpoints.up("sm")]: {
              width: 120,
            },
            [theme.breakpoints.up("md")]: {
              width: 140,
            },
          }}
          image={image}
          alt="Timeline img"
        />
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            margin: 0,
            padding: '1em',
            flexDirection: "column",
            width: "100%",
            height: "inherit",
          }}
        >
          <Box display="flex" justifyContent="flex-start">
            <Typography
              textAlign="left"
              sx={{
                fontFamily: "Ubuntu",
                fontSize: 22,
                color: alpha(theme.palette.common.black, 0.8),
                fontWeight: 500,
                [theme.breakpoints.down("lg")]: {
                  fontSize: 18,
                },
                marginBottom: '.5rem',
              }}
            >
              {year}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-start">
            <Typography dangerouslySetInnerHTML={{ __html: htmlContent }} textAlign="left"
              textOverflow="ellipsis"
              sx={{
                fontFamily: "Ubuntu",
                fontWeight: 300,
                fontSize: 16,
                [theme.breakpoints.down("md")]: {
                  fontSize: 11,
                },
                [theme.breakpoints.down("lg")]: {
                  fontSize: 13,
                },
              }}
            />
          </Box>
        </Box>
      </Card>
    </Item>
  );
};

export default TimelineCard;
