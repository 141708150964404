import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import Cloader from "../../shared/components/CLoader";
import Layout from "../../components/Layout";
import MainBanner from "../CoursesCatalog/components/MainBanner";



export default function CertificateCheck() {
    const { certificateId } = useParams<{certificateId: string}>();

    const [certificateData, setCertificateData] = useState<CertificateData>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const certificateResponse = axios("/api/check-certificate/" + certificateId)
            .then(resp => setCertificateData(resp.data))
            .catch(() => setError(true))
            .finally(() => setLoading(false))
    }, []);

    if(loading) return <Cloader message={"Verificando estado del certificado"} />
    if(error) return <p>Hubo un error en la verificacion del certificado</p>

    return (
        <Layout>
            <h1> SOLIDAR - POR UNA VIDA SIN VIOLENCIA </h1>
            <h1>
                Verificacion del certificado
            </h1>

            <dl>
                <dt> Curso </dt>
                <dd> {certificateData?.courseName} </dd>
            </dl>
            <dl>
                <dt> Participante </dt>
                <dd> {certificateData?.participantName} </dd>
            </dl>

            <a href={certificateData?.certificateUrl}> Descargar el certificado </a>

        </Layout>
    )

}

interface CertificateData {
    participantName: string;
    courseName: string;
    certificateUrl: string;
}