import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/Layout";
import Footer from "../../components/Footer";
import MainBanner from "./components/MainBanner";
import { CampaignSection } from "./components/Campaign";
import { FeaturedBanner } from "./components/FeaturedBanner";
import { ResourcesSection } from "./components/Resources";
import News from "../Hemeroteca/components/News";
import { SocialsSection } from "./components/Socials";
import ReactGA from "react-ga4";

const CommunicationPage = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }

    window.scrollTo(0, 0);
  }, [pathname, hash, key]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/comunicacion",
      title: "Comunicación",
    });
  }, []);

  return (
    <Layout>
      <MainBanner />
      <FeaturedBanner />
      <CampaignSection />
      <ResourcesSection />
      <News />
      <SocialsSection />
      <Footer />
    </Layout>
  );
};

export default CommunicationPage;
